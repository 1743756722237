<script async setup lang="ts">
import CustomFormLoader from "@/components/CustomFormLoader.vue";
import ReportFooter from "@/components/ReportFooter.vue";
import ReportHeader from "@/components/ReportHeader.vue";
import SHField from "@/components/SHField.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHPill from "@/components/SHPill.vue";
import SHSignaturePad from "@/components/SHSignaturePad.vue";
import SHTextEditor from "@/components/TextEditor/SHTextEditor.vue";
import { useTicketSignature } from "@/composables/useTicketSignature";
import type { MediaObjectOrUploadTask } from "@/composables/useUpload";
import { formatUSD, numberFormatter } from "@/formatters";
import { graphql, useFragment, type FragmentType } from "@/generated";
import { humanizeEnum } from "@/lib/helpers";
import { useLogger } from "@/logger";
import MediaUploadsView from "@/views/MediaUploadsView.vue";
import { computed } from "vue";

const { log } = useLogger("TicketReport"); // eslint-disable-line @typescript-eslint/no-unused-vars

const fragment = graphql(/* GraphQL */ `
  fragment TicketReport on query_root {
    tickets_by_pk(id: $ticketId) {
      id
      ref

      ...TicketCard
      ...ReportHeaderTicket

      notesj

      work_order {
        customer {
          ...ReportHeaderCustomer
        }
      }

      product {
        title
      }

      work_site {
        title
      }

      combined_logs {
        id
        notesj
      }

      log_sums: combined_logs_aggregate(
        where: { deleted_at: { _is_null: true } }
      ) {
        aggregate {
          sum {
            work_hours
            travel_hours
            travel_miles
          }
        }
      }

      ticket_agents {
        agent {
          ...ReportHeaderAgent
        }
      }

      ticket_custom_form_entries(
        # where: { id: { _in: $ticketCustomFormEntryIds } }
        order_by: { custom_form_definition: { title: asc } }
      ) {
        id
        ticket_id
        payload

        custom_form_definition {
          id
          title
        }
      }

      expense_logs(order_by: [{ expensed_at: asc }]) {
        id
        expense_type
        expensed_at
        cents_recorded
        notesj
        media_uploads {
          ...MediaUploadCard
          ...SHLightbox
        }
      }
      ...UseTicketSignature
    }
  }
`);

const props = defineProps<{
  query: FragmentType<typeof fragment>;
  selectedFormEntries?: string[]; // ids from ticket_custom_form_entries
  selectedExpenseLogs?: string[]; // ids from expense_logs
}>();

const data = computed(() => useFragment(fragment, props.query));
const ticket = computed(() => data.value.tickets_by_pk);
const { signature, isSignatureRequired } = useTicketSignature(ticket);
const allFormEntries = computed(
  () => data.value.tickets_by_pk?.ticket_custom_form_entries || []
);
const allExpenseLogs = computed(
  () => data.value.tickets_by_pk?.expense_logs || []
);

const orderedFormEntries = computed(() => {
  // log("calculating ordered entries", props.selectedEntries);
  return (props.selectedFormEntries || [])
    .map(id => allFormEntries.value.find(entry => entry.id === id))
    .filter(id => !!id) as typeof allFormEntries.value;
});
const orderedExpenseLogs = computed(() => {
  return (props.selectedExpenseLogs || [])
    .map(id => allExpenseLogs.value.find(entry => entry.id === id))
    .filter(id => !!id) as typeof allExpenseLogs.value;
});
const totalExpenseCents = computed(() => {
  return orderedExpenseLogs.value.reduce(
    (total, e) => total + e.cents_recorded,
    0
  );
});
const totalHours = computed(
  () =>
    (ticket.value?.log_sums.aggregate?.sum?.work_hours || 0) +
    (ticket.value?.log_sums.aggregate?.sum?.travel_hours || 0)
);
</script>

<template>
  <article v-if="ticket" class="ticket-report vertical">
    <ReportHeader
      title="Report"
      :agent="ticket.ticket_agents.at(0)?.agent"
      :customer="ticket.work_order.customer"
      :ticket="ticket"
    >
      <div class="vertical tight">
        <h3>Totals</h3>
        <span class="level-spread">
          Hours:
          <strong>{{ numberFormatter.format(totalHours) }}</strong>
        </span>
        <span class="level-spread">
          Mileage:
          <strong>
            {{
              numberFormatter.format(
                ticket.log_sums.aggregate?.sum?.travel_miles ?? 0
              )
            }}
          </strong>
        </span>
        <span class="level-spread">
          Expenses:
          <strong>{{ formatUSD(totalExpenseCents) }}</strong>
        </span>
        <span class="level-spread">
          Generated on
          <SHInlineDate :d="new Date()" />
        </span>
      </div>
    </ReportHeader>

    <div v-if="isSignatureRequired && signature" class="signature-receipt">
      <SHField label="Signed by">
        <p>
          <span>{{ signature.author_name }}</span>
        </p>
        <p>
          <em>({{ signature.author_email }})</em>
        </p>
      </SHField>
      <SHField label="Signed at">
        <SHInlineDate format="long" :d="signature?.created_at" />
      </SHField>
      <SHSignaturePad
        v-if="signature"
        id="ticket-report-signature"
        disabled
        :model-value="signature.url"
      />
    </div>

    <CustomFormLoader
      v-for="entry in orderedFormEntries"
      :key="entry.id"
      :ticket-id="entry.ticket_id"
      :custom-form-definition-id="entry.custom_form_definition.id"
      :ticket-custom-form-entry-id="entry.id"
      readonly
    />

    <template v-if="orderedExpenseLogs.length">
      <h2>Expenses</h2>
      <div class="vertical loose expenses">
        <div
          v-for="e of orderedExpenseLogs"
          :key="e.id"
          class="vertical tight expense"
        >
          <header class="level tight">
            <SHPill
              color="var(--color-primary)"
              :left="humanizeEnum(e.expense_type)"
              :right="formatUSD(e.cents_recorded)"
            />
            <SHInlineDate format="long" :d="e.expensed_at" />
          </header>
          <SHTextEditor v-if="e.notesj" :model-value="e.notesj" is-ssr />
          <MediaUploadsView
            :media-uploads="
              (e.media_uploads ?? []) as MediaObjectOrUploadTask[]
            "
            hide-empty-message
            no-controls
            is-ssr
          />
        </div>
      </div>
    </template>

    <ReportFooter />
  </article>
</template>

<style lang="scss" scoped>
.ticket-report {
  display: flex;
  flex-direction: column;
  gap: 0.75em;

  font-size: 10pt;

  h1,
  :deep(h1) {
    font-size: 12pt;
  }

  h2,
  :deep(h2) {
    font-size: 11pt;
  }

  h3,
  :deep(h3) {
    font-size: 10pt;
  }

  .wellname {
    font-weight: 700;
  }
  .expenses {
    gap: 2em;
    .expense {
      gap: 0.25em;
    }
  }

  .signature-receipt {
    display: grid;
    width: 100%;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1.5in 1fr;
    gap: 1em;
    word-break: break-word;

    > #ticket-report-signature {
      grid-column: 2;
      grid-row: 1/3;

      min-height: 2in;
      width: 100%;

      display: flex;
      align-items: center;
    }
  }
}
</style>
