<script setup lang="ts">
import { numberFormatter } from "@/formatters";
import { graphql, useFragment, type FragmentType } from "@/generated";
import {
  faTruckClock,
  faTruckContainer
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { differenceInMinutes } from "date-fns";
import { computed } from "vue";

import AppLink from "@/components/AppLink.vue";
import SHPill from "@/components/SHPill.vue";
import TBDWorkSiteLink from "@/components/TBDWorkSiteLink.vue";
import SHTextEditor from "@/components/TextEditor/SHTextEditor.vue";
import TimeLineEntry from "@/components/TimeLineEntry.vue";
import UserLink from "@/components/UserLink.vue";
import MediaUploadsView from "@/views/MediaUploadsView.vue";

const fragment = graphql(/* GraphQL */ `
  fragment TravelLogCard on travel_logs {
    id
    ticket_id
    departed_at
    arrived_at
    notesj
    miles_traveled
    created_at

    author {
      ...UserLink
    }

    media_uploads(
      where: { deleted_at: { _is_null: true } }
      order_by: [{ created_at: asc }]
    ) {
      id
      ...MediaUploadCard
      ...SHLightbox
    }

    to_work_site {
      ...TBDWorkSiteLink
    }
    to_work_site_unconfirmed

    from_work_site {
      ...TBDWorkSiteLink
    }
    from_work_site_unconfirmed
  }
`);

const { editable, travelLog } = defineProps<{
  editable?: boolean;
  travelLog: FragmentType<typeof fragment>;
}>();

const fmt = numberFormatter.format;
const tlog = computed(() => useFragment(fragment, travelLog));
const duration = computed(
  () =>
    differenceInMinutes(
      tlog.value?.arrived_at ? new Date(tlog.value.arrived_at) : new Date(),
      new Date(tlog.value.departed_at)
    ) / 60
);
</script>

<template>
  <TimeLineEntry
    :start="tlog.departed_at"
    :end="tlog.arrived_at"
    icon-fg-color="var(--color-warning-up-300)"
    icon-bg-color="var(--color-warning-down-300)"
    class="travel-log-card"
    :editable="editable"
    @edit="
      $router.push({
        name: 'TravelLogDetail',
        params: {
          ticketId: tlog.ticket_id,
          travelLogId: tlog.id
        }
      })
    "
  >
    <template #icon>
      <FontAwesomeIcon :icon="faTruckContainer" fixed-width />
    </template>

    <template #default>
      <UserLink :user="tlog.author" link />
      logged {{ fmt(tlog.miles_traveled) }} miles in {{ fmt(duration) }} hours.
    </template>

    <template #detail>
      <div class="vertical tight">
        <div class="level tight wrap">
          <TBDWorkSiteLink
            v-if="tlog.from_work_site"
            :work-site="tlog.from_work_site"
            :guess="tlog.from_work_site_unconfirmed"
          />
          <AppLink v-else disabled>Home Base</AppLink>

          <FontAwesomeIcon
            :icon="faTruckClock"
            style="color: var(--color-secondary-up-300)"
          />

          <TBDWorkSiteLink
            v-if="tlog.to_work_site"
            :work-site="tlog.to_work_site"
            :guess="tlog.to_work_site_unconfirmed"
          />
          <AppLink v-else disabled>Home Base</AppLink>
        </div>

        <SHTextEditor v-if="tlog.notesj" :model-value="tlog.notesj" />
        <MediaUploadsView
          :media-uploads="tlog.media_uploads ?? []"
          hide-empty-message
          no-controls
        />

        <footer>
          <div class="level loose pill">
            <SHPill>
              <template #left>
                {{ parseFloat(tlog.miles_traveled.toFixed(1)) }} Miles
              </template>
              <template #right>{{ fmt(duration) }} hr</template>
            </SHPill>
          </div>
        </footer>
      </div>
    </template>
  </TimeLineEntry>
</template>
