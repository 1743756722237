<script async setup lang="ts">
import CustomerContactForm from "@/components/CustomerContactForm.vue";
import DocumentPreview from "@/components/DocumentPreview.vue";
import SHButton from "@/components/SHButton.vue";
import SHCustomerContactChooser from "@/components/SHCustomerContactChooser.vue";
import SHNote from "@/components/SHNote.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import type { AgentChoice } from "@/composables/useAgentChoice";
import {
  useCustomerContactChoice,
  type CustomerContactChoice
} from "@/composables/useCustomerContactChoice";
import { useCustomers } from "@/composables/useCustomers";
import { useQuotes } from "@/composables/useQuotes";
import { useToaster } from "@/composables/useToaster";
import type { Customer_Contacts_Insert_Input } from "@/generated/graphql";
import QuoteRO from "@/ssrComponents/QuoteRO.vue";
import { faUserPlus } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ref } from "vue";

const { createToast } = useToaster();

const { quoteId } = defineProps<{
  quoteId: string;
}>();

const isSending = ref(false);
const isAdding = ref(false);
const contactInsert = ref<Customer_Contacts_Insert_Input | null>();
const selectedAgents = ref<AgentChoice[]>([]);
const selectedContacts = ref<CustomerContactChoice[]>([]);

const { mutations: customerMutations } = useCustomers();
const { mutations: quoteMutations, getByPkAsync } = await useQuotes();
const { quote } = await getByPkAsync(quoteId);

const onSend = async () => {
  isSending.value = true;
  const { data, error } = await quoteMutations.send.executeMutation({
    quoteId,
    userIds: selectedAgents.value?.map(a => a.id),
    customerContacts: selectedContacts.value?.map(c => c.id)
  });
  isSending.value = false;
  if (error) {
    createToast({
      theme: "danger",
      title: `Quote not sent.`,
      message: error.message
    });
  } else if (data) {
    createToast({
      theme: "success",
      message: `Quote ${data.sendQuote?.quote?.ref} sent.`
    });
  }
};

const onContactAdd = async () => {
  console.log("on contact add", contactInsert.value);
  if (!contactInsert.value?.email) {
    return;
  }

  const { data, error } = await customerMutations.createContact.executeMutation(
    {
      contact: {
        ...contactInsert.value,
        customer_id: quote.value?.customer?.id
      }
    }
  );
  if (error) {
    createToast({
      title: "Contact was not added.",
      message: error.message,
      theme: "danger"
    });
  } else if (data?.insert_customer_contacts_one) {
    createToast({
      message: "Contact added.",
      theme: "success"
    });
    // now put this new user in our selected contacts
    const newChoice = useCustomerContactChoice(
      data.insert_customer_contacts_one
    );
    selectedContacts.value.push(newChoice);
    contactInsert.value = null;
    isAdding.value = false;
  }
  console.log({ data, error });
};
const onCancelContactAdd = async () => {
  isAdding.value = false;
  console.log("on contact add CANCELLED");
};
</script>

<template>
  <article class="quote-preview">
    <h2>Send Quote</h2>

    <div class="recipients">
      <!-- <SHField label="Agents">
        <SHAgentChooser v-model:multiple="selectedAgents" />
      </SHField> -->
      <SHCustomerContactChooser
        v-model:multiple="selectedContacts"
        :disabled="!quote?.customer?.contacts_aggregate?.aggregate?.count"
        :context-customer-id="quote?.customer?.id"
      />
      <SHButton
        color="primary"
        :active="isAdding"
        @click="isAdding = !isAdding"
      >
        <FontAwesomeIcon :icon="faUserPlus" />
      </SHButton>
    </div>

    <Transition name="fade">
      <div v-if="isAdding">
        <div class="card">
          <h3>New Contact For {{ quote?.customer?.title }}</h3>
          <CustomerContactForm @update:inserts="contactInsert = $event" />
          <UndoConfirm
            :confirm-enabled="!!contactInsert?.email"
            confirm-text="Add Contact"
            @undo="onCancelContactAdd"
            @confirm="onContactAdd"
          />
        </div>
      </div>
    </Transition>
    <SHNote
      v-if="selectedAgents.length || selectedContacts.length"
      theme="warning"
    >
      Will send to
      <ul>
        <li v-for="a of selectedAgents" :key="a.id">{{ a.full_name }}</li>
        <li v-for="a of selectedContacts" :key="a.id">{{ a.full_name }}</li>
      </ul>
    </SHNote>
    <div>
      <SHButton
        :disabled="
          isSending ||
          isAdding ||
          (!selectedAgents.length && !selectedContacts.length)
        "
        color="success"
        @click="onSend"
      >
        Send
      </SHButton>
    </div>
    <DocumentPreview ref="documentPreview" :extra-styles="['/printQuote.css']">
      <template #header><h2>Preview</h2></template>
      <QuoteRO :quote-id="quoteId" />
    </DocumentPreview>
  </article>
</template>

<style lang="scss" scoped>
.quote-preview {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .recipients {
    display: grid;
    gap: 0.5em;
    grid-template-columns: 1fr max-content;
  }
}
.card {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  background: var(--color-surface-0);
  box-shadow: var(--box-shadow);
  border: thin solid var(--color-surface-100);
  border-radius: var(--border-radius);
}
</style>
