import ModuleHeader from "@/components/ModuleHeader.vue";
import SHButton from "@/components/SHButton.vue";
import { useRole } from "@/composables/useRole";
import {
  faChartUser,
  faIdCard,
  faIdCardAlt,
  faPen,
  faPlus
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { h } from "vue";
import { type RouteRecordRaw } from "vue-router";

import UserProfileForm from "@/components/UserProfileForm.vue";
import AgentShiftDetail from "@/views/AgentShiftDetail.vue";
import AgentShiftsView from "@/views/AgentShiftsView.vue";
import ExpensesView from "@/views/ExpensesView.vue";
import OrganizationAgentCreate from "@/views/OrganizationAgentCreate.vue";
import OrganizationAgentCustomers from "@/views/OrganizationAgentCustomers.vue";
import OrganizationAgentDetail from "@/views/OrganizationAgentDetail.vue";
import OrganizationAgentInfo from "@/views/OrganizationAgentInfo.vue";
import OrganizationAgentsList from "@/views/OrganizationAgentsList.vue";
import OrganizationAgentsView from "@/views/OrganizationAgentsView.vue";
import PreferencesView from "@/views/PreferencesView.vue";
import ServiceReportForm from "@/views/ServiceReportForm.vue";
import TicketsView from "@/views/TicketsView.vue";
import TimeSheetsView from "@/views/TimeSheetsView.vue";

export const agentRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "OrganizationAgentsView",
    props: true,
    components: {
      default: OrganizationAgentsView,
      header: h(
        ModuleHeader,
        { icon: faIdCard },
        {
          default: () => "Agents",
          nav: () =>
            useRole().can("organization_agents:create")
              ? h(
                  SHButton,
                  {
                    to: { name: "OrganizationAgentCreate" },
                    color: "primary",
                    size: "sm"
                  },
                  () =>
                    h("div", [
                      h(FontAwesomeIcon, { icon: faPlus, fixedWidth: true }),
                      h(FontAwesomeIcon, { icon: faIdCard, fixedWidth: true })
                    ])
                )
              : ""
        }
      )
    },
    children: [
      {
        path: "",
        name: "AgentsView",
        component: OrganizationAgentsList
      },
      {
        path: "archived",
        name: "ArchivedAgentsView",
        props: { showArchived: true },
        component: OrganizationAgentsList
      },
      {
        path: "timesheets",
        name: "AgentTimeSheets",
        props: true,
        component: TimeSheetsView
      },
      {
        path: "shifts",
        name: "AgentShiftsView",
        props: true,
        component: AgentShiftsView
      }
    ]
  },
  {
    path: "new",
    name: "OrganizationAgentCreate",
    components: {
      default: OrganizationAgentCreate,
      header: h(
        ModuleHeader,
        { icon: faIdCardAlt },
        {
          default: () => "Agent Create"
        }
      )
    },
    props: true
  },
  {
    path: ":agentId",
    name: "AgentDetail",
    components: {
      default: OrganizationAgentDetail,
      header: h(
        ModuleHeader,
        { icon: faIdCard },
        {
          default: () => "Agent Details",
          nav: () => {
            const { can } = useRole();
            return can("users:update_others") || can("users:block_only")
              ? h(
                  SHButton,
                  {
                    to: { name: "AgentEdit" },
                    props: true,
                    color: "primary",
                    square: true
                  },
                  () =>
                    h("div", { class: "level tight" }, [
                      h(FontAwesomeIcon, { icon: faPen })
                    ])
                )
              : undefined;
          }
        }
      )
    },
    redirect: {
      name: "OrganizationAgentInfo"
    },
    props: true,
    children: [
      {
        path: "info",
        name: "OrganizationAgentInfo",
        props: true,
        components: { default: OrganizationAgentInfo }
      },
      {
        path: "tickets",
        name: "OrganizationAgentTickets",
        components: { default: TicketsView },
        props: {
          default(route) {
            return {
              ...route.params,
              noMargin: true
            };
          }
        }
      },
      {
        path: "customers",
        name: "OrganizationAgentCustomers",
        props: true,
        components: { default: OrganizationAgentCustomers }
      },
      {
        path: "timesheet",
        name: "OrganizationAgentTimeSheet",
        props: {
          default(route) {
            return {
              ...route.params
            };
          }
        },
        components: { default: TimeSheetsView }
      },
      {
        path: "shifts",
        name: "AgentDetailShiftsView",
        component: AgentShiftsView,
        props: true
      },
      {
        path: "shifts/:shiftHash",
        name: "AgentShiftDetail",
        props: true,
        component: AgentShiftDetail
      },
      {
        path: "service-report",
        name: "CreateServiceReportFromAgent",
        components: {
          default: ServiceReportForm,
          header: h(
            ModuleHeader,
            { icon: faChartUser },
            () => "Create Service Report"
          )
        },
        props: {
          default(route) {
            return {
              ...route.params,
              noMargin: true
            };
          }
        }
      },
      {
        path: "expenses",
        name: "AgentExpenses",
        components: {
          default: ExpensesView,
          header: h(
            ModuleHeader,
            { icon: faChartUser },
            () => "Create Service Report"
          )
        },
        props: {
          default(route) {
            return {
              ...route.params,
              ...route.query,
              noMargin: true
            };
          }
        }
      },
      {
        path: "perferences",
        name: "NotifPrefs",
        components: {
          default: PreferencesView,
          header: h(
            ModuleHeader,
            { icon: faChartUser },
            () => "Notification Preferences"
          )
        },
        props: {
          default(route) {
            return {
              ...route.params,
              ...route.query,
              noMargin: true
            };
          }
        }
      }
    ]
  },
  {
    path: "edit",
    children: [
      {
        path: ":agentId",
        props: true,
        name: "AgentEdit",
        components: {
          default: UserProfileForm,
          header: h(
            ModuleHeader,
            { icon: faIdCard },
            {
              default: () => "Edit User Profile"
            }
          )
        }
      }
    ]
  }
];
