<script setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import ComboBox from "@/components/ComboBox/ComboBox.vue";
import DateRangePickerVue from "@/components/DateRangePicker.vue";
import SHAgentChooser from "@/components/SHAgentChooser.vue";
import SHAutoComplete from "@/components/SHAutoComplete.vue";
import SHButton from "@/components/SHButton.vue";
import SHDollarInput from "@/components/SHDollarInput.vue";
import SHField from "@/components/SHField.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHInput from "@/components/SHInput.vue";
import SHList from "@/components/SHList.vue";
import SHNote from "@/components/SHNote.vue";
import SHNumberInput from "@/components/SHNumberInput.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import SHTimeInput from "@/components/SHTimeInput/SHTimeInput.vue";
import SHToggle from "@/components/SHToggle.vue";
import UserLink from "@/components/UserLink.vue";
import type { AgentChoice } from "@/composables/useAgentChoice";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import type { ButtonSize, ButtonTheme } from "@/types";
import { faSlotMachine, faSquare } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useQuery } from "@urql/vue";
import { add } from "date-fns";
import { computed, h, ref, watch } from "vue";

const colors: ButtonTheme[] = [
  "primary",
  "secondary",
  "success",
  "info",
  "warning",
  "danger",
  "surface-0",
  "surface-50",
  "surface-100",
  "surface-200",
  "surface-300",
  "surface-400",
  "surface-500",
  "surface-600",
  "surface-700",
  "surface-800",
  "surface-900"
];
const colorOptions = colors.map(c => ({ id: c, label: c, foo: "bar" }));

const dates = ref<[Date, Date]>([new Date(), add(new Date(), { days: 10 })]);

const buttonsDisabled = ref(false);
const buttonSize = ref<ButtonSize>("md");

const usersQuery = graphql(/* GraphQL */ `
  query ThemePageUsers {
    users(limit: 20) {
      id
      email
      ...UserLink
    }
  }
`);

const { data: usersData } = useQuery({
  query: usersQuery
});

const users = computed(() => usersData.value?.users ?? []);
const selectedColors = ref<typeof colorOptions>([]);
const selectedColor = ref<(typeof colorOptions)[number] | null>();
const { createToast } = useToaster();
const sampleDate = ref<Date>(new Date());
watch(sampleDate, newVal => {
  console.log("ThemeDump: date updated to", newVal);
});
const minuteResolution = ref(1);
const onTimeUpdate = (newDate: Date) => {
  console.log("ThemeDump: date updated to!!", newDate);
  sampleDate.value = newDate;
};
const selectedAgent = ref<AgentChoice>();
const selectedAgents = ref<AgentChoice[]>([]);
const selfAtTop = ref(true);
const clearable = ref(true);
const multiSelect = ref(true);
const autoComplete = ref("");
const numberValue = ref(0);
const decimals = ref(0);
const stringValue = ref("I'm disabled");
const ToastSlotExample = h(
  "div",
  { class: "level-tight", style: "padding: var(--padding);" },
  [
    h(FontAwesomeIcon, { icon: faSlotMachine }),
    h("span", "I am the slotted component")
  ]
);
</script>

<template>
  <article class="vertical xmargin">
    <h1>Theme</h1>

    <section class="vertical">
      <h2>AppLink</h2>
      <AppLink href="/theme">Href</AppLink>
      <AppLink :to="{ name: 'Dashboard' }" active>Manually Active</AppLink>
      <AppLink :to="{ name: 'ThemeDump' }">
        To Theme Dump (should be active)
      </AppLink>
      <AppLink :to="{ name: 'Dashboard' }">
        To Dashboard (should be inactive)
      </AppLink>
      <AppLink
        @click="
          createToast({
            message: 'App Link clicked!',
            theme: 'success'
          })
        "
      >
        Click Handler
      </AppLink>
      <AppLink
        disabled
        @click="
          createToast({
            message: 'Disabled App Link clicked!',
            theme: 'success'
          })
        "
      >
        Disabled
      </AppLink>
    </section>

    <section class="vertical">
      <h2>Buttons</h2>
      <div class="level tight">
        <SHField label="Disable Buttons">
          <SHToggle v-model="buttonsDisabled" />
        </SHField>
        <SHField :label="`Button Size: ${buttonSize}`">
          <div class="level tight">
            <label class="level tight">
              <input v-model="buttonSize" type="radio" value="sm" />
              SM
            </label>
            <label class="level tight">
              <input v-model="buttonSize" type="radio" value="md" />
              MD
            </label>
            <label class="level tight">
              <input v-model="buttonSize" type="radio" value="lg" />
              LG
            </label>
          </div>
        </SHField>
      </div>
      <p>
        Look this is a
        <SHButton
          :size="buttonSize"
          :disabled="buttonsDisabled"
          color="primary"
        >
          button
        </SHButton>
        in a sentence. And this is a
        <SHButton
          square
          :size="buttonSize"
          :disabled="buttonsDisabled"
          color="primary"
        >
          <FontAwesomeIcon :icon="faSquare" />
        </SHButton>
        button
      </p>
      <h3>Colors</h3>
      <div class="level tight wrap">
        <SHButton
          v-for="(c, i) of colors"
          :key="i"
          :color="c"
          :disabled="buttonsDisabled"
          :size="buttonSize"
        >
          {{ c }}
        </SHButton>
      </div>
    </section>

    <section class="vertical">
      <h2>Inputs</h2>
      <SHField label="Decimal Points">
        <SHNumberInput v-model="decimals" :decimals="0" />
      </SHField>

      <SHField label="Number Input">
        <SHNumberInput v-model="numberValue" :decimals="decimals" />
      </SHField>

      <SHField label="Dollar Input">
        <SHDollarInput v-model="numberValue" />
      </SHField>

      <SHField label="Disabled Input">
        <SHInput v-model="stringValue" disabled />
      </SHField>
    </section>

    <h2>Autocomplete Combo</h2>
    <SHAutoComplete v-model="autoComplete" domain="pumps" />

    <h2>Agent Chooser</h2>
    <div class="level tight">
      <SHButton @click="clearable = !clearable">
        Clearable {{ clearable }}
      </SHButton>
      <SHButton @click="selfAtTop = !selfAtTop">
        Self At Top {{ selfAtTop }}
      </SHButton>
      <SHButton @click="multiSelect = !multiSelect">
        multiSelect {{ multiSelect }}
      </SHButton>
    </div>
    <SHAgentChooser
      v-if="multiSelect"
      v-model:multiple="selectedAgents"
      :clearable="clearable"
      :self-at-top="selfAtTop"
      dedicate-at-top-by-customer-id="4be106f4-f401-4f3a-a08c-744d80d6d4c2"
    />
    <SHAgentChooser
      v-else
      v-model="selectedAgent"
      :clearable="clearable"
      :self-at-top="selfAtTop"
      dedicate-at-top-by-customer-id="4be106f4-f401-4f3a-a08c-744d80d6d4c2"
    />

    <h2>Time</h2>
    <div class="level loose">
      Minute Resolution
      <div class="level tight">
        <input v-model="minuteResolution" type="radio" :value="1" />
        1
      </div>
      <div class="level tight">
        <input v-model="minuteResolution" type="radio" :value="5" />
        5
      </div>
      <div class="level tight">
        <input v-model="minuteResolution" type="radio" :value="10" />
        10
      </div>
      <div class="level tight">
        <input v-model="minuteResolution" type="radio" :value="15" />
        15
      </div>
      <code>{{ sampleDate.toString() }}</code>
    </div>
    <SHTimeInput
      :model-value="sampleDate"
      :minute-resolution="minuteResolution"
      @update:model-value="onTimeUpdate"
    />

    <h2>Toast</h2>
    <div class="level loose">
      <SHButton
        color="info"
        @click.stop="
          createToast({
            placement: 'top-right',
            theme: 'info',
            lifetimeMS: 5000,
            title: 'Update Available',
            message: 'Your version is 49 days old, you should update'
          })
        "
      >
        Info
      </SHButton>
      <SHButton
        color="success"
        @click.stop="
          createToast({
            placement: 'top-right',
            theme: 'success',
            title: 'Banana Rotation Applied',
            message: 'now +4.614 radians of Y'
          })
        "
      >
        Success
      </SHButton>
      <SHButton
        color="warning"
        @click.stop="
          createToast({
            placement: 'top-right',
            theme: 'warning',
            lifetimeMS: 5000,
            title: 'Calibration Incomplete',
            message:
              'Watermelon calibration incomplete but you may still save your work'
          })
        "
      >
        Warning
      </SHButton>
      <SHButton
        color="danger"
        @click.stop="
          createToast({
            placement: 'top-right',
            theme: 'danger',
            requiresInteraction: true,
            title: 'Banana Not Found',
            message: 'Banana #1509 not found'
          })
        "
      >
        Danger
      </SHButton>
      <SHButton
        color="primary"
        @click.stop="
          createToast({
            placement: 'top-right',
            theme: 'primary',
            requiresInteraction: true,
            title: 'Branded 🔩🍿🚀',
            message: 'Banana #1509 not found'
          })
        "
      >
        primary
      </SHButton>
      <SHButton
        color="secondary"
        @click.stop="
          createToast({
            placement: 'top-right',
            theme: 'secondary',
            requiresInteraction: true,
            title: 'Secondary 🕑2️⃣',
            message: 'Banana #1509 not found'
          })
        "
      >
        Secondary
      </SHButton>
      <SHButton
        color="info"
        @click.stop="
          createToast({
            placement: 'top-right',
            theme: 'primary',
            requiresInteraction: true,
            title: 'Slotted 🎰',
            message: 'I am the simple string',
            component: ToastSlotExample
          })
        "
      >
        Slotted
      </SHButton>
    </div>

    <h2>Forms</h2>
    <DateRangePickerVue v-model="dates" />

    <h2>Table</h2>
    <SHTable :rows="users" :columns="['#', 'id', 'name', 'email']">
      <template #row="{ row, idx }">
        <td class="number">{{ idx }}</td>
        <td>{{ row.id }}</td>
        <td>
          <UserLink :user="row" />
        </td>
        <td class="number">{{ row.email }}</td>
      </template>
    </SHTable>

    <h2>Menu</h2>

    <SHField label="multiple" block>
      <SHList v-model:multiple="selectedColors" :options="colorOptions" />
    </SHField>

    <SHField label="multiple" block>
      <div class="level loose">
        <ComboBox
          v-model:multiple="selectedColors"
          mobile-header="Select a Color"
          :options="colors.map(c => ({ id: c, label: c }))"
        />
        <ComboBox
          v-model:multiple="selectedColors"
          mobile-header="Select a Color"
          :options="colors.map(c => ({ id: c, label: c }))"
          loading
        />
      </div>
    </SHField>

    <SHField label="single" block>
      <SHList v-model="selectedColor" :options="colorOptions" />
    </SHField>
    <h2>Combo</h2>

    <SHField label="single" block>
      <div class="level loose">
        <ComboBox
          v-model="selectedColor"
          mobile-header="Select a Color"
          :options="colorOptions"
        />
        <ComboBox
          v-model="selectedColor"
          mobile-header="Select a Color"
          loading
          :options="colorOptions"
        />
      </div>
    </SHField>

    <h2>Spinner</h2>
    <SHSpinner />

    <h2>Note</h2>
    <SHNote theme="primary">This is a note!</SHNote>

    <h2>SHInlineDate</h2>

    <!-- Default Format -->

    <span>
      <strong>Default Format:</strong>
      ( omit the format prop )
    </span>

    <span>
      <SHInlineDate d="2023-12-07T21:43:19.580332+00:00" />
    </span>

    <!-- Long Format -->

    <span>
      <strong>Long Format:</strong>
      ( set
      <code>format="long"</code>
      )
    </span>

    <span>
      <SHInlineDate d="2023-12-07T21:43:19.580332+00:00" format="long" />
    </span>

    <!-- Relative Format -->

    <span>
      <strong>Relative Format:</strong>
      ( set
      <code>format="rel"</code>
      )
    </span>

    <div>
      <span>
        This is an SHInlineDate component set to relative using a string for (
        <SHInlineDate d="2023-12-07T21:43:19.580332+00:00" format="rel" />
        ) the date.
      </span>

      <div>
        This example uses a Date() object:
        <SHInlineDate :d="new Date()" format="rel" />
      </div>
    </div>

    <!-- Short Format -->

    <span>
      <strong>Short Format:</strong>
      ( set
      <code>format="short"</code>
      )
    </span>

    <SHInlineDate d="2023-12-07T21:43:19.580332+00:00" format="short" />
  </article>
</template>
