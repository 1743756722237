import { graphql, useFragment, type FragmentType } from "@/generated";
import type { CustomerContactChoiceFragment } from "@/generated/graphql";

const fragment = graphql(/* GraphQL */ `
  fragment CustomerContactChoice on customer_contacts {
    id
    full_name
    job_title
  }
`);

export type CustomerContactChoice = CustomerContactChoiceFragment;

/**
 * Overload the useFragment function to provide a more specific type for the agent choice fragment.
 * The overloads are written this make typescript work better when agent is null or undefined.
 */
export function useCustomerContactChoice(contact: null): null;
export function useCustomerContactChoice(contact: undefined): undefined;
export function useCustomerContactChoice(
  contact: FragmentType<typeof fragment>
): CustomerContactChoice;
export function useCustomerContactChoice(
  contact: FragmentType<typeof fragment> | null | undefined
): CustomerContactChoice | null | undefined;
export function useCustomerContactChoice(
  contact: FragmentType<typeof fragment> | null | undefined
) {
  return useFragment(fragment, contact);
}
