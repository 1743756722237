<script setup lang="ts">
import { ref } from "vue";

const { initialValue: modelValue = false } = defineProps<{
  is?: string;
  initialValue?: boolean;
}>();

const on = ref(modelValue);

function toggle() {
  on.value = !on.value;
}

function enable() {
  on.value = true;
}

function disable() {
  on.value = false;
}
</script>

<template>
  <component :is="is" v-if="is" v-bind="$attrs">
    <slot
      v-bind="{
        on,
        toggle,
        enable,
        disable
      }"
    ></slot>
  </component>
  <!-- eslint-disable-next-line vue/no-multiple-template-root -->
  <slot
    v-else
    v-bind="{
      on,
      toggle,
      enable,
      disable
    }"
  ></slot>
</template>
