<script async setup lang="ts">
import SHButton from "@/components/SHButton.vue";
import SHNote from "@/components/SHNote.vue";
import SHTextEditor from "@/components/TextEditor/SHTextEditor.vue";
import SHTextEditorWithToolbar from "@/components/TextEditor/SHTextEditorWithToolbar.vue";
import TimeLine from "@/components/TimeLine.vue";
import TimeLineEntry from "@/components/TimeLineEntry.vue";
import UserLink from "@/components/UserLink.vue";
import { useQuotes } from "@/composables/useQuotes";
import { useToaster } from "@/composables/useToaster";
import { graphql, useFragment } from "@/generated";
import { Quote_Event_Types_Enum } from "@/generated/graphql";
import { humanizeEnum, makeArrayWorkWithHasura } from "@/lib/helpers";
import {
  getEventBackgroundColor,
  getEventForegroundColor,
  getEventIcon
} from "@/lib/quoteStatus";
import type { UploadedMedia } from "@/types";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { JSONContent } from "@tiptap/vue-3";
import { useQuery } from "@urql/vue";
import { computed, ref } from "vue";
import MediaUploadsView from "./MediaUploadsView.vue";

const { createToast } = useToaster();

const { quoteId } = defineProps<{
  quoteId: string;
}>();

const fragment = graphql(/* GraphQL */ `
  fragment QuoteActivity on quote_quotes {
    id
    ref
    media_uploads(
      where: { deleted_at: { _is_null: true } }
      order_by: [{ created_at: asc }]
    ) {
      id
      ...SHLightbox
      ...MediaUploadCard
    }
    events(order_by: [{ created_at: asc }]) {
      id
      event_type
      created_at
      notes
      notesj
      author {
        ...UserLink
      }
      desired_reviewer {
        ...UserLink
      }
      customer_contact {
        full_name
        email
      }
      media_uploads(
        where: { deleted_at: { _is_null: true } }
        order_by: [{ created_at: asc }]
      ) {
        id
        ...SHLightbox
        ...MediaUploadCard
      }
    }
  }
`);

const { data, error } = await useQuery({
  query: graphql(/* GraphQL */ `
    query QuoteActivity($quoteId: uuid!) {
      quote_quotes_by_pk(id: $quoteId) {
        ...QuoteActivity
      }
    }
  `),
  variables: computed(() => ({ quoteId }))
});
const quote = computed(() =>
  useFragment(fragment, data.value?.quote_quotes_by_pk)
);
const eventNotes = ref<JSONContent | null>(null);
const mediaUploads = ref<UploadedMedia[]>([]);

const { mutations } = useQuotes();

const onComment = async () => {
  const { data, error } = await mutations.comment.executeMutation({
    args: {
      _quote_id: quoteId,
      _notes: eventNotes.value,
      _media_upload_ids: makeArrayWorkWithHasura(
        mediaUploads.value.map(u => u.id)
      )
    }
  });
  if (error) {
    createToast({
      title: "Comment was not added.",
      message: error.message,
      theme: "danger"
    });
  } else if (data) {
    eventNotes.value = null;
    mediaUploads.value = [];
    createToast({
      message: "Comment added.",
      theme: "success"
    });
  }
};
const onAccept = async () => {
  const { data, error } = await mutations.accept.executeMutation({
    args: {
      _quote_id: quoteId,
      _notes: eventNotes.value,
      _media_upload_ids: makeArrayWorkWithHasura(
        mediaUploads.value.map(u => u.id)
      )
    }
  });
  if (error) {
    createToast({
      title: "Quote was not accepted.",
      message: error.message,
      theme: "danger"
    });
  } else if (data) {
    eventNotes.value = null;
    mediaUploads.value = [];
    createToast({
      message: "Quote accepted.",
      theme: "success"
    });
  }
};
const onReject = async () => {
  const { data, error } = await mutations.reject.executeMutation({
    args: {
      _quote_id: quoteId,
      _notes: eventNotes.value,
      _media_upload_ids: makeArrayWorkWithHasura(
        mediaUploads.value.map(u => u.id)
      )
    }
  });
  if (error) {
    createToast({
      title: "Quote was not rejected.",
      message: error.message,
      theme: "danger"
    });
  } else if (data) {
    eventNotes.value = null;
    mediaUploads.value = [];
    createToast({
      message: "Quote rejected.",
      theme: "success"
    });
  }
};
</script>

<template>
  <SHNote v-if="error" theme="danger">{{ error.message }}</SHNote>
  <SHNote v-else-if="!quote" theme="danger">Not found.</SHNote>
  <article v-else class="quote-activity vertical">
    <h2>Timeline</h2>
    <TimeLine v-if="quote?.events">
      <TransitionGroup name="list">
        <template v-for="e of quote.events" :key="e.id">
          <TimeLineEntry
            :start="e.created_at"
            :icon-fg-color="getEventForegroundColor(e.event_type)"
            :icon-bg-color="getEventBackgroundColor(e.event_type)"
          >
            <template #icon>
              <FontAwesomeIcon :icon="getEventIcon(e.event_type)" fixed-width />
            </template>
            <div class="level tight wrap">
              <template
                v-if="e.event_type === Quote_Event_Types_Enum.Requested"
              >
                <UserLink :user="e.author" />
                requested review by
                <template v-if="e.desired_reviewer">
                  <UserLink :user="e.desired_reviewer" />
                </template>
                <template v-else>
                  {{ e.customer_contact?.full_name }} ({{
                    e.customer_contact?.email
                  }})
                </template>
              </template>
              <template v-else>
                {{ humanizeEnum(e.event_type) }}
                by
                <UserLink :user="e.author" />
              </template>
            </div>
            <template v-if="e.notesj || e.media_uploads.length" #detail>
              <SHTextEditor inline :model-value="e.notesj" />
              <MediaUploadsView
                :media-uploads="e.media_uploads"
                hide-empty-message
              />
            </template>
          </TimeLineEntry>
        </template>
      </TransitionGroup>
    </TimeLine>

    <!-- the context is empty here as we don't know what this event's id will be after the quote.* function, we attach images on save of the event-->
    <SHTextEditorWithToolbar
      v-model="eventNotes"
      v-model:media-uploads="mediaUploads"
      editable
      :context="{}"
      :file-path="`quotes/${quote.ref}`"
    />
    <div class="level tight">
      <SHButton color="primary" @click="onComment">Comment</SHButton>
      <SHButton color="success" @click="onAccept">Accept</SHButton>
      <SHButton color="danger" @click="onReject">Reject</SHButton>
    </div>
  </article>
</template>
