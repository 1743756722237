<script setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import { graphql, useFragment, type FragmentType } from "@/generated";
import { faCircleUser } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";
import type { RouteLocationRaw } from "vue-router";

const customerFragment = graphql(/* GraphQL */ `
  fragment CustomerLink on customers {
    id
    abbreviation
    title
  }
`);

const props = defineProps<{
  to?: RouteLocationRaw;
  customer: FragmentType<typeof customerFragment>;
  abbr?: boolean; // prefer abbreviation over full name
  hideIcon?: boolean;
}>();

const customer = computed(() => useFragment(customerFragment, props.customer));
</script>

<template>
  <AppLink
    class="customer-link"
    :to="
      to || {
        name: 'CustomerDetail',
        params: { customerId: customer.id }
      }
    "
  >
    <template v-if="!hideIcon" #prefix>
      <FontAwesomeIcon
        color="var(--color-secondary)"
        :icon="faCircleUser"
        fixed-width
      />
    </template>
    <slot>
      <template v-if="abbr">
        {{ customer.abbreviation || customer.title }}
      </template>
      <template v-else>
        {{ customer.title }}
      </template>
    </slot>
  </AppLink>
</template>
