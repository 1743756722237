import { useS3Upload } from "@/composables/useS3Upload";
import { useLogger } from "@/logger";
import type { SignaturePayload } from "@/types";
import SignaturePad from "signature_pad";
import { v4 } from "uuid";

const { log } = useLogger("useSignatureUpload");

export function useSignatureUpload() {
  const { uploadToS3 } = useS3Upload();

  async function uploadSignature(signaturePayload: SignaturePayload) {
    log("Starting signature upload");

    // Enforce black pen color
    signaturePayload.data.forEach(point => {
      point.penColor = "#000";
    });

    // Convert signature payload to image
    const canvas = document.createElement("canvas");
    canvas.width = signaturePayload.width;
    canvas.height = signaturePayload.height;
    const signaturePad = new SignaturePad(canvas);

    signaturePad.fromData(signaturePayload.data);

    // Convert to blob
    const blob = await new Promise<Blob>(resolve => {
      canvas.toBlob(blob => {
        if (blob) resolve(blob);
        else throw new Error("Failed to convert signature to blob");
      }, "image/png");
    });

    // Create File object
    const file = new File([blob], "signature.png", { type: "image/png" });

    // Generate unique ID for the signature
    const signatureId = v4();

    // Upload to S3
    const uploadTask = await uploadToS3(file, signatureId, "signatures");

    // Start the upload
    await uploadTask.start();

    log("Signature upload completed");

    return uploadTask;
  }

  return {
    uploadSignature
  };
}
