<script setup lang="ts">
import ComboBox from "@/components/ComboBox/ComboBox.vue";
import {
  useCustomerChoice,
  type CustomerChoice
} from "@/composables/useCustomerChoice";
import { graphql } from "@/generated/gql";
import { useQuery } from "@urql/vue";
import { computed } from "vue";
import SHNote from "./SHNote.vue";

const modelValue = defineModel<CustomerChoice | undefined>();
const multiple = defineModel<CustomerChoice[]>("multiple");

const { mustHaveUnbilledLogs } = defineProps<{
  disabled?: boolean;
  mustHaveUnbilledLogs?: boolean;
}>();

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query SHCustomerChooser($where: customers_bool_exp!) {
      customers(order_by: [{ title: asc }], where: $where) {
        ...CustomerChoice
      }
    }
  `),
  variables: computed(() => ({
    where: {
      _and: [
        { deleted_at: { _is_null: true } },
        mustHaveUnbilledLogs
          ? { combined_logs_aggregate: { count: { predicate: { _gt: 0 } } } }
          : {}
      ]
    }
  }))
});

const customers = computed(() =>
  (data.value?.customers || []).map(useCustomerChoice)
);
</script>
<template>
  <SHNote v-if="error" theme="danger">{{ error }}</SHNote>
  <main v-else>
    <ComboBox
      v-model:multiple="multiple"
      v-model="modelValue"
      enable-fuse
      :fuse-options="{
        keys: ['title', 'abbreviation'],
        threshold: 0.5
      }"
      :disabled="disabled || !customers"
      :options="customers"
      :loading="fetching"
      mobile-header="Choose Customer"
      placeholder="Choose Customer..."
    />
  </main>
</template>
