<script async setup lang="ts">
import { graphql } from "@/generated";
import { useQuery } from "@urql/vue";
import { computed, ref } from "vue";

import AppLink from "@/components/AppLink.vue";
import CustomerLink from "@/components/Customer/CustomerLink.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import UserLink from "@/components/UserLink.vue";
import { useFacets, type FacetComboOption } from "@/composables/useFacets";
import type { Facet, OrderSet } from "@/composables/useQueryStringFilters";
import {
  Order_By,
  type Ticket_Custom_Form_Entries_Bool_Exp,
  type Ticket_Custom_Form_Entries_Order_By
} from "@/generated/graphql";
import { CollectionView } from "@/lib/collectionViewTypes";
import { DATE_FILTER_PRESETS, type DateRangePreset } from "@/lib/datetime";
import { injectStrict } from "@/lib/helpers";
import { BreakPointsKey } from "@/providerKeys";
import QueryFilter from "./QueryFilter.vue";
import SHInlineDate from "./SHInlineDate.vue";
import SHTableRowCount from "./SHTableRowCount.vue";

const { customFormDefinitionId, workSiteId } = defineProps<{
  customFormDefinitionId?: string;
  workSiteId?: string;
}>();

const breakpoints = injectStrict(BreakPointsKey);
const filterViewMode = ref<CollectionView>(CollectionView.Table);

const orderByFilters: OrderSet<Ticket_Custom_Form_Entries_Order_By> = {
  defaultValue: "updated",
  defaultOrderBy: Order_By.Asc,
  options: [
    // common order by filters
    {
      id: "author",
      title: "Agent",
      asc: [{ author: { full_name: Order_By.Asc } }],
      desc: [{ author: { full_name: Order_By.Desc } }]
    },
    {
      id: "created",
      title: "Created",
      asc: [{ created_at: Order_By.Asc }],
      desc: [{ created_at: Order_By.Desc }]
    },
    {
      id: "ticket",
      title: "Ticket",
      asc: [{ ticket: { ref: Order_By.Asc } }],
      desc: [{ ticket: { ref: Order_By.Desc } }]
    },
    {
      id: "updated",
      title: "Updated",
      asc: [{ updated_at: Order_By.Asc }],
      desc: [{ updated_at: Order_By.Desc }]
    }
  ]
};

if (customFormDefinitionId) {
  // if viewing by form type, should be able to sort by customers
  orderByFilters.options = [
    ...orderByFilters.options,
    {
      id: "customer",
      title: "Customer",
      asc: [{ ticket: { work_order: { customer: { title: Order_By.Asc } } } }],
      desc: [{ ticket: { work_order: { customer: { title: Order_By.Desc } } } }]
    }
  ];
} else {
  // if viewing by worksite, should be able to sort by form type
  orderByFilters.options = [
    ...orderByFilters.options,
    {
      id: "form",
      title: "Form Type",
      asc: [{ custom_form_definition: { title: Order_By.Asc } }],
      desc: [{ custom_form_definition: { title: Order_By.Desc } }]
    }
  ];
}

const buildDatePredicates = (dates: [Date, Date]) => {
  return {
    _or: [
      { created_at: { _gte: dates[0], _lte: dates[1] } },
      { updated_at: { _gte: dates[0], _lte: dates[1] } }
    ]
  };
};

const dateRangeFilters: Facet<Ticket_Custom_Form_Entries_Bool_Exp> = {
  defaultValue: "all",
  hideOnMobile: true,
  label: "Dates",
  modelName: "dateRange",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    ...DATE_FILTER_PRESETS.map((preset: DateRangePreset) => ({
      id: preset.id,
      title: preset.title,
      predicate: buildDatePredicates(preset.dates)
    }))
  ]
};

const {
  data: optionsData,
  fetching: optionsFetching,
  error: optionsError
} = await useQuery({
  query: graphql(/* GraphQL */ `
    query CustomFormEntries_Options {
      custom_form_definitions(order_by: { title: asc }) {
        id
        title
      }

      organization_agents(
        order_by: [
          { agent: { deleted_at: asc_nulls_first } }
          { agent: { full_name: asc } }
        ]
      ) {
        agent {
          id
          full_name
        }
      }
    }
  `)
});
const forms = computed(() => optionsData.value?.custom_form_definitions || []);
const agents = computed(
  () => optionsData.value?.organization_agents.map(oa => oa.agent) || []
);

const agentFilters: Facet<Ticket_Custom_Form_Entries_Bool_Exp> = {
  defaultValue: "all",
  label: "Agent",
  modelName: "agent",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    ...agents.value.map(agent => {
      return {
        id: agent.id,
        title: agent.full_name || "",
        predicate: {
          author: {
            id: { _eq: agent.id }
          }
        }
      };
    })
  ]
};

const formTypeFilters: Facet<Ticket_Custom_Form_Entries_Bool_Exp> = {
  defaultValue: "all",
  label: "Form Type",
  modelName: "formType",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    ...forms.value.map(entry => {
      return {
        id: entry.id,
        title: entry.title,
        predicate: {
          custom_form_definition: {
            id: { _eq: entry.id }
          }
        }
      };
    })
  ]
};

const activeFacets = customFormDefinitionId
  ? [dateRangeFilters, agentFilters]
  : [formTypeFilters, dateRangeFilters, agentFilters];

const {
  facetModels,
  filterWhereClause,
  resetFacets,
  searchString,
  sortOrder,
  updateFacet,
  updateSearch,
  updateSortDirection,
  updateSortType
} = useFacets(activeFacets, orderByFilters);

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query CustomFormEntries(
      $where: ticket_custom_form_entries_bool_exp!
      $orderBy: [ticket_custom_form_entries_order_by!]
      $limit: Int
      $offset: Int
    ) {
      custom_form_definitions(order_by: { title: asc }) {
        id
        title
      }

      organization_agents(
        order_by: [
          { agent: { deleted_at: asc_nulls_first } }
          { agent: { full_name: asc } }
        ]
      ) {
        agent {
          id
          full_name
        }
      }

      ticket_custom_form_entries(
        where: $where
        order_by: $orderBy
        limit: $limit
        offset: $offset
      ) {
        id
        author {
          ...UserLink
        }
        created_at
        payload

        custom_form_definition {
          id
          title
        }

        ticket {
          id
          ref
          product {
            ...ProductCard
          }
          work_order {
            customer {
              ...CustomerLink
            }
          }
        }
        updated_at
      }
      ticket_custom_form_entries_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `),
  variables: computed(() => ({
    where: {
      _and: [
        // this picks up when we're nested under a custom form
        customFormDefinitionId
          ? { custom_form_definition_id: { _eq: customFormDefinitionId } }
          : {},
        // this picks up when we're nested under a work site
        workSiteId ? { ticket: { work_site_id: { _eq: workSiteId } } } : {},
        // this applies all the where clauses
        ...(filterWhereClause.value || []),
        // this adds any search text set from the filter
        { ticket: { ref: { _ilike: `${searchString.value}%` } } }
      ]
    },
    orderBy: sortOrder.value,
    limit: 25
  }))
});
const entries = computed(() => data.value?.ticket_custom_form_entries || []);
const entriesTotal = computed(
  () => data.value?.ticket_custom_form_entries_aggregate.aggregate?.count || 0
);
</script>

<template>
  <article class="vertical custom-form-entries">
    <QueryFilter
      v-model:view-mode="filterViewMode"
      :facets="activeFacets"
      :facet-models="facetModels"
      :fetching="fetching"
      :order-set="orderByFilters"
      :result-count="entriesTotal"
      clear-all-filters
      no-calendar-view
      no-card-view
      no-table-view
      placeholder="Search by Ticket..."
      searchable
      @reset:facets="resetFacets"
      @update:facet="updateFacet($event as FacetComboOption)"
      @update:search="updateSearch($event)"
      @update:sort-direction="updateSortDirection($event)"
      @update:sort-type="updateSortType($event)"
    >
      <template #mobile-header>Custom Form Entries Filter</template>
    </QueryFilter>

    <SHSpinner v-if="optionsFetching || fetching" />

    <SHNote v-else-if="optionsError || error" theme="danger">
      {{ error?.message }}
    </SHNote>

    <SHTable v-else :rows="entries" empty-message="No form entries found.">
      <template #header>
        <th v-if="customFormDefinitionId">Ticket</th>
        <th v-else>Form Type</th>
        <th v-if="customFormDefinitionId && breakpoints.includes('tablet')">
          Customer
        </th>
        <th v-else-if="workSiteId && breakpoints.includes('tablet')">Ticket</th>
        <th v-if="breakpoints.includes('mobile')">Agent</th>
        <th v-if="breakpoints.includes('tablet')">Date</th>
      </template>

      <template #row="{ row }">
        <td v-if="customFormDefinitionId">
          <AppLink
            :to="{
              name: 'CustomFormDetail',
              params: {
                ticketId: row.ticket.id
              },
              query: {
                ticketCustomFormEntryId: row.id,
                customFormDefinitionId
              }
            }"
          >
            {{ row.ticket.ref }}
          </AppLink>
        </td>
        <td v-else>
          <AppLink
            :to="{
              query: { formType: row.custom_form_definition.id }
            }"
          >
            {{ row.custom_form_definition.title }}
          </AppLink>
        </td>

        <td v-if="customFormDefinitionId && breakpoints.includes('tablet')">
          <CustomerLink :customer="row.ticket?.work_order?.customer" />
        </td>
        <td v-else-if="workSiteId && breakpoints.includes('tablet')">
          <AppLink
            :to="{
              name: 'CustomFormDetail',
              params: {
                ticketId: row.ticket.id
              },
              query: {
                ticketCustomFormEntryId: row.id,
                customFormDefinitionId: row.custom_form_definition.id
              }
            }"
          >
            {{ row.ticket.ref }}
          </AppLink>
        </td>
        <td v-if="breakpoints.includes('mobile')">
          <UserLink :user="row.author" />
        </td>
        <td v-if="breakpoints.includes('tablet')">
          <SHInlineDate :d="row.updated_at" />
        </td>
      </template>

      <template #footer>
        <SHTableRowCount :count="entries.length" label="form" />
      </template>
    </SHTable>
  </article>
</template>

<style lang="scss" scoped></style>
