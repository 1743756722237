<script async setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import DarkModeToggle from "@/components/DarkModeToggle.vue";
import DynamicRoleSwitcher from "@/components/MainNavigation/DynamicRoleSwitcher.vue";
import OrgLogo from "@/components/OrgLogo.vue";
import SHButton from "@/components/SHButton.vue";
import SHMenu from "@/components/SHMenu.vue";
import ThemeChooser from "@/components/ThemeChooser.vue";
import UserLink from "@/components/UserLink.vue";
import { useFeatureFlags } from "@/composables/useFeatureFlags";
import { useHubSpotChat } from "@/composables/useHubSpotChat";
import { useRole } from "@/composables/useRole";
import { injectStrict } from "@/lib/helpers";
import { useNavigation } from "@/mainNavigation";
import { CurrentUserKey, DarkModeKey, OrganizationKey } from "@/providerKeys";
import type { NavigationNode } from "@/types";
import {
  faChevronRight,
  faCircleQuestion,
  faRefresh
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SHSpinner from "../SHSpinner.vue";
import SideBarDivider from "./SideBarDivider.vue";
import SideBarLink from "./SideBarLink.vue";

const { open: openChatWidget, loading: chatLoading } = useHubSpotChat();
const { sidebarOrder } = useNavigation();

const { isCollapsed } = defineProps<{
  isCollapsed?: boolean;
  isAuthenticated?: boolean;
  showUpdateButton: boolean;
}>();

const emit = defineEmits<{
  (e: "toggle"): void;
  (e: "logout"): void;
  (e: "update-app"): void;
}>();

const isDark = injectStrict(DarkModeKey);
const currentOrg = injectStrict(OrganizationKey);
const currentUser = injectStrict(CurrentUserKey);

const { can } = useRole();
const { has } = useFeatureFlags();
const hasPermission = (node: NavigationNode): boolean =>
  (!node.allowedPermissions?.length ||
    node.allowedPermissions.some(p => can(p))) &&
  (!node.allowedWithFeatureFlags?.length ||
    node.allowedWithFeatureFlags.some(f => has(f)));
</script>

<template>
  <section class="sidebar vertical">
    <nav :class="{ isCollapsed }">
      <header>
        <AppLink to="/">
          <OrgLogo
            :organization="currentOrg"
            :dark="isDark"
            :square="isCollapsed"
          />
        </AppLink>
        <aside @click="emit('toggle')">
          <FontAwesomeIcon
            color="var(--color-surface-900)"
            :icon="faChevronRight"
          />
        </aside>
      </header>

      <main class="links vertical">
        <div class="align-center" :class="{ isCollapsed }">
          <SHButton
            v-if="showUpdateButton"
            class="level tight"
            color="success"
            size="sm"
            :square="isCollapsed"
            @click="emit('update-app')"
          >
            <div class="level tight">
              <FontAwesomeIcon :icon="faRefresh" />
              <span v-if="!isCollapsed">Update</span>
            </div>
          </SHButton>
        </div>

        <div v-for="m of sidebarOrder" :key="m.title">
          <div class="topLevel">
            <SideBarLink
              v-if="hasPermission(m)"
              :avatar="m.avatar"
              :to="m.to || ''"
              :icon="m.icon"
              :iconColor="
                isCollapsed
                  ? 'var(--color-surface-900)'
                  : 'var(--color-primary)'
              "
              :is-collapsed="isCollapsed"
            >
              <UserLink v-if="m.title === 'You'" :user="currentUser" />
              <span v-else>
                {{ m.title }}
              </span>

              <template #popup="{ close }">
                <div class="popup">
                  <header>
                    <UserLink v-if="m.title === 'You'" :user="currentUser" />
                    <span v-else>
                      {{ m.title }}
                    </span>
                  </header>
                  <SHMenu
                    :items="
                      m.children
                        ?.filter(c => hasPermission(c))
                        .map(c => ({
                          id: c.title,
                          label: c.title,
                          to: c.to
                          // icon: c.icon
                        })) ?? []
                    "
                    @click="
                      i => {
                        $router.push(i.to || '');
                        close();
                      }
                    "
                  />
                </div>
              </template>
            </SideBarLink>

            <div v-if="!isCollapsed" class="childLinks">
              <template v-for="(j, idx2) of m.children || []" :key="idx2">
                <SideBarLink v-if="hasPermission(j)" :to="j.to">
                  {{ j.title }}
                </SideBarLink>
              </template>
            </div>
          </div>
          <SideBarDivider />
        </div>
      </main>

      <AppLink @click="openChatWidget">
        <div class="level-center tight">
          <FontAwesomeIcon
            v-if="!chatLoading"
            color="var(--color-surface-900)"
            :icon="faCircleQuestion"
          />
          <SHSpinner v-else size="xs" />
          <span v-show="!isCollapsed">Support Desk</span>
        </div>
      </AppLink>

      <div class="vertical xmargin">
        <ThemeChooser v-if="!isCollapsed" />
        <DarkModeToggle :condensed="isCollapsed" />
        <DynamicRoleSwitcher v-if="!isCollapsed" />
      </div>
    </nav>
  </section>
</template>

<style lang="scss" scoped>
section.sidebar {
  height: 100%;
  background: var(--color-surface-100);
  border-right: thin solid var(--color-secondary);
  box-shadow: 0 0 0.5em rgba(50, 50, 52, 0.351);

  nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 1em;
    transition: all 0.25s ease-in-out;
    padding: 1em 0 2em;

    &.isCollapsed {
      align-items: center;

      :deep(.app-link) {
        text-decoration: none;
      }

      header {
        min-width: 100%;

        img {
          max-width: 3em;
          max-height: 3em;
        }
        aside {
          cursor: e-resize;
          svg {
            transform: rotateZ(360deg);
          }
        }
      }

      .sidebar-divider {
        margin: 1.25em 0;
      }

      .links {
        padding-top: 0.75em;
      }
    }

    header {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 3.5em;

      :deep(.app-link) {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
      }

      img {
        min-width: 2em;
        min-height: 2em;
        max-width: 10em;
        max-height: 4em;
      }

      aside {
        position: absolute;
        top: 4.5em;
        right: 0;
        background: var(--color-secondary);
        transition: all 0.2s ease-in-out;
        padding: 0.5em;
        border-radius: 0.25em 0 0 0.25em;
        font-size: 0.85em;
        cursor: w-resize;
        svg {
          transition: all 0.2s ease-in-out;
          transform: rotateZ(180deg);
        }
        &:hover {
          background: var(--color-primary);
        }
      }
    }
  }

  .sidebar-divider {
    margin: 1em 0 0;
  }

  .links {
    padding: 0 1em;

    .topLevel {
      .popup {
        border-radius: 0 0.5em 0.5em 0;
        border: thin solid var(--color-surface-300);
        border-left: none;
        header {
          border-bottom: thin solid var(--color-surface-300);
        }
        background: var(--color-surface-100);
        color: var(--color-surface-600);

        header {
          border-bottom: thin solid var(--color-surface-600);
          padding: 0.5em 1em;
        }

        :deep(.menu-link) {
          width: 100%;
        }
      }

      .childLinks {
        font-weight: 400;
        display: flex;
        flex-direction: column;
        padding-top: 0.5em;
        padding-left: 1.85rem;
        gap: 0.25em;

        :deep(.app-link) {
          text-decoration: none;
          padding: 0;
          color: var(--color-surface-500);
          &.active {
            color: var(--color-surface-900);
          }
        }
      }
    }
  }
}
</style>
