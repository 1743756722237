import { graphql } from "@/generated";
import { useMutation } from "@urql/vue";
import { ref } from "vue";
import { useToaster, type SHToastOptions } from "./useToaster";

const failedToast: SHToastOptions = {
  title: "Notifications Not Enabled",
  message:
    "Failed to enable push notifications. Please check your permissions and network connection.",
  theme: "danger"
};

const successToast: SHToastOptions = {
  message: "Push notifications now enabled.",
  theme: "success"
};

let serviceWorker: ServiceWorkerRegistration;
async function getServiceWorker() {
  if (!serviceWorker) serviceWorker = await navigator.serviceWorker.ready;
  return serviceWorker;
}

export default function useWebpush() {
  const toaster = useToaster();

  const { executeMutation: savePushSubscription } = useMutation(
    graphql(/* GraphQL */ `
      mutation Users_Subscription($userId: String!, $subscription: String!) {
        update_users_by_pk(
          pk_columns: { id: $userId }
          _set: { subscription: $subscription }
        ) {
          id
        }
      }
    `)
  );

  const isLoading = ref(true);
  const subscription = ref<PushSubscription | null>(null);

  async function register(userId: string) {
    const { VAPID_PUBLIC_KEY = import.meta.env.VITE_VAPID_PUBLIC_KEY } =
      window.config;
    //  Request permission for notifications
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.log("Permission not granted for Notification.");
      return toaster.createToast(failedToast), false;
    }

    const registration = await getServiceWorker();
    try {
      const sub = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: VAPID_PUBLIC_KEY
      });
      await savePushSubscription({
        userId,
        subscription: JSON.stringify(sub)
      });
      subscription.value = sub;
    } catch (err) {
      console.log("Failed to subscribe the user: ", err);
      return toaster.createToast(failedToast), false;
    }

    // Listen for messages from the service worker
    // navigator.serviceWorker.addEventListener("message", event => {
    //   console.log("Received a message from service worker:", event.data);
    // });
    return toaster.createToast(successToast), true;
  }

  getServiceWorker().then(sw =>
    sw.pushManager.getSubscription().then(sub => {
      subscription.value = sub;
      isLoading.value = false;
    })
  );

  return { register, subscription, isLoading };
}
