import ModuleHeader from "@/components/ModuleHeader.vue";
import QuoteForm from "@/components/QuoteForm.vue";
import SHButton from "@/components/SHButton.vue";
import QuoteActivity from "@/views/QuoteActivity.vue";
import QuoteDetail from "@/views/QuoteDetail.vue";
import QuoteNotes from "@/views/QuoteNotes.vue";
import QuotePreview from "@/views/QuotePreview.vue";
import QuoteSummary from "@/views/QuoteSummary.vue";
import QuotesView from "@/views/QuotesView.vue";
import { faDollarSquare, faPlus } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { h } from "vue";
import { type RouteRecordRaw } from "vue-router";

export const quoteRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "QuotesView",
    props: true,
    components: {
      default: h(QuotesView, { class: "xmargin" }),
      header: h(
        ModuleHeader,
        {
          icon: faDollarSquare,
          allowedPermissions: ["quotes:manage_own", "quotes:manage_all"]
        },
        {
          default: () => "Quotes",
          nav: () =>
            h(
              SHButton,
              { to: { name: "QuoteCreate" }, color: "primary", size: "sm" },
              () =>
                h("div", [
                  h(FontAwesomeIcon, { icon: faPlus, fixedWidth: true }),
                  h(FontAwesomeIcon, {
                    icon: faDollarSquare,
                    fixedWidth: true
                  })
                ])
            )
        }
      )
    }
  },
  {
    path: "new",
    name: "QuoteCreate",
    props: true,
    components: {
      default: h(QuoteForm, { class: "xmargin" }),
      header: h(
        ModuleHeader,
        { icon: faDollarSquare },
        () => "Create New Quote"
      )
    }
  },
  {
    path: ":quoteId/edit",
    name: "QuoteEdit",
    props: true,
    components: {
      default: h(QuoteForm, { class: "xmargin" }),
      header: h(
        ModuleHeader,
        { icon: faDollarSquare },
        {
          default: () => "Quote Edit"
        }
      )
    }
  },
  {
    path: ":quoteId",
    name: "QuoteDetail",
    redirect: { name: "QuoteActivity" },
    props: true,
    components: {
      // @ts-expect-error - For some reason, TypeScript does not use the correct overload
      default: h(QuoteDetail, { class: "xmargin" }),
      header: h(
        ModuleHeader,
        { icon: faDollarSquare },
        {
          default: () => "Quote Detail"
        }
      )
    },
    children: [
      {
        path: "activity",
        name: "QuoteActivity",
        component: QuoteActivity,
        props(route) {
          return {
            ...route.params,
            ...route.query
          };
        }
      },
      {
        path: "summary",
        name: "QuoteSummary",
        component: QuoteSummary,
        props(route) {
          return {
            ...route.params,
            ...route.query
          };
        }
      },
      {
        path: "notes",
        name: "QuoteNotes",
        component: QuoteNotes,
        props: true
      },
      {
        path: "preview",
        name: "QuotePreview",
        component: QuotePreview,
        props(route) {
          return {
            ...route.params,
            ...route.query
          };
        }
      }
    ]
  }
];
