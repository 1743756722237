<script async setup lang="ts">
import { dueDateOptions, pivotOptions } from "@/components/Invoices/types";
import SHField from "@/components/SHField.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHToggle from "@/components/SHToggle.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import {
  Invoice_Pivot_Options_Enum,
  type UpdateCustomerSettingsMutation
} from "@/generated/graphql";
import type { CombinedError } from "@urql/vue";
import { useMutation, useQuery } from "@urql/vue";
import { computed, ref } from "vue";
import TimeSheetSettings from "./TimeSheetSettings.vue";

// This is disabled pending implementation of Invoices
const showInvoiceSettings = ref(false);

const { createToast } = useToaster();

const props = defineProps<{
  customerId: string;
}>();

const {
  data: customerData,
  error,
  fetching
} = await useQuery({
  query: graphql(/* GraphQL */ `
    query CustomerSettings($customerId: uuid!) {
      customers_by_pk(id: $customerId) {
        id
        invoice_pivot
        invoice_terms
        requires_signature
      }
    }
  `),
  variables: computed(() => ({
    customerId: props.customerId
  }))
});

const customer = computed(() => customerData.value?.customers_by_pk);

const { executeMutation: updateCustomer } = useMutation(
  graphql(/* GraphQL */ `
    mutation UpdateCustomerSettings(
      $customerId: uuid!
      $_set: customers_set_input!
    ) {
      update_customers_by_pk(pk_columns: { id: $customerId }, _set: $_set) {
        id
      }
    }
  `)
);

const generalSettingsForm = ref<{ requiresSignature: boolean | undefined }>({
  requiresSignature: customer.value?.requires_signature
});

const invoiceDefaultsForm = ref<{
  invoiceTerms: number;
  invoicePivot: Invoice_Pivot_Options_Enum;
}>({
  invoiceTerms: customer.value?.invoice_terms ?? 30,
  invoicePivot: customer.value?.invoice_pivot ?? Invoice_Pivot_Options_Enum.None
});

async function onConfirmGeneralSettings() {
  const { data: updateResult, error: updateError } = await updateCustomer({
    customerId: props.customerId,
    _set: {
      requires_signature: generalSettingsForm.value.requiresSignature
    }
  });

  handleUpdateResult(updateResult, updateError, "General settings");
}

async function onConfirmInvoiceDefaults() {
  const { data: updateResult, error: updateError } = await updateCustomer({
    customerId: props.customerId,
    _set: {
      invoice_pivot: invoiceDefaultsForm.value.invoicePivot,
      invoice_terms: invoiceDefaultsForm.value.invoiceTerms
    }
  });

  handleUpdateResult(updateResult, updateError, "Invoice defaults");
}

function handleUpdateResult(
  updateResult: UpdateCustomerSettingsMutation | undefined,
  updateError: CombinedError | undefined,
  settingsType: string
) {
  if (updateError) {
    createToast({
      title: `Unable to update the customer settings.`,
      message: updateError.message || "Unknown error.",
      theme: "danger",
      requiresInteraction: true
    });
  } else if (updateResult) {
    createToast({
      message: `Customer settings updated.`,
      theme: "success"
    });
  }
}
</script>

<template>
  <SHSpinner v-if="fetching" />
  <SHNote v-else-if="error" theme="danger">
    {{ error }}
  </SHNote>
  <article v-else-if="customer" class="customer-settings vertical">
    <h1>Customer Settings</h1>
    <section>
      <h2>General Settings</h2>
      <SHField label="Requires Signature on Tickets">
        <div class="level tight">
          <span>No</span>
          <SHToggle v-model="generalSettingsForm.requiresSignature" />
          <span>Yes</span>
        </div>
      </SHField>
      <footer>
        <UndoConfirm
          confirm-enabled
          @confirm="onConfirmGeneralSettings"
          @undo="$router.back()"
        />
      </footer>
    </section>
    <section>
      <h2>Time Sheet Settings</h2>
      <TimeSheetSettings :customer-id="customerId" />
    </section>
    <section v-if="showInvoiceSettings">
      <h2>Customer Invoice Defaults</h2>
      <div>
        <SHField label="Invoice Terms" />
        <template v-for="o in dueDateOptions" :key="o.value">
          <label class="level tight">
            <input
              v-model="invoiceDefaultsForm.invoiceTerms"
              type="radio"
              :value="o.value"
            />
            <span>{{ o.label }}</span>
          </label>
        </template>
      </div>
      <div>
        <SHField label="Invoice Line Item Grouping" />
        <template v-for="o in pivotOptions" :key="o.value">
          <label class="level tight">
            <input
              v-model="invoiceDefaultsForm.invoicePivot"
              type="radio"
              :value="o.value"
            />
            <span>{{ o.label }}</span>
          </label>
        </template>
      </div>
      <footer>
        <UndoConfirm
          confirm-enabled
          @confirm="onConfirmInvoiceDefaults"
          @undo="$router.back()"
        />
      </footer>
    </section>
  </article>
</template>
