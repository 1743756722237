<script lang="ts" async setup>
import QueryFilter from "@/components/QueryFilter.vue";
import QuoteCard from "@/components/QuoteCard.vue";
import SHNote from "@/components/SHNote.vue";
import { useFacets } from "@/composables/useFacets";
import type { Facet, OrderSet } from "@/composables/useQueryStringFilters";
import { graphql } from "@/generated";
import type {
  Quote_Quotes_Bool_Exp,
  Quote_Quotes_Order_By
} from "@/generated/graphql";
import { Lifecycle_Statuses_Enum, Order_By } from "@/generated/graphql";
import { CollectionView } from "@/lib/collectionViewTypes";
import { injectStrict } from "@/lib/helpers";
import { useLogger } from "@/logger";
import { CurrentUserKey } from "@/providerKeys";
import { useQuery } from "@urql/vue";
import { computed, ref } from "vue";

const { log } = useLogger("QuotesView"); // eslint-disable-line @typescript-eslint/no-unused-vars
const currentUser = injectStrict(CurrentUserKey);

const props = defineProps<{
  customerId?: string;
}>();

const filterViewMode = ref<CollectionView>(CollectionView.Card);

const { data: optionsData } = await useQuery({
  query: graphql(/* GraphQL */ `
    query QuotesViewOptions($currentUserId: String!) {
      customers(
        where: { quotes_aggregate: { count: { predicate: { _gt: 0 } } } }
        order_by: { title: asc }
      ) {
        id
        title
      }
      organization_agents(
        where: {
          quotes_aggregate: { count: { predicate: { _gt: 0 } } }
          agent_id: { _neq: $currentUserId }
        }
        order_by: { agent: { full_name: asc } }
      ) {
        agent {
          full_name
          id
        }
      }
    }
  `),
  variables: { currentUserId: currentUser.value.id }
});
const quoteAuthors = computed(
  () => optionsData.value?.organization_agents || []
);
const quoteCustomers = computed(() => optionsData.value?.customers || []);

const authorFilters: Facet<Quote_Quotes_Bool_Exp> = {
  modelName: "authors",
  hideOnMobile: true,
  label: "Author",
  defaultValue: "all",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    ...quoteAuthors.value.map(oa => ({
      id: oa.agent.id,
      title: `${oa.agent.full_name} `,
      predicate: {
        author: { id: { _eq: oa.agent.id } }
      }
    }))
  ]
};

const customerFilters: Facet<Quote_Quotes_Bool_Exp> = {
  defaultValue: "all",
  hidden: !!props.customerId,
  hideOnMobile: true,
  label: "Customer",
  modelName: "customers",
  fuseOptions: {
    keys: ["title", "abbreviation"],
    threshold: 0.5
  },
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    ...quoteCustomers.value.map(cust => {
      return {
        id: cust.id,
        title: cust.title,
        predicate: {
          customer_id: { _eq: cust.id }
        }
      };
    })
  ]
};

const lifecycleFilters: Facet<Quote_Quotes_Bool_Exp> = {
  defaultValue: "all",
  label: "Status",
  modelName: "status",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    {
      id: "draft",
      title: "Draft",
      predicate: {
        lifecycle_status: { _eq: Lifecycle_Statuses_Enum.Pending }
      }
    },
    {
      id: "open",
      title: "Open",
      predicate: {
        lifecycle_status: { _eq: Lifecycle_Statuses_Enum.Open }
      }
    },
    {
      id: "closed",
      title: "Closed",
      predicate: {
        lifecycle_status: { _eq: Lifecycle_Statuses_Enum.Closed }
      }
    }
  ]
};

const archiveFilters: Facet<Quote_Quotes_Bool_Exp> = {
  defaultValue: "active",
  label: "Archived",
  modelName: "archived",
  filters: [
    {
      id: "active",
      title: "Active",
      predicate: { deleted_at: { _is_null: true } }
    },
    {
      id: "archived",
      title: "Archived",
      predicate: { deleted_at: { _is_null: false } }
    },
    {
      id: "all",
      title: "All",
      predicate: { ref: { _is_null: false } }
    }
  ]
};

const orderByFilters: OrderSet<Quote_Quotes_Order_By> = {
  defaultValue: "updated",
  options: [
    {
      id: "ref",
      title: "Quote ID",
      asc: [{ ref: Order_By.Asc }],
      desc: [{ ref: Order_By.Desc }]
    },
    {
      id: "created",
      title: "Created",
      asc: [{ created_at: Order_By.Asc }],
      desc: [{ created_at: Order_By.Desc }]
    },
    {
      id: "updated",
      title: "Updated",
      asc: [{ updated_at: Order_By.Asc }],
      desc: [{ updated_at: Order_By.Desc }]
    }
  ]
};

const {
  facetModels,
  filterWhereClause,
  // mode,
  resetFacets,
  searchString,
  sortOrder,
  updateFacet,
  updateSearch,
  updateSortDirection,
  updateSortType,
  updateViewMode
} = useFacets(
  [authorFilters, customerFilters, lifecycleFilters, archiveFilters],
  orderByFilters
);

const { data, fetching, error } = useQuery({
  query: graphql(/* GraphQL */ `
    query QuotesViews(
      $where: quote_quotes_bool_exp!
      $order_by: [quote_quotes_order_by!]
      $limit: Int!
      $offset: Int!
    ) {
      quote_quotes(
        where: $where
        order_by: $order_by
        limit: $limit
        offset: $offset
      ) {
        id
        ...QuoteCard

        items {
          qty
          product {
            title
          }
        }
      }
      quote_quotes_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `),
  variables: computed(() => ({
    where: {
      _and: [
        // this picks up when we're nested under a customer
        props.customerId ? { customer_id: { _eq: props.customerId } } : {},
        // this applies all the where clauses from TicketsFilter
        ...(filterWhereClause.value || []),
        // this adds any search text set from the filter
        { ref: { _ilike: `${searchString.value}%` } }
      ]
    },
    order_by: sortOrder.value,
    limit: 100,
    offset: 0
  })),
  context: {
    additionalTypenames: ["quote_quotes"]
  }
});
const quotes = computed(() => data.value?.quote_quotes || []);
</script>

<template>
  <SHNote v-if="error" theme="danger">{{ error.message }}</SHNote>
  <article v-else class="quotes-view vertical">
    <QueryFilter
      v-model:view-mode="filterViewMode"
      :facets="[
        authorFilters,
        customerFilters,
        lifecycleFilters,
        archiveFilters
      ]"
      :facet-models="facetModels"
      :fetching="fetching"
      :order-set="orderByFilters"
      :result-count="data?.quote_quotes_aggregate.aggregate?.count"
      clear-all-filters
      placeholder="Search by Ticket..."
      searchable
      no-table-view
      no-calendar-view
      @reset:facets="resetFacets"
      @update:facet="updateFacet($event)"
      @update:search="updateSearch($event)"
      @update:sort-direction="updateSortDirection($event)"
      @update:sort-type="updateSortType($event)"
      @update:view-mode="updateViewMode($event)"
    >
      <template #mobile-header>Quote Filter</template>
    </QueryFilter>

    <template v-if="filterWhereClause !== undefined && !quotes?.length">
      <slot name="empty">
        <SHNote theme="warning">No quotes found.</SHNote>
      </slot>
    </template>

    <section class="vertical">
      <QuoteCard
        v-for="q of quotes"
        :key="q.id"
        :quote="q"
        @click:id="
          $router.push({ name: 'QuoteDetail', params: { quoteId: q.id } })
        "
      />
    </section>
  </article>
</template>
