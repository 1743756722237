<script setup lang="ts">
import { graphql, useFragment, type FragmentType } from "@/generated";
import { faDigging } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";

import SHPill from "@/components/SHPill.vue";
import SHTextEditor from "@/components/TextEditor/SHTextEditor.vue";
import TimeLineEntry from "@/components/TimeLineEntry.vue";
import UserLink from "@/components/UserLink.vue";
import { formatInterval } from "@/lib/datetime";
import MediaUploadsView from "@/views/MediaUploadsView.vue";
import { parseISO } from "date-fns";

const fragment = graphql(/* GraphQL */ `
  fragment WorkLogCard on work_logs {
    id
    started_at
    ended_at
    notesj
    created_at
    deleted_at

    author {
      ...UserLink
    }

    media_uploads(
      where: { deleted_at: { _is_null: true } }
      order_by: [{ created_at: asc }]
    ) {
      id
      orig_filename
      ...MediaUploadCard
      ...SHLightbox
    }
  }
`);

const props = defineProps<{
  editable?: boolean;
  workLog: FragmentType<typeof fragment>;
}>();

const workLog = computed(() => useFragment(fragment, props.workLog));

const formattedInterval = computed(() =>
  formatInterval({
    start: parseISO(workLog.value.started_at),
    end: parseISO(workLog.value.ended_at)
  })
);
</script>

<template>
  <TimeLineEntry
    :start="workLog.started_at"
    :end="workLog.ended_at"
    icon-fg-color="var(--color-primary-up-300)"
    icon-bg-color="var(--color-primary-down-300)"
    class="work-log-card"
    :editable="editable"
    @edit="
      $router.push({
        name: 'WorkLogDetail',
        params: { workLogId: workLog.id }
      })
    "
  >
    <template #icon>
      <FontAwesomeIcon :icon="faDigging" fixed-width />
    </template>

    <template #default>
      <UserLink :user="workLog.author" link />
      logged
      {{ formattedInterval }}
    </template>

    <template #detail>
      <main class="vertical tight">
        <SHTextEditor v-if="workLog.notesj" :model-value="workLog.notesj" />
        <MediaUploadsView
          :media-uploads="workLog.media_uploads ?? []"
          hide-empty-message
          no-controls
        />
        <div class="level tight">
          <SHPill>
            <template #left>Labor</template>
            <template #right>
              {{ formattedInterval }}
            </template>
          </SHPill>
        </div>
      </main>
    </template>
  </TimeLineEntry>
</template>
