import { inject, type InjectionKey } from "vue";

export function injectStrict<T>(key: InjectionKey<T>): T {
  const injectedValue = inject(key);

  if (typeof injectedValue === "undefined") {
    throw new Error(`Variable undefined at injection key ${key.description}`);
  }

  return injectedValue;
}

export function humanizeEnum(enumVal: string) {
  // converts some_val_foo to "Some Val Foo"
  return enumVal
    .trim()
    .replaceAll("_", " ")
    .split(" ")
    .reduce((prev, cur) => {
      if (cur.length) {
        // at least 2 chars
        prev.push(cur.at(0)?.toUpperCase() + cur.slice(1)?.toLowerCase());
      }
      return prev;
    }, [] as string[])
    .join(" ");
}

// https://github.com/hasura/graphql-engine/issues/2381
export const makeArrayWorkWithHasura = (
  someList: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
) => JSON.stringify(someList).replace("[", "{").replace("]", "}");

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));
