<script setup lang="ts">
import { formatUSD } from "@/formatters";
import { graphql, useFragment, type FragmentType } from "@/generated";
import { Delivery_Statuses_Enum } from "@/generated/graphql";
import { humanizeEnum } from "@/lib/helpers";
import { getStatusForeground } from "@/lib/quoteStatus";
import { faChevronUp } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, ref } from "vue";
import AppLink from "./AppLink.vue";
import CustomerLink from "./Customer/CustomerLink.vue";
import SHBadge from "./SHBadge.vue";
import SHField from "./SHField.vue";
import SHInlineDate from "./SHInlineDate.vue";
import SHPill from "./SHPill.vue";
import SHTextEditor from "./TextEditor/SHTextEditor.vue";
import UserLink from "./UserLink.vue";
// import { BreakPointsKey } from "@/providerKeys";
// import { injectStrict } from "@/lib/helpers";

const fragment = graphql(/* GraphQL */ `
  fragment QuoteCard on quote_quotes {
    id
    ref
    created_at
    updated_at
    deleted_at
    lifecycle_status
    delivery_status
    review_status
    notesj

    author {
      ...UserLink
    }

    customer {
      title
      abbreviation
      ...CustomerLink
    }

    latest_events: events(order_by: [{ created_at: desc }], limit: 1) {
      id
      created_at
      event_type
      author {
        ...UserLink
      }
    }

    items_aggregate {
      aggregate {
        sum {
          subtotal_cents
        }
      }
    }
  }
`);

const props = defineProps<{
  quote: Partial<FragmentType<typeof fragment>>;
  expandable?: boolean;
  noHover?: boolean;
  reportMode?: boolean;
}>();

const emit = defineEmits<{
  (e: "click:id", value: typeof quote.value): void;
}>();

// const breakpoints = injectStrict(BreakPointsKey);
// const isMobile = injectStrict(IsMobileKey);

const showDetails = ref(false);
const quote = computed(() => useFragment(fragment, props.quote));
const lastEvent = computed(() => quote.value.latest_events.at(0));
const statusColor = computed(() =>
  // this is setup as a computed so we can v-bind it in the css, and keep the template references shorter
  getStatusForeground(quote.value.delivery_status, quote.value.review_status)
);
</script>

<template>
  <article
    class="ticket-card"
    :class="{
      hoverEnabled: !noHover,
      report: reportMode
    }"
    @click="!noHover && emit('click:id', quote)"
  >
    <header>
      <span class="level tight">
        <SHPill
          :right="
            formatUSD(quote.items_aggregate.aggregate?.sum?.subtotal_cents || 0)
          "
          :color="statusColor"
        >
          <template #left>
            <span class="ref">{{ quote.ref }}</span>
          </template>
        </SHPill>

        <SHBadge v-if="quote.deleted_at !== null" color="var(--color-danger)">
          ARCHIVED
        </SHBadge>
      </span>
      <span class="truncate">
        <div class="level tight wrap">
          <template
            v-if="quote.delivery_status === Delivery_Statuses_Enum.Draft"
          >
            <SHPill :right="quote.delivery_status" :color="statusColor" />
          </template>
          <template v-else>
            <SHPill :right="quote.review_status" :color="statusColor" />
          </template>

          <!-- <SHPill
            left="LIFECYCLE"
            :right="quote.lifecycle_status"
            color="var(--color-primary)"
          /> -->

          <span style="color: var(--color-secondary)">◦</span>

          <CustomerLink
            v-if="quote.customer"
            :disabled="!noHover || reportMode"
            :customer="quote.customer"
          >
            {{ quote.customer?.abbreviation ?? quote.customer?.title ?? "?" }}
          </CustomerLink>
        </div>
      </span>
    </header>

    <main>
      <section>
        {{ humanizeEnum(lastEvent?.event_type ?? "UNKNOWN") }}
        <SHInlineDate
          :d="lastEvent?.created_at ?? quote.updated_at"
          format="long_time"
        />
        by
        <UserLink :user="lastEvent?.author" />
      </section>

      <section>
        <UserLink :user="quote.author" />
      </section>
    </main>

    <footer v-if="expandable && quote.notesj" class="vertical">
      <SHField v-show="showDetails" label="Ticket Notes/Instructions" block>
        <SHTextEditor v-model="quote.notesj" />
      </SHField>

      <div style="text-align: center">
        <AppLink @click.prevent.stop="showDetails = !showDetails">
          <FontAwesomeIcon
            :icon="faChevronUp"
            :rotation="showDetails ? undefined : 180"
            style="transition: transform 150ms ease-in-out"
          />
        </AppLink>
      </div>
    </footer>
  </article>
</template>

<style lang="scss" scoped>
@use "../assets/scss/breakpoints.scss" as bp;
article.ticket-card {
  position: relative;
  font-size: 0.875em;

  &:not(.report) {
    border: thin solid var(--color-surface-200);
    background: var(--color-surface-100);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
  }

  &.isClosed {
    background: var(--color-surface-200-opacity-40);
    box-shadow: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: calc(50% - 30px);
    top: 0%;
    width: 60px;
    height: 4px;
    background: v-bind(statusColor);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  header {
    display: flex;
    gap: 0.5em;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.5em 1em;
    font-weight: var(--font-weight-bold);
    color: var(--color-surface-500);

    .ref {
      font-size: 1.5em;
      font-weight: 800;
    }

    @include bp.tablet {
      flex-direction: row;
    }

    .truncate {
      display: inline-flex;
      justify-content: flex-end;
      gap: 0.25em;
      flex: 1;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  main {
    border-top: thin solid var(--color-surface-200);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0.5em 1em;

    nav {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.25em;
    }
  }

  footer {
    padding-left: 1em;
    padding-bottom: 0.5em;
  }

  &.hoverEnabled:hover {
    transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    background: var(--color-primary-opacity-15);
    cursor: pointer;
  }
}
</style>
