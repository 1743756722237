<template>
  <div class="timeline">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.timeline {
  display: flex;
  flex-direction: column;
  gap: 1em;
  position: relative;

  &::before {
    content: "";
    top: 2em;
    left: 0.825em;
    width: 1px;
    height: calc(100%);
    border-left: 2px dotted var(--color-surface-600-opacity-15);
    position: absolute;
    z-index: -1;
  }
}
</style>
