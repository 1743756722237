<script setup lang="ts">
import type { ButtonSize, ButtonTheme } from "@/types";
import type { RouteLocationNamedRaw } from "vue-router";
import SHSpinner from "./SHSpinner.vue";

const {
  color = "surface-200",
  active,
  to,
  href,
  fullWidth
} = defineProps<{
  to?: RouteLocationNamedRaw;
  disabled?: boolean;
  href?: string;
  active?: boolean;
  color?: ButtonTheme;
  square?: boolean;
  size?: ButtonSize;
  innerClass?: string;
  fullWidth?: boolean;
  loading?: boolean;
}>();
const replace = window.location.replace;
</script>

<template>
  <button
    class="sh-btn"
    :disabled="disabled || loading"
    :class="{
      square,
      active,
      sm: size === 'sm',
      md: size === 'md',
      lg: size === 'lg',
      [color as string]: true,
      'full-width': fullWidth
    }"
    @click="
      () => {
        if (to) {
          $router.push(to);
        } else if (href) {
          replace(href);
        }
      }
    "
  >
    <span v-if="loading" class="loading-spinner"><SHSpinner size="xs" /></span>
    <span :class="innerClass"><slot /></span>
  </button>
</template>

<style lang="scss" scoped>
@use "../assets/scss/theme" as theme;

:slotted(svg.svg-inline--fa) {
  min-height: 1ch;
  min-width: 1ch;
}

.sh-btn {
  --color: var(--color-surface-900);
  --fg: white;
  --bg: var(--color-surface-200);
  --border-color: var(--color-surface-300);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25em;
  padding: 0.125em 0.5em;
  outline: none;
  appearance: none;
  transition: all 0.2s ease-in-out;

  white-space: nowrap;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  cursor: pointer;

  font-weight: 400;
  color: var(--fg, var(--color));
  background: var(--bg, var(--color));
  border-width: thin;
  border-style: solid;
  border-color: var(--border-color, var(--color));

  &.sm {
    font-size: 0.9em;
  }
  &.md {
    font-size: 1em;
  }
  &.lg {
    font-size: 1.25em;
  }

  &.active,
  &:active {
    border-color: var(--color-secondary);
  }
  &:disabled {
    cursor: not-allowed;
    filter: grayscale(60%) brightness(0.9);
  }
  &:hover:not(:disabled):not(.active) {
    background: var(--up);
    border-color: var(--up);
  }
  &:focus:not(.disabled):not(.active) {
    background: var(--focus);
    border-color: var(--focus);
  }

  &.square {
    aspect-ratio: 1;
  }

  @each $theme, $variants in theme.$themes {
    @each $variantName, $colorList in $variants {
      @if $variantName == "light" {
        @each $name, $color in $colorList {
          .#{$theme} &.#{$name} {
            --fg: var(--color-surface-100);
            --up: var(--color-#{$name}-up-200);
            --focus: var(--color-#{$name}-up-200);
            --color: var(--color-#{$name});
            --bg: var(--color);
          }
        }
      } @else if $variantName == "dark" {
        @each $name, $color in $colorList {
          .#{$theme}.dark &.#{$name} {
            --fg: var(--color-surface-800);
          }
        }
      }
    }
  }

  &.full-width {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
  }

  .loading-spinner {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;
  }
}
</style>
