<script setup lang="ts">
import RouterTabs from "@/components/RouterTabs.vue";
import SHBadge from "@/components/SHBadge.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import { graphql } from "@/generated";
import { useQuery } from "@urql/vue";
import { computed } from "vue";

const { customerId } = defineProps<{
  customerId: string;
}>();

const CustomerDetailQuery = graphql(/* GraphQL */ `
  query CustomerDetail($id: uuid!) {
    customers_by_pk(id: $id) {
      id
      updated_at
      created_at
      title
      abbreviation
      mailing_address
      price_book_id

      author {
        ...UserLink
      }
    }
  }
`);

const { data, fetching } = useQuery({
  query: CustomerDetailQuery,
  variables: computed(() => ({
    id: customerId
  })),
  pause: computed(() => !customerId)
});

const customer = computed(() => data.value?.customers_by_pk);

const tabs = computed(() => [
  { title: "Dashboard", to: { name: "CustomerDashboard" } },
  {
    title: "Contacts",
    to: { name: "CustomerContactsView" },
    allowedPermissions: ["customer_contacts:view"]
  },
  {
    title: "Worksites",
    to: { name: "WorkSitesView" },
    allowedPermissions: ["work_sites:view"]
  },
  {
    title: "Dedicated Agents",
    to: { name: "CustomerAgentsView" },
    allowedPermissions: ["customer_agents:view"]
  },
  { title: "Tickets", to: { name: "CustomerTicketsView" } },
  {
    title: "Work Orders",
    to: {
      name: "CustomerWorkOrders",
      params: { customerId: customerId }
    }
  },
  { title: "Time Sheets", to: { name: "CustomerTimeSheetsView" } },
  {
    title: "Invoices",
    to: { name: "CustomerInvoicesView" },
    allowedPermissions: ["invoices:create"]
  },
  {
    title: "Service Report",
    to: {
      name: "CreateServiceReportFromCustomer",
      params: { customerId: customerId }
    }
  },
  {
    title: "Quotes",
    to: {
      name: "CustomerQuotes",
      params: { customerId: customerId }
    }
  },
  {
    title: "Settings",
    to: { name: "CustomerSettings" },
    allowedPermissions: ["customers:update"]
  }
]);
</script>

<template>
  <article class="vertical xmargin">
    <SHSpinner v-if="fetching" />
    <main v-else-if="customer" class="vertical _padding:1">
      <h1 class="level-spread">
        {{ customer.title }}
        <SHBadge v-if="customer?.abbreviation">
          {{ customer?.abbreviation }}
        </SHBadge>
      </h1>

      <RouterTabs :tabs="tabs" />
    </main>
  </article>
</template>
