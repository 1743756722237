<script setup lang="ts">
import QueryFilter from "@/components/QueryFilter.vue";
import SHBadge from "@/components/SHBadge.vue";
import SHButton from "@/components/SHButton.vue";
import SHDropdown from "@/components/SHDropdown.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHNote from "@/components/SHNote.vue";
import SHRoleBadge from "@/components/SHRoleBadge.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import SHTableRowCount from "@/components/SHTableRowCount.vue";
import UserLink from "@/components/UserLink.vue";
import { useFacets } from "@/composables/useFacets";
import type { Facet, OrderSet } from "@/composables/useQueryStringFilters";
import { useRole } from "@/composables/useRole";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import {
  Order_By,
  type Organization_Agents_Bool_Exp,
  type Organization_Agents_Order_By
} from "@/generated/graphql";
import { CollectionView } from "@/lib/collectionViewTypes";
import { injectStrict } from "@/lib/helpers";
import { BreakPointsKey, OrganizationKey } from "@/providerKeys";
import {
  faEnvelope,
  faPhone,
  faRecycle
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation, useQuery } from "@urql/vue";
import { sub } from "date-fns";
import { computed, ref } from "vue";

const breakpoints = injectStrict(BreakPointsKey);
const { can } = useRole();
const { createToast } = useToaster();
const currentOrg = injectStrict(OrganizationKey);
const filterViewMode = ref<CollectionView>(CollectionView.Table);

const { data: optionsData } = await useQuery({
  query: graphql(/* GraphQL */ `
    query OrganizationAgentsViewOptions($currentOrg: String!) {
      user_roles(
        order_by: { title: asc }
        distinct_on: title
        where: { organization_name: { _eq: $currentOrg } }
      ) {
        title
        short_name
        organization_name
      }
    }
  `),
  variables: { currentOrg: currentOrg.value?.short_name ?? "" }
});

const roles = computed(() => optionsData.value?.user_roles ?? []);

const OrganizationAgentsView = graphql(/* GraphQL */ `
  query OrganizationAgentsView(
    $where: organization_agents_bool_exp
    $order_by: [organization_agents_order_by!]
    $limit: Int
    $offset: Int
  ) {
    organization_agents(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      agent_id
      created_at
      deleted_at
      organization_name
      user_roles {
        role_name
        user_role {
          ...UserRole
          title
          short_name
        }
      }

      agent {
        created_at
        deleted_at
        email
        fname
        id
        lname
        phone_number
        last_seen_at
        first_seen_at
        ...UserLink
      }
    }
    organization_agents_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

const activeStateFilters: Facet<Organization_Agents_Bool_Exp> = {
  defaultValue: "active",
  label: "Active State",
  modelName: "active_state",
  filters: [
    {
      id: "active",
      title: "Active",
      predicate: {
        agent: { deleted_at: { _is_null: true } }
      }
    },
    {
      id: "nologin",
      title: "Never seen",
      predicate: {
        agent: { first_seen_at: { _is_null: true } }
      }
    },
    {
      id: "lapsed",
      title: "Not seen in a week",
      predicate: {
        agent: { last_seen_at: { _lt: sub(new Date(), { days: 7 }) } }
      }
    },
    {
      id: "archived",
      title: "Archived",
      predicate: {
        agent: { deleted_at: { _is_null: false } }
      }
    }
  ]
};

const roleFilters: Facet<Organization_Agents_Bool_Exp> = {
  defaultValue: "all",
  label: "Role",
  modelName: "title",
  filters: [
    {
      id: "all",
      title: "All",
      predicate: {}
    },
    ...roles.value.map(role => ({
      id: role.title,
      title: role.title,
      predicate: {
        user_roles: { user_role: { title: { _eq: role.title } } }
      }
    }))
  ]
};

const orderByFilters: OrderSet<Organization_Agents_Order_By> = {
  defaultValue: "last_seen",
  defaultOrderBy: Order_By.DescNullsLast,
  options: [
    {
      id: "last_seen",
      title: "Last Seen",
      asc: [{ agent: { last_seen_at: Order_By.AscNullsLast } }],
      desc: [{ agent: { last_seen_at: Order_By.DescNullsLast } }]
    },
    {
      id: "fname",
      title: "First Name",
      asc: [{ agent: { fname: Order_By.Asc } }],
      desc: [{ agent: { fname: Order_By.Desc } }]
    },
    {
      id: "lname",
      title: "Last Name",
      asc: [{ agent: { lname: Order_By.Asc } }],
      desc: [{ agent: { lname: Order_By.Desc } }]
    },
    {
      id: "created_at",
      title: "Created Date",
      asc: [{ agent: { created_at: Order_By.Asc } }],
      desc: [{ agent: { created_at: Order_By.Desc } }]
    },
    {
      id: "update_at",
      title: "Last Updated",
      asc: [{ agent: { updated_at: Order_By.Asc } }],
      desc: [{ agent: { updated_at: Order_By.Desc } }]
    },
    {
      id: "city",
      title: "City",
      asc: [{ agent: { city: Order_By.Asc } }],
      desc: [{ agent: { city: Order_By.Desc } }]
    },
    {
      id: "state",
      title: "State",
      asc: [{ agent: { state: Order_By.Asc } }],
      desc: [{ agent: { state: Order_By.Desc } }]
    },
    {
      id: "deleted_at",
      title: "Archived Date",
      asc: [{ agent: { deleted_at: Order_By.Asc } }],
      desc: [{ agent: { deleted_at: Order_By.Desc } }]
    }
  ]
};

const {
  facetModels,
  filterWhereClause,
  resetFacets,
  searchString,
  sortOrder,
  updateFacet,
  updateSearch,
  updateSortDirection,
  updateSortType
} = useFacets([activeStateFilters, roleFilters], orderByFilters);

const { data, error, fetching } = useQuery({
  query: OrganizationAgentsView,
  variables: computed(() => ({
    where: {
      _and: [
        ...(filterWhereClause.value || []),
        {
          _or:
            searchString.value?.length > 2
              ? [
                  { agent: { fname: { _ilike: `%${searchString.value}%` } } },
                  { agent: { lname: { _ilike: `%${searchString.value}%` } } }
                ]
              : [{}]
        }
      ]
    },
    order_by: sortOrder.value,
    limit: 50,
    offset: 0
  })),
  pause: computed(() => !filterWhereClause.value),
  context: { additionalTypenames: ["organization_agents"] }
});

const agents = computed(
  () =>
    data.value?.organization_agents.map(oa => {
      return { ...oa, id: oa.organization_name + oa.agent_id };
    }) ?? []
);

const agentsCount = computed(
  () => data.value?.organization_agents_aggregate.aggregate?.count ?? 0
);

const { executeMutation: resetPassword } = useMutation(
  graphql(/* GraphQL */ `
    mutation requestPasswordReset($email: String!) {
      requestPasswordReset(email: $email) {
        message
        error
      }
    }
  `)
);

const sendResetPasswordEmail = async (email: string) => {
  console.log("will send reset pw");
  const { data, error } = await resetPassword({ email });
  if (error) {
    createToast({
      title: "Unable to send the reset email.",
      message: error.message || "Unknown error.",
      theme: "danger"
    });
    console.log(error);
  } else if (data) {
    createToast({
      message: "Reset email sent.",
      theme: "success"
    });
  }
};
</script>

<template>
  <article class="vertical loose">
    <QueryFilter
      v-model:view-mode="filterViewMode"
      :facets="[activeStateFilters, roleFilters]"
      :facet-models="facetModels"
      :fetching="fetching"
      :order-set="orderByFilters"
      clear-all-filters
      no-calendar-view
      no-card-view
      no-table-view
      placeholder="Search by Name..."
      searchable
      @reset:facets="resetFacets"
      @update:facet="updateFacet($event)"
      @update:search="updateSearch($event)"
      @update:sort-direction="updateSortDirection($event)"
      @update:sort-type="updateSortType($event)"
    >
      <template #mobile-header>Agents Filter</template>
    </QueryFilter>

    <SHSpinner v-if="fetching" />

    <SHNote v-else-if="error" theme="danger">
      {{ error }}
    </SHNote>
    <template
      v-else-if="
        !fetching && optionsData && filterViewMode === CollectionView.Table
      "
    >
      <SHTable :rows="agents" class="agents-table">
        <template #header>
          <!-- Name header is always shown -->
          <th>Name</th>

          <!-- Role header is always shown when larger than mobile -->
          <th v-if="breakpoints.includes('tablet')">Roles</th>

          <th v-if="breakpoints.includes('tablet')">Last Seen</th>
          <th>Contact</th>
        </template>

        <template #row="{ row }">
          <!-- Name is always shown -->
          <td>
            <div class="level tight">
              <UserLink :user="row.agent" />
              <SHBadge
                v-if="row.agent.deleted_at !== null"
                color="var(--color-danger)"
                reverse-color
              >
                BLOCKED
              </SHBadge>
            </div>
          </td>

          <!-- Role is always shown when larger than mobile -->
          <td v-if="breakpoints.includes('tablet')" class="level tight wrap">
            <template v-if="!row.user_roles">No roles assigned!</template>
            <template v-else>
              <template
                v-for="{ user_role } of row.user_roles"
                :key="user_role.agent_id"
              >
                <SHRoleBadge :role="user_role" size="sm" />
              </template>
            </template>
          </td>

          <!-- Date Agent was last seen -->
          <td v-if="breakpoints.includes('tablet')">
            <span v-if="row.agent.last_seen_at || row.agent.first_seen_at">
              <SHInlineDate
                :d="row.agent.last_seen_at || row.agent.first_seen_at"
                format="rel"
              />
            </span>
            <span v-else style="color: var(--color-surface-600)">never</span>
          </td>

          <!-- Rows specific to the Agents table -->

          <!-- Agent Email -->
          <td class="level tight wrap">
            <SHButton
              size="sm"
              color="primary"
              square
              :href="`mailto:${row.agent.email}`"
            >
              <slot name="buttonIcon">
                <FontAwesomeIcon
                  :icon="faEnvelope"
                  fixed-width
                ></FontAwesomeIcon>
              </slot>
            </SHButton>

            <SHButton
              square
              size="sm"
              color="primary"
              :href="`tel:${row.agent.phone_number}`"
            >
              <slot name="buttonIcon">
                <FontAwesomeIcon :icon="faPhone" fixed-width />
              </slot>
            </SHButton>

            <SHDropdown
              v-if="can('users:create')"
              :open-on-hover="true"
              :offset="4"
              flip
              :shift="{ mainAxis: true, crossAxis: true }"
              placement="bottom-start"
              strategy="fixed"
            >
              <SHButton
                square
                size="sm"
                color="secondary"
                @click="sendResetPasswordEmail(row.agent.email)"
              >
                <FontAwesomeIcon :icon="faRecycle" fixed-width />
              </SHButton>

              <template #popup>
                <SHNote theme="info">
                  Sends this user an email to allow resetting their password.
                </SHNote>
              </template>
            </SHDropdown>
          </td>
        </template>

        <template #footer>
          <SHTableRowCount :count="agentsCount" label="agent" />
        </template>
      </SHTable>
    </template>
  </article>
</template>

<style lang="scss" scoped>
.agents-table {
  :deep(tr th) {
    text-align: left;
  }
}
</style>
