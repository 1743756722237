<script setup lang="ts">
const value = defineModel<boolean>("modelValue");
defineProps<{
  disabled?: boolean;
}>();
</script>

<template>
  <div
    class="sh-toggle"
    :class="{ isOn: !!value, disabled }"
    @click.prevent="if (!disabled) value = !value;"
  >
    <div class="thumb" />
  </div>
</template>

<style lang="scss" scoped>
.sh-toggle {
  --transition: all, 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative;
  min-height: 1.25em;
  background: var(--color-secondary);
  min-width: 2.5em;
  max-width: 2.5em;
  border: thin solid var(--color-surface-100);
  border-radius: 0.5em;
  transition: var(--transition);
  box-shadow:
    inset 0 0 6px 2px hsla(0, 0%, 0%, 0.15),
    0px 4px 20px 0px hsla(0, 0%, 0%, 0.08);

  &.disabled {
    &.isOn {
      background: var(--color-surface-300);
    }
    .thumb:hover {
      cursor: not-allowed;
    }
    cursor: not-allowed;
  }

  .thumb {
    top: 0.1em;
    bottom: 0.1em;
    position: absolute;
    left: 0;
    aspect-ratio: 1;
    background: var(--color-surface-600);
    border-radius: 100%;
    border: 2px solid var(--color-surface-300);
    transition: var(--transition);
    box-shadow: 0px 4px 3px 2px hsla(0, 0%, 0%, 0.15);
  }

  .thumb:hover {
    cursor: pointer;
  }
  &.isOn {
    background: var(--color-primary);

    .thumb {
      left: calc(100% - 1em);
      background: var(--color-surface-900);
    }
  }
}
</style>
