import { graphql, useFragment, type FragmentType } from "@/generated";
import {
  Review_Statuses_Enum,
  Ticket_Review_Event_Types_Enum
} from "@/generated/graphql";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";
import type { ButtonTheme } from "@/types";
import { useMutation } from "@urql/vue";
import { computed, toValue, type MaybeRefOrGetter } from "vue";
import { useCustomForms } from "./useCustomForms";
import { useRole } from "./useRole";
import { useTicketSignature } from "./useTicketSignature";

type EventTheme = {
  label: string;
  value: Ticket_Review_Event_Types_Enum;
  color: ButtonTheme;
  show: boolean;
};

const fragment = graphql(/* GraphQL */ `
  fragment TicketReview_Ticket on tickets {
    id
    ref
    created_at
    review_status
    reviewer_id
    author {
      id
      ...UserLink
    }
    work_order {
      customer {
        requires_signature
      }
      author {
        id
        ...UserLink
      }
    }
    ticket_agents {
      agent_id
    }
    ticket_review_events(order_by: [{ created_at: asc }]) {
      id
      created_at
      ticket_review_event_type
      desired_reviewer_id
      ...ReviewEventCard
    }
    latest_request_event: ticket_review_events(
      where: { ticket_review_event_type: { _eq: REQUEST } }
      order_by: { created_at: desc }
      limit: 1
    ) {
      desired_reviewer_id
    }

    ...CustomFormEntries
    ...UseTicketSignature
  }
`);

export function useTicketReview(
  ticket: MaybeRefOrGetter<FragmentType<typeof fragment> | null | undefined>
) {
  const currentUser = injectStrict(CurrentUserKey);
  const { roleName } = useRole();
  const data = computed(() => useFragment(fragment, toValue(ticket)));
  const { formBindings } = useCustomForms(data);

  const bindingsWithCacheValue = computed(() => {
    return formBindings.value.filter(o => !!o.cache.value);
  });

  const { isMissingSignature } = useTicketSignature(data);

  const reviewState = computed<Review_Statuses_Enum>(() => {
    return data.value?.review_status ?? Review_Statuses_Enum.Inapplicable;
  });

  const isRequested = computed(() => {
    return reviewState.value == Review_Statuses_Enum.Requested;
  });

  const latestRequestEvent = computed(() => {
    return data.value?.latest_request_event.at(0);
  });

  const isReviewer = computed(() => {
    return data.value?.reviewer_id
      ? data.value?.reviewer_id === currentUser.value.id
      : roleName?.value === "back_office" || roleName?.value === "supervisor";
  });

  const isTicketAgent = computed(
    () =>
      data.value?.ticket_agents.some(
        o => o.agent_id === currentUser.value.id
      ) ?? false
  );

  const allRequiredFormsFilledOut = computed(() => {
    return formBindings.value
      .filter(o => o.isRequired)
      .every(o => o.serverValue);
  });

  const canRequest = computed(
    () =>
      (reviewState.value === Review_Statuses_Enum.Pending ||
        reviewState.value === Review_Statuses_Enum.Rejected) &&
      isTicketAgent.value &&
      allRequiredFormsFilledOut.value
  );

  const canComment = computed(() => true);

  const canAccept = computed(
    () =>
      reviewState.value == Review_Statuses_Enum.Requested && isReviewer.value
  );

  const canReject = computed(
    () =>
      reviewState.value == Review_Statuses_Enum.Requested && isReviewer.value
  );

  const canCancel = computed<boolean>(
    () => isRequested.value && isTicketAgent.value
  );

  const eventOptions = computed<EventTheme[]>(() => [
    {
      label: "Request",
      value: Ticket_Review_Event_Types_Enum.Request,
      color: "info",
      show: canRequest.value
    },
    {
      label: "Cancel",
      value: Ticket_Review_Event_Types_Enum.Cancel,
      color: "warning",
      show: canCancel.value
    },
    {
      label: "Accept",
      value: Ticket_Review_Event_Types_Enum.Accept,
      color: "success",
      show: canAccept.value
    },
    {
      label: "Reject",
      value: Ticket_Review_Event_Types_Enum.Reject,
      color: "danger",
      show: canReject.value
    },
    {
      label: "Comment",
      value: Ticket_Review_Event_Types_Enum.Comment,
      color: "primary",
      show: canComment.value
    }
  ]);

  const reviewEvents = computed(() => data.value?.ticket_review_events ?? []);

  const currentReviewerId = computed(() =>
    isRequested.value
      ? (latestRequestEvent.value?.desired_reviewer_id ?? null)
      : null
  );

  const { executeMutation: updateReview } = useMutation(
    graphql(/* GraphQL */ `
      mutation AddTicketReviewEvent(
        $reviewEvent: ticket_review_events_insert_input!
        $eventNotification: event_notifications_insert_input!
      ) {
        insert_ticket_review_events_one(object: $reviewEvent) {
          id
          ticket {
            id
          }
        }
        insert_event_notifications_one(object: $eventNotification) {
          event_id
        }
      }
    `)
  );

  return {
    // status,
    // reviewEvents,
    // updateReview,
    // canRequest,
    // canComment,
    // canAccept,
    // canReject,
    // canCancel,
    // isReviewer,
    // isTicketAgent,
    // currentReviewerId,
    // formBindings,
    // bindingsWithCacheValue,
    // isSignatureRequired,
    // eventOptions
    reviewEvents,
    eventOptions,
    updateReview,
    currentReviewerId,
    bindingsWithCacheValue,
    allRequiredFormsFilledOut,
    isMissingSignature,
    canRequest
  };
}
