<script setup lang="ts">
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHNote from "@/components/SHNote.vue";
import SHTextEditor from "@/components/TextEditor/SHTextEditor.vue";
import { graphql } from "@/generated";
import { useQuery } from "@urql/vue";
import { computed } from "vue";
import MediaUploadsView from "./MediaUploadsView.vue";

const props = defineProps<{
  quoteId: string;
}>();

const { data, error } = await useQuery({
  query: graphql(/* GraphQL */ `
    query QuoteNotes($id: uuid!) {
      quote_quotes_by_pk(id: $id) {
        notesj
        updated_at
      }
    }
  `),
  variables: computed(() => ({ id: props.quoteId })),
  pause: computed(() => !props.quoteId)
});
const quote = computed(() => data.value?.quote_quotes_by_pk);
</script>

<template>
  <SHNote v-if="error" theme="danger">{{ error.message }}</SHNote>
  <article v-else class="quote-notes vertical">
    <h2>Notes</h2>
    <SHTextEditor v-if="quote?.notesj" :model-value="quote.notesj" />
    <MediaUploadsView hide-empty-message :quote-id="quoteId" />
    <span>
      Last Updated
      <SHInlineDate :d="quote?.updated_at" format="long_time" />
    </span>
  </article>
</template>

<style lang="scss" scoped></style>
