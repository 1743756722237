<script setup lang="ts">
import SHButton from "@/components/SHButton.vue";
import SHSignaturePad from "@/components/SHSignaturePad.vue";
import { useTicketSignature } from "@/composables/useTicketSignature";
import { graphql } from "@/generated";
import { useQuery } from "@urql/vue";
import { format, parseISO } from "date-fns";
import { computed } from "vue";

const props = defineProps<{
  ticketId: string;
}>();

const { data } = await useQuery({
  query: graphql(/* GraphQL */ `
    query TicketSignatureDetail($ticketId: uuid!) {
      tickets_by_pk(id: $ticketId) {
        ...UseTicketSignature
      }
    }
  `),
  variables: {
    ticketId: props.ticketId
  }
});

const ticket = computed(() => data.value?.tickets_by_pk);

const { signature } = useTicketSignature(ticket);
</script>

<template>
  <article class="ticket-signature-detail">
    <section v-if="signature" class="vertical">
      <SHSignaturePad disabled :model-value="signature?.url" />
      <p>
        Signed by
        <span>
          {{ signature.author_name }}
        </span>
        <em>({{ signature.author_email }})</em>
        on
        <span class="signature-date">
          {{ format(parseISO(signature.created_at), "MMMM d, yyyy") }}
        </span>
        at
        <span class="signature-time">
          {{ format(parseISO(signature.created_at), "h:mm a") }}
        </span>
      </p>
      <div class="level-end">
        <SHButton
          :to="{
            name: 'TicketSignatureRequest',
            params: { ticketId: ticketId }
          }"
        >
          Update Signature
        </SHButton>
      </div>
    </section>
    <section v-else class="pending-signature">
      <SHButton
        color="primary"
        :to="{ name: 'TicketSignatureRequest', params: { ticketId: ticketId } }"
      >
        Start Signature Request
      </SHButton>
    </section>
  </article>
</template>

<style lang="scss" scoped>
.ticket-signature-detail {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .pending-signature {
    display: flex;
    justify-content: center;

    > button {
      width: 100%;
      height: 3em;
    }
  }
}
</style>
