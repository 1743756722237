<script setup lang="ts">
import ComboBox from "@/components/ComboBox/ComboBox.vue";
import { useAgentChoice, type AgentChoice } from "@/composables/useAgentChoice";
import { graphql } from "@/generated";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";
import { useQuery } from "@urql/vue";
import { computed } from "vue";
import SHRoleBadge from "./SHRoleBadge.vue";

const currentUser = injectStrict(CurrentUserKey);

const {
  contextCustomerId = "",
  contextTicketId = "",
  disabled = false,
  placeholder = "Choose Agent...",
  selfAtTop = false,
  ...props
} = defineProps<{
  multiple?: AgentChoice[];
  placeholder?: string;
  modelValue?: AgentChoice | null | undefined;
  selfAtTop?: boolean;
  contextCustomerId?: string;
  contextTicketId?: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:model-value", value: AgentChoice | null): void;
  (e: "update:multiple", value: AgentChoice[]): void;
}>();

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:model-value", val ?? null);
  }
});

const multiple = computed({
  get() {
    return props.multiple;
  },
  set(val) {
    if (val) {
      emit("update:multiple", val);
    }
  }
});

const query = graphql(/* GraphQL */ `
  query SHAgentChooser(
    $where: organization_agents_bool_exp
    $ticketId: uuid!
    $hasTicketContext: Boolean!
    $customerId: uuid!
    $hasCustomerContext: Boolean!
  ) {
    organization_agents(
      where: $where
      order_by: [{ agent: { lname: asc } }, { agent: { fname: asc } }]
    ) {
      agent {
        ...AgentChoice

        id
        customer_agents_aggregate(where: { customer_id: { _eq: $customerId } })
          @include(if: $hasCustomerContext) {
          aggregate {
            count
          }
        }
        ticket_agents_aggregate(where: { ticket_id: { _eq: $ticketId } })
          @include(if: $hasTicketContext) {
          aggregate {
            count
          }
        }
      }
    }
  }
`);

const { data, fetching, error } = useQuery({
  query: query,
  variables: computed(() => ({
    ticketId: contextTicketId,
    customerId: contextCustomerId,
    hasTicketContext: contextTicketId !== "",
    hasCustomerContext: contextCustomerId !== "",
    where: {
      deleted_at: { _is_null: true }
    }
  }))
});

const agents = computed(() => {
  const out = data.value?.organization_agents
    ? [...data.value?.organization_agents]
    : [];
  if (contextCustomerId) {
    // rank these dudes higher
    out.sort(
      (x, y) =>
        (y.agent.customer_agents_aggregate?.aggregate?.count || 0) -
        (x.agent.customer_agents_aggregate?.aggregate?.count || 0)
    );
  }
  if (selfAtTop) {
    // if it's us, move it left, otherwise leave it alone
    out.sort(x => (x.agent.id === currentUser.value.id ? -1 : 1));
  }
  return out.map(x => useAgentChoice(x.agent));
});
</script>
<template>
  <div v-if="error">{{ error }}</div>
  <main v-else>
    <ComboBox
      v-bind="$attrs"
      v-model:model-value="modelValue"
      v-model:multiple="multiple"
      :disabled="disabled"
      :fuse-options="{
        keys: ['full_name'],
        threshold: 0.2
      }"
      :loading="fetching"
      :options="agents"
      :placeholder="placeholder"
      mobile-header="Choose Agent"
      enable-fuse
    >
      <template #option="{ option }">
        <div class="level tight">
          <SHRoleBadge
            :role="
              option.organization_agents.at(0)?.user_roles.at(0)?.user_role
            "
            :show-label="false"
          />
          {{ option?.full_name }}
        </div>
      </template>

      <template #selected-option="{ option }">
        <div v-if="option?.full_name" class="level tight">
          <SHRoleBadge
            :role="
              option?.organization_agents.at(0)?.user_roles.at(0)?.user_role
            "
            :show-label="false"
          />
          {{ option?.full_name }}
        </div>
        <div v-else>
          {{ placeholder }}
        </div>
      </template>
    </ComboBox>
  </main>
</template>
