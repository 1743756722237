<script async setup lang="ts">
import SHButton from "@/components/SHButton.vue";
import SHTable from "@/components/SHTable.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useToaster } from "@/composables/useToaster";
import useWebpush from "@/composables/useWebpush";
import { graphql } from "@/generated";
import { humanizeEnum, injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";
import { useMutation, useQuery } from "@urql/vue";
import { computed, ref } from "vue";
import type { Event_Types_Enum } from "../../types";

const user = injectStrict(CurrentUserKey);
const toaster = useToaster();
const webPush = useWebpush();

const { executeMutation: saveNotifPrefs } = useMutation(
  graphql(/* GraphQL */ `
    mutation UserNotifPreferencesInsert(
      $objects: [notification_prefs_insert_input!]!
    ) {
      insert_notification_prefs(
        objects: $objects
        on_conflict: {
          constraint: notification_prefs_user_id_notif_type_key
          update_columns: [email_enabled, push_enabled]
        }
      ) {
        returning {
          id
          type
          email_enabled
          push_enabled
        }
      }
    }
  `)
);

const { data } = await useQuery({
  query: graphql(/* GraphQL */ `
    query GetEventTypesAndUserPrefs($userId: String!) {
      event_types {
        value
        notification_prefs(where: { user_id: { _eq: $userId } }) {
          email_enabled
          push_enabled
        }
      }
    }
  `),
  variables: {
    userId: user.value.id
  },
  context: {
    additionalTypenames: ["notification_prefs"]
  }
});

const eventTypes = ref(
  (data.value?.event_types || []).map((t, id) => ({
    id,
    type: t.value as Event_Types_Enum,
    email: t.notification_prefs[0]?.email_enabled || false,
    push: t.notification_prefs[0]?.push_enabled || false
  }))
);

const originalPrefs = computed(() =>
  (data.value?.event_types || []).map((t, id) => ({
    id,
    type: t.value as Event_Types_Enum,
    email: t.notification_prefs[0]?.email_enabled || false,
    push: t.notification_prefs[0]?.push_enabled || false
  }))
);

const allEmail = computed(() => eventTypes.value.every(t => t.email));
const setAllEmail = () => {
  const newVal = !eventTypes.value.every(t => t.email);
  eventTypes.value.forEach(t => (t.email = newVal));
};

const allPush = computed(() => eventTypes.value.every(t => t.push));
const setAllPush = () => {
  const newVal = !eventTypes.value.every(t => t.push);
  eventTypes.value.forEach(t => (t.push = newVal));
};

const isDirty = computed(
  () => JSON.stringify(originalPrefs.value) !== JSON.stringify(eventTypes.value)
);

const isPushEnabled = computed(() => !!webPush.subscription.value);

const enablePush = async () => await webPush.register(user.value.id);

const reset = () =>
  (eventTypes.value = JSON.parse(JSON.stringify(originalPrefs.value)));

const save = async () => {
  const objects = eventTypes.value.map(t => ({
    type: t.type,
    email_enabled: t.email,
    push_enabled: t.push
  }));

  const { error } = await saveNotifPrefs({ objects });

  if (error) {
    console.error("Error while saving prefs: ", error);
    return toaster.createToast({
      theme: "warning",
      title: "Failed to save preferences",
      message: "An error occurred while saving. Please try again.",
      requiresInteraction: true
    });
  }

  toaster.createToast({
    theme: "success",
    title: "Preferences Saved",
    message: "Your notification preferences have been updated"
  });
};
</script>

<template>
  <article>
    <SHTable :columns="['Type', 'Email', 'Push']" :rows="eventTypes">
      <template #header>
        <th>Type</th>
        <th style="text-align: center">
          Email
          <input :checked="allEmail" type="checkbox" @click="setAllEmail" />
        </th>
        <th style="text-align: center">
          Push
          <input
            :checked="allPush"
            type="checkbox"
            :disabled="!isPushEnabled"
            @click="setAllPush"
          />
        </th>
      </template>
      <template #row="{ row }">
        <td>{{ humanizeEnum(row.type) }}</td>
        <td style="text-align: center">
          <input v-model="row.email" type="checkbox" />
        </td>
        <td style="text-align: center">
          <input
            v-model="row.push"
            type="checkbox"
            :disabled="!isPushEnabled"
          />
        </td>
      </template>
      <template v-if="!isPushEnabled" #footer>
        <td></td>
        <td></td>
        <td style="text-align: center">
          <SHButton @click="enablePush">Enable push</SHButton>
        </td>
      </template>
    </SHTable>
    <div class="notif-actions">
      <UndoConfirm
        :confirm-enabled="isDirty"
        confirm-text="Save"
        undo-text="Reset"
        @undo="reset"
        @confirm="save"
      />
    </div>
  </article>
</template>

<style lang="scss" scoped>
.notif-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;

  :not(:last-child) {
    margin-right: 5px;
  }
}
</style>
