<script async setup lang="ts">
import QuoteCard from "@/components/QuoteCard.vue";
import RouterTabs from "@/components/RouterTabs.vue";
import SHButton from "@/components/SHButton.vue";
import SHDropdown from "@/components/SHDropdown.vue";
import type { MenuEntry } from "@/components/SHMenu.vue";
import SHMenu from "@/components/SHMenu.vue";
import SHNote from "@/components/SHNote.vue";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import {
  faBan,
  faCopy,
  faEllipsisVertical,
  faPen,
  faTrashCan,
  faTrashCanArrowUp,
  faTrashCanSlash
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation, useQuery } from "@urql/vue";
import { useClipboard } from "@vueuse/core";
import { computed } from "vue";
import { useRoute } from "vue-router";

const props = defineProps<{
  quoteId: string;
}>();

const route = useRoute();
const { createToast } = useToaster();
const { copy: copyToClipboard } = useClipboard();

const { data, error } = await useQuery({
  query: graphql(/* GraphQL */ `
    query QuoteDetail($id: uuid!) {
      quote_quotes_by_pk(id: $id) {
        ...QuoteCard
        id
        ref
        created_at
        updated_at
        deleted_at
        author {
          ...UserLink
        }
        notesj
        review_status
        lifecycle_status
        delivery_status
        customer {
          ...CustomerLink
          id
          title
          abbreviation
          mailing_address
          price_book {
            id
            title
          }
        }
      }
    }
  `),
  variables: computed(() => ({ id: props.quoteId })),
  pause: computed(() => !props.quoteId)
});
const quote = computed(() => data.value?.quote_quotes_by_pk);
const canEditQuote = computed(() => true); // TODO
const canArchiveQuote = computed(() => true); // TODO

const { executeMutation: archive } = useMutation(
  graphql(/* GraphQL */ `
    mutation QuoteArchive($args: quote_archive_args!) {
      quote_archive(args: $args) {
        id
      }
    }
  `)
);
const { executeMutation: unarchive } = useMutation(
  graphql(/* GraphQL */ `
    mutation QuoteUnarchive($args: quote_unarchive_args!) {
      quote_unarchive(args: $args) {
        id
      }
    }
  `)
);

const onArchive = async () => {
  console.log("on archive");
  const { data, error } = await archive({
    args: { _quote_id: props.quoteId }
  });
  console.log("archive done", { data, error });
};
const onUnarchive = async () => {
  console.log("on unarchive");
  const { data, error } = await unarchive({
    args: { _quote_id: props.quoteId }
  });
  console.log("unarchive done", { data, error });
};

const onCopyToClipboard = async () => {
  copyToClipboard(new URL(route.fullPath, window.location.origin).href);
  createToast({
    message: "Copied to clipboard.",
    theme: "success"
  });
};

const menuItems = computed<MenuEntry[]>(() => [
  {
    id: "copy",
    icon: faCopy,
    label: "Copy Link",
    onClick: onCopyToClipboard
  },
  {
    id: "edit",
    icon: faPen,
    label: "Edit",
    disabled: !canEditQuote.value,
    disabledReason: "not implemented",
    to: { name: "QuoteEdit" }
  },
  quote.value?.deleted_at === null
    ? {
        id: "archive",
        icon: canArchiveQuote.value ? faTrashCan : faTrashCanSlash,
        label: "Archive",
        isDanger: true,
        disabled: !canArchiveQuote.value,
        disabledReason: "Tickets with logged work cannot be archived.",
        onClick: onArchive
      }
    : {
        id: "unarchive",
        icon: canArchiveQuote.value ? faTrashCanArrowUp : faBan,
        label: "Reinstate",
        isDanger: false,
        onClick: onUnarchive
      }
]);
</script>

<template>
  <SHNote v-if="error" theme="danger">{{ error?.message }}</SHNote>
  <article v-else-if="quote" class="quote-detail vertical">
    <header>
      <QuoteCard :quote="quote" no-hover />

      <RouterTabs
        query-string-match="filter"
        :exact-match="false"
        :tabs="[
          {
            title: 'Activity',
            to: { name: 'QuoteActivity' }
          },
          {
            title: 'Summary',
            to: { name: 'QuoteSummary' }
          },
          {
            title: 'Notes',
            to: { name: 'QuoteNotes' }
          },
          {
            title: 'Preview',
            to: { name: 'QuotePreview' }
          }
        ]"
      >
        <template #endOfRow>
          <SHDropdown placement="bottom-end" strategy="fixed">
            <template #default="{ toggle }">
              <SHButton color="surface-300" square size="sm" @click="toggle">
                <FontAwesomeIcon :icon="faEllipsisVertical" fixed-width />
              </SHButton>
            </template>

            <template #popup="{ close }">
              <div class="popup">
                <SHMenu :items="menuItems" @click="() => close()" />
              </div>
            </template>
          </SHDropdown>
        </template>
      </RouterTabs>
    </header>
  </article>
</template>

<style lang="scss" scoped>
.quote-detail {
  header {
    display: grid;
    gap: 1.5em;
    grid-template-columns: 1fr;
  }
}
</style>
