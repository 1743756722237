<script setup lang="ts">
import ComboBox from "@/components/ComboBox/ComboBox.vue";
import {
  useCustomerContactChoice,
  type CustomerContactChoice
} from "@/composables/useCustomerContactChoice";
import { graphql } from "@/generated";
import { useQuery } from "@urql/vue";
import { computed } from "vue";

const {
  contextCustomerId = "",
  disabled = false,
  placeholder = "Choose Contact...",
  ...props
} = defineProps<{
  multiple?: CustomerContactChoice[];
  placeholder?: string;
  modelValue?: CustomerContactChoice | null | undefined;
  contextCustomerId?: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:model-value", value: CustomerContactChoice | null): void;
  (e: "update:multiple", value: CustomerContactChoice[]): void;
}>();

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:model-value", val ?? null);
  }
});

const multiple = computed({
  get() {
    return props.multiple;
  },
  set(val) {
    if (val) {
      emit("update:multiple", val);
    }
  }
});

const query = graphql(/* GraphQL */ `
  query SHCustomerContactChooser($where: customer_contacts_bool_exp) {
    customer_contacts(where: $where, order_by: [{ full_name: asc }]) {
      ...CustomerContactChoice
    }
  }
`);

const { data, fetching, error } = useQuery({
  query: query,
  variables: computed(() => ({
    where: {
      customer_id: { _eq: contextCustomerId },
      deleted_at: { _is_null: true }
    }
  }))
});

const contacts = computed<CustomerContactChoice[]>(() =>
  (data.value?.customer_contacts || []).map(cc => useCustomerContactChoice(cc))
);
</script>
<template>
  <div v-if="error">{{ error }}</div>
  <main v-else>
    <ComboBox
      v-bind="$attrs"
      v-model:model-value="modelValue"
      v-model:multiple="multiple"
      :disabled="disabled"
      :fuse-options="{
        keys: ['full_name'],
        threshold: 0.2
      }"
      :loading="fetching"
      :options="contacts"
      :placeholder="placeholder"
      mobile-header="Choose Contact..."
      enable-fuse
      :get-option-label="(opt): string => opt?.id ?? 'unknown contact'"
    >
      <template #option="{ option }">
        <span class="agent-option">
          {{ option?.full_name }}
        </span>
      </template>
      <template #mobile-option="{ option }">
        <div class="level tight" style="flex-grow: 1">
          {{ option.full_name }}
          <span
            v-if="option.job_title"
            :style="{ color: 'var(--color-secondary)' }"
          >
            {{ option.job_title }}
          </span>
        </div>
      </template>

      <template #selected-option="{ option }">
        <span v-if="option?.full_name" class="selected-option">
          {{ option?.full_name }}
        </span>
        <div v-else>
          {{ placeholder }}
        </div>
      </template>
    </ComboBox>
  </main>
</template>
<style lang="scss" scoped>
.agent-option,
.selected-option {
  display: flex;
  justify-content: space-between;
}

.agent-option {
  gap: 1.25em;
}

.selected-option {
  gap: 0.5em;
}

:deep(.sh-menu .sh-list-item) {
  padding: 0.25em;
}
</style>
