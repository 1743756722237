<script async setup lang="ts">
import SHButton from "@/components/SHButton.vue";
import SHImage from "@/components/SHImage.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import { useImage } from "@/composables/useImage";
import { injectStrict } from "@/lib/helpers";
import { useLogger } from "@/logger";
import { DarkModeKey } from "@/providerKeys";
import type { SignaturePayload } from "@/types";
import { faCheckCircle } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SignaturePad from "signature_pad";
import { computed, onMounted, ref } from "vue";
const isDark = injectStrict(DarkModeKey);
const { log } = useLogger("SHSignaturePad");
const { trim } = useImage();
const props = defineProps<{
  modelValue: SignaturePayload | string | null | undefined;
  width?: number;
  height?: number;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: SignaturePayload): void;
}>();

const canvasEl = ref<HTMLCanvasElement | null>(null);
const signaturePad = ref<SignaturePad | null>(null);

const modelValue = computed({
  get: () => props.modelValue,
  set: (value: SignaturePayload | string | null | undefined) => {
    emit("update:modelValue", value as SignaturePayload);
  }
});

const isImage = computed(() => typeof modelValue.value === "string");
const isUpdating = ref(false);
const isLoading = ref(false);

onMounted(async () => {
  if (!canvasEl.value) {
    log("Canvas element not found");
    return;
  }

  const penColor = getComputedStyle(document.body).getPropertyValue(
    "--color-surface-900"
  );

  signaturePad.value = new SignaturePad(canvasEl.value, {
    penColor
  });

  if (modelValue.value && typeof modelValue.value === "object") {
    signaturePad.value.fromData(modelValue.value.data);
  }

  signaturePad.value.addEventListener("beginStroke", handleBeginStroke);
  signaturePad.value.addEventListener("endStroke", handleEndStroke);

  log("Signature pad initialized");

  return () => {
    signaturePad.value?.removeEventListener("beginStroke", handleBeginStroke);
    signaturePad.value?.removeEventListener("endStroke", handleEndStroke);
  };
});

function clear() {
  if (props.disabled) {
    log("Signature pad is disabled");
    return;
  }

  signaturePad.value?.clear();
  modelValue.value = null;
}

function handleBeginStroke(e: Event) {
  if (!signaturePad.value) {
    log("Signature pad not found");
    return;
  }

  if (props.disabled) {
    log("Signature pad is disabled");
    e.preventDefault();
    return false;
  }

  isUpdating.value = true;
}

function handleEndStroke() {
  if (!signaturePad.value || !canvasEl.value) {
    log("Signature pad or canvas not found");
    return;
  }

  modelValue.value = {
    version: "1",
    data: signaturePad.value.toData(),
    width: canvasEl.value.width,
    height: canvasEl.value.height
  };

  log("Signature updated");
  isUpdating.value = false;
}

// Two pixels are removed for the border
const computedWidth = computed(() => (props.width ? props.width - 2 : 600));
const computedHeight = computed(() => (props.height ? props.height - 2 : 200));
defineExpose({
  clear
});
</script>

<template>
  <div class="sh-signature-pad" :class="{ disabled }">
    <SHImage
      v-if="isImage"
      class="signature-image"
      :class="{ isDark }"
      :src="trim(modelValue as string)"
      alt="Signature"
    />
    <canvas
      v-else
      id="signature-pad"
      ref="canvasEl"
      :height="computedHeight"
      :width="computedWidth"
    />
    <nav class="controls">
      <SHButton v-if="!disabled" @click="clear">Clear</SHButton>
    </nav>
    <hr class="dotted-line" />

    <section class="status level-end">
      <Transition name="flip-in">
        <SHSpinner v-if="isUpdating || isLoading" fixed-width size="sm" />
        <span v-else>
          <FontAwesomeIcon
            fixed-width
            size="sm"
            style="color: var(--color-success-up-200)"
            :icon="faCheckCircle"
          />
        </span>
      </Transition>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.signature-image {
  &.isDark {
    filter: invert(1);
  }
  > :deep(img) {
    aspect-ratio: 3 / 1;
  }
}

.sh-signature-pad {
  position: relative;
  width: fit-content;
  border: 1px solid var(--color-surface-300);
  border-radius: 5px;

  &.disabled {
    opacity: 0.5;
  }

  #signature-pad {
    background-color: var(--color-primary-opacity-15);
    z-index: 2;
  }

  .controls {
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--padding);
  }

  .dotted-line {
    position: absolute;
    z-index: -1;
    width: 100%;
    bottom: 2em;
  }

  .status {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: var(--padding);
  }
}

.flip-in-enter-active {
  animation: flip-in 0.5s;
}

@keyframes flip-in {
  from {
    transform: rotateY(50deg);
  }
  to {
    transform: rotateY(0deg);
  }
}
</style>
