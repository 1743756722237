import { useRole } from "@/composables/useRole";
import { agentRoutes } from "@/router/customers/agentRoutes";
import { contactRoutes } from "@/router/customers/contactRoutes";
import { invoiceRoutes } from "@/router/customers/invoiceRoutes";
import { ticketRoutes } from "@/router/customers/ticketRoutes";
import { worksiteRoutes } from "@/router/customers/worksiteRoutes";
import { h } from "vue";
import { RouterView, type RouteRecordRaw } from "vue-router";

import ModuleHeader from "@/components/ModuleHeader.vue";
import SHButton from "@/components/SHButton.vue";
import CustomerCreate from "@/views/CustomerCreate.vue";
import CustomerDashboard from "@/views/CustomerDashboard.vue";
import CustomerDetail from "@/views/CustomerDetail.vue";
import CustomerEdit from "@/views/CustomerEdit.vue";
import CustomerSettings from "@/views/CustomerSettings.vue";
import CustomersView from "@/views/CustomersView.vue";
import QuotesView from "@/views/QuotesView.vue";
import ServiceReportForm from "@/views/ServiceReportForm.vue";
import TimeSheetsView from "@/views/TimeSheetsView.vue";
import WorkOrdersView from "@/views/WorkOrdersView.vue";
import WorkSitesView from "@/views/WorkSitesView.vue";

import {
  faChartUser,
  faDollarSquare,
  faPen,
  faPlus,
  faTrafficCone,
  faUser,
  faUsers
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export const customerRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "CustomersView",
    components: {
      default: CustomersView,
      header: h(
        ModuleHeader,
        { icon: faUsers },
        {
          default: () => "Customers",
          nav: () =>
            useRole().can("customers:create")
              ? h(
                  SHButton,
                  {
                    to: { name: "CustomerCreate" },
                    color: "primary",
                    size: "sm"
                  },
                  () =>
                    h("div", [
                      h(FontAwesomeIcon, { icon: faPlus, fixedWidth: true }),
                      h(FontAwesomeIcon, { icon: faUsers, fixedWidth: true })
                    ])
                )
              : ""
        }
      )
    }
  },
  {
    path: "new",
    name: "CustomerCreate",
    components: {
      default: CustomerCreate,
      header: h(ModuleHeader, { icon: faUsers }, () => "Create New Customer")
    }
  },
  {
    path: ":customerId/edit",
    name: "CustomerEdit",
    components: {
      default: CustomerEdit,
      header: h(ModuleHeader, { icon: faUsers }, () => "Edit Customer")
    },
    props: true
  },
  {
    path: ":customerId",
    components: {
      default: CustomerDetail,
      header: h(RouterView, { name: "header" })
    },
    name: "CustomerDetail",
    props: true,
    redirect: {
      name: "CustomerDashboard"
    },
    children: [
      {
        path: "dashboard",
        name: "CustomerDashboard",
        props: true,
        components: {
          default: CustomerDashboard,
          header: h(
            ModuleHeader,
            { icon: faUsers },
            {
              default: () => "Customer Dashboard",
              nav: () =>
                useRole().can("customers:update")
                  ? h(
                      SHButton,
                      {
                        to: { name: "CustomerEdit" },
                        color: "primary",
                        size: "sm"
                      },
                      [
                        h(FontAwesomeIcon, { icon: faPen, fixedWidth: true }),
                        h(FontAwesomeIcon, { icon: faUser, fixedWidth: true })
                      ]
                    )
                  : ""
            }
          )
        }
      },
      {
        path: "contacts",
        children: contactRoutes
      },
      {
        path: "worksites",
        name: "WorkSitesView",
        components: {
          default: WorkSitesView,
          header: h(
            ModuleHeader,
            { icon: faUsers },
            {
              default: () => "Customer Worksites",
              nav: () =>
                h(
                  SHButton,
                  {
                    to: { name: "WorkSiteCreate" },
                    color: "primary",
                    size: "sm"
                  },
                  () => [
                    h(FontAwesomeIcon, { icon: faPlus, fixedWidth: true }),
                    h(FontAwesomeIcon, {
                      icon: faTrafficCone,
                      fixedWidth: true
                    })
                  ]
                )
            }
          )
        },
        props: true
      },
      {
        path: "agents",
        children: agentRoutes
      },
      {
        path: "tickets",
        children: ticketRoutes
      },
      {
        path: "workorders",
        name: "CustomerWorkOrders",
        components: {
          default: WorkOrdersView,
          header: h(
            ModuleHeader,
            { icon: faChartUser },
            () => "Customer Work Orders"
          )
        },
        props: true
      },
      {
        path: "time-sheets",
        name: "CustomerTimeSheetsView",
        components: {
          default: TimeSheetsView,
          header: h(
            ModuleHeader,
            { icon: faUsers },
            {
              default: () => "Customer Time Sheet"
            }
          )
        },
        props: true
      },
      {
        path: "invoices",
        children: invoiceRoutes
      },
      {
        path: "service-report",
        name: "CreateServiceReportFromCustomer",
        components: {
          default: ServiceReportForm,
          header: h(
            ModuleHeader,
            { icon: faChartUser },
            () => "Create Service Report"
          )
        },
        props: true
      },
      {
        path: "settings",
        name: "CustomerSettings",
        components: {
          default: CustomerSettings,
          header: h(
            ModuleHeader,
            { icon: faUsers },
            {
              default: () => "Customer Settings",
              nav: () =>
                h(
                  SHButton,
                  {
                    to: { name: "CustomerEdit" },
                    color: "primary",
                    size: "sm"
                  },
                  () =>
                    h("div", [
                      h(FontAwesomeIcon, { icon: faPen, fixedWidth: true }),
                      h(FontAwesomeIcon, { icon: faUser, fixedWidth: true })
                    ])
                )
            }
          )
        },
        props: true
      },
      {
        path: "worksites",
        children: worksiteRoutes
      },
      {
        path: "quotes",
        name: "CustomerQuotes",
        props: true,
        components: {
          default: QuotesView,
          header: h(
            ModuleHeader,
            { icon: faDollarSquare },
            () => "Customer Quotes"
          )
        }
      }
    ]
  }
];
