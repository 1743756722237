<script async setup lang="ts">
import RouterTabs from "@/components/RouterTabs.vue";
import SHButton from "@/components/SHButton.vue";
import SHDropdown from "@/components/SHDropdown.vue";
import type { MenuEntry } from "@/components/SHMenu.vue";
import SHMenu from "@/components/SHMenu.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import TicketCard from "@/components/TicketCard.vue";
import { useLogPermissions } from "@/composables/useLogPermissions";
import { useRole } from "@/composables/useRole";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { Ticket_Statuses_Enum } from "@/generated/graphql";
import { injectStrict } from "@/lib/helpers";
import { useLogger } from "@/logger";
import { CurrentTicketKey, CurrentUserKey } from "@/providerKeys";
import {
  faBan,
  faCopy,
  faEllipsisVertical,
  faFilePdf,
  faPen,
  faTrashCan,
  faTrashCanArrowUp,
  faTrashCanSlash
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation, useQuery } from "@urql/vue";
import { useClipboard } from "@vueuse/core";
import { computed, provide } from "vue";
import { useRoute, useRouter } from "vue-router";

const currentUser = injectStrict(CurrentUserKey);
const { can } = useRole();

const { ticketId } = defineProps<{
  ticketId: string;
  ticketItemId?: string;
}>();

const router = useRouter();
const { createToast } = useToaster();
const { log } = useLogger("TicketDetail"); // eslint-disable-line @typescript-eslint/no-unused-vars
const route = useRoute();
const { copy: copyToClipboard } = useClipboard();

const { data, fetching } = await useQuery({
  query: graphql(/* GraphQL */ `
    query TicketDetail($currentUserId: String!, $ticketId: uuid!) {
      tickets_by_pk(id: $ticketId) {
        id
        deleted_at
        ...TicketCard
        ...TicketCanAddLogs

        author {
          id
          ...UserLink
        }

        ticket_agents {
          agent {
            id
            ...UserName
          }
        }

        ref
        ticket_status
        notesj
        created_at
        work_order {
          id
          ...WorkOrderCard
          customer {
            requires_signature
            id
          }
        }

        product {
          id
          title
        }

        date_range {
          first_work
        }
      }
    }
  `),
  variables: computed(() => ({
    currentUserId: currentUser?.value.id || "",
    ticketId: ticketId
  })),
  pause: computed(() => !ticketId)
});

const ticket = computed(() => data.value?.tickets_by_pk);
const ticketHasLogs = computed(() => !!ticket.value?.date_range?.first_work);
const ticketIsArchived = computed(() => ticket.value?.deleted_at !== null);
const canArchiveTicket = computed(() => {
  return (
    (ticket.value?.ticket_status === Ticket_Statuses_Enum.Open ||
      ticket.value?.ticket_status === Ticket_Statuses_Enum.InProgress) &&
    !ticketHasLogs.value
  );
});

provide(CurrentTicketKey, ticket);

const { canAddLogsOnTicket, reasonAddForbid: reason } =
  useLogPermissions(ticket);

const isAssignedAgent = computed(
  () =>
    ticket.value?.ticket_agents.find(
      ta => ta.agent.id === currentUser.value.id
    ) !== undefined
);

const canEditTicket = computed(() => {
  const notClosed = ticket.value?.ticket_status !== Ticket_Statuses_Enum.Closed;
  const isAuthor = ticket.value?.author?.id === currentUser.value.id;
  const canEditOwn = can("tickets:update");
  const canEditAll = can("tickets:update_all");

  return (
    notClosed &&
    (canEditAll || (canEditOwn && (isAuthor || isAssignedAgent.value)))
  );
});

const onCopyToClipboard = async () => {
  copyToClipboard(new URL(route.fullPath, window.location.origin).href);
  createToast({
    message: "Copied to clipboard.",
    theme: "success"
  });
};

const { executeMutation: archiveTicket } = useMutation(
  graphql(/* GraphQL */ `
    mutation ArchiveTicket($ticketId: uuid!) {
      update_tickets_by_pk(
        pk_columns: { id: $ticketId }
        _set: { deleted_at: "now()" }
      ) {
        id
      }
    }
  `)
);

const { executeMutation: reinstateTicket } = useMutation(
  graphql(/* GraphQL */ `
    mutation ReinstateTicket($ticketId: uuid!) {
      update_tickets_by_pk(
        pk_columns: { id: $ticketId }
        _set: { deleted_at: null }
      ) {
        id
      }
    }
  `)
);

const onArchiveTicket = async () => {
  if (!confirm("Are you sure you want to archive this ticket?")) {
    return;
  }
  const result = await archiveTicket({
    ticketId: ticket?.value?.id ?? "unknown ticket"
  });
  if (result.error) {
    createToast({
      title: "Unable to archive the ticket.",
      message: result.error?.message || "Unknown error.",
      theme: "danger"
    });
  } else {
    createToast({
      message: "Ticket archived.",
      theme: "success"
    });
    router.back();
  }
};

const onReinstateTicket = async () => {
  if (!confirm("Are you sure you want to reinstate this ticket?")) {
    return;
  }
  const result = await reinstateTicket({
    ticketId: ticket?.value?.id ?? "unknown ticket"
  });
  if (result.error) {
    createToast({
      title: "Unable to reinstate the ticket.",
      message: result.error?.message || "Unknown error.",
      theme: "danger"
    });
  } else {
    createToast({
      message: "Ticket reinstated.",
      theme: "success"
    });
  }
};

const menuItems = computed(() => {
  const res: MenuEntry[] = [
    {
      id: "copy",
      icon: faCopy,
      label: "Copy Link",
      onClick: onCopyToClipboard
    },
    {
      id: "report",
      icon: faFilePdf,
      label: "Create Report",
      to: { name: "TicketReportForm", params: { ticketId } }
    },
    {
      id: "edit",
      icon: faPen,
      label: "Edit",
      disabled: !canEditTicket.value,
      disabledReason: "",
      to: canEditTicket.value
        ? {
            name: "TicketEdit",
            params: {}
          }
        : ""
    }
  ];

  if (ticketIsArchived.value) {
    res.push({
      id: "unarchive",
      icon: canArchiveTicket.value ? faTrashCanArrowUp : faBan,
      label: "Reinstate",
      isDanger: false,
      onClick: onReinstateTicket
    });
  } else {
    res.push({
      id: "archive",
      icon: canArchiveTicket.value ? faTrashCan : faTrashCanSlash,
      label: "Archive",
      isDanger: true,
      disabled: !canArchiveTicket.value,
      disabledReason: "Tickets with logged work cannot be archived.",
      onClick: onArchiveTicket
    });
  }

  return res;
});

const routerTabs = computed(() => {
  const res = [
    {
      title: "Activity",
      to: { name: "TicketActivityView", query: { filter: "" } }
    },
    {
      title: "Logs",
      to: { name: "WorkLogsView" }
    },
    {
      title: "Mileage",
      to: { name: "TravelLogsView" }
    },
    {
      title: "Expenses",
      to: { name: "ExpenseLogsView" }
    },
    {
      title: "Review",
      to: { name: "TicketReview" }
    },
    {
      title: "Media",
      to: { name: "TicketMediaView" }
    },
    { title: "Forms", to: { name: "TicketCustomForms" } },
    {
      title: "Time Sheet",
      to: { name: "TicketTimeSheet" },
      allowedPermissions: isAssignedAgent.value
        ? ["time_sheets:view_self"]
        : ["time_sheets:view"]
    }
  ];

  if (ticket.value?.work_order.customer.requires_signature) {
    res.push({
      title: "Signature",
      to: { name: "TicketSignatureDetail" }
      // allowedPermissions: ["tickets:request_signature"]
    });
  }

  return res;
});
</script>

<template>
  <section class="ticket-detail xmargin">
    <SHSpinner v-if="fetching" />
    <article v-else-if="!ticketItemId" class="vertical">
      <header class="vertical loose">
        <TicketCard
          v-if="ticket"
          :ticket="ticket"
          no-hover
          show-work-order
          expandable
        />
      </header>

      <SHNote v-if="!fetching && !canAddLogsOnTicket && reason" theme="warning">
        {{ reason }}
      </SHNote>

      <RouterTabs
        query-string-match="filter"
        :exact-match="false"
        :tabs="routerTabs"
      >
        <template #endOfRow>
          <SHDropdown placement="bottom-end" strategy="fixed">
            <template #default="{ toggle }">
              <SHButton color="surface-300" square size="sm" @click="toggle">
                <FontAwesomeIcon :icon="faEllipsisVertical" fixed-width />
              </SHButton>
            </template>

            <template #popup="{ close }">
              <div class="popup">
                <SHMenu :items="menuItems" @click="() => close()" />
              </div>
            </template>
          </SHDropdown>
        </template>
      </RouterTabs>
    </article>
  </section>
</template>

<style lang="scss" scoped>
section.ticket-detail {
  article {
    .popup {
      border: thin solid var(--color-surface-300);
      border-radius: var(--border-radius);
      header {
        padding: 1em 0.5em;
      }
      main {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        align-items: stretch;
        justify-content: flex-start;
      }
    }
  }
}
</style>
