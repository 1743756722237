import { graphql } from "@/generated";
import { useLogger } from "@/logger";
import { useMutation } from "@urql/vue";

const { log } = useLogger("useCustomers"); // eslint-disable-line @typescript-eslint/no-unused-vars

export const useCustomers = () => {
  return {
    mutations: {
      createContact: useMutation(
        graphql(/* GraphQL */ `
          mutation CustomerContactCreate(
            $contact: customer_contacts_insert_input!
          ) {
            insert_customer_contacts_one(object: $contact) {
              ...CustomerContactChoice
            }
          }
        `)
      )
    }
  };
};
