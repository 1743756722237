import {
  Delivery_Statuses_Enum,
  Quote_Event_Types_Enum,
  Review_Statuses_Enum
} from "@/generated/graphql";
import {
  faArrowDownFromLine,
  faArrowLeftToLine,
  faArrowRightToLine,
  faBan,
  faCheck,
  faComment,
  faPencil,
  faQuestion,
  faTrashPlus,
  faTrashRestore
} from "@fortawesome/sharp-light-svg-icons";

export const getEventIcon = (eventType: Quote_Event_Types_Enum) => {
  switch (eventType) {
    case Quote_Event_Types_Enum.Created:
      return faArrowDownFromLine;
    case Quote_Event_Types_Enum.Updated:
      return faPencil;
    case Quote_Event_Types_Enum.Archived:
      return faTrashPlus;
    case Quote_Event_Types_Enum.Unarchived:
      return faTrashRestore;
    case Quote_Event_Types_Enum.Commented:
      return faComment;
    case Quote_Event_Types_Enum.Requested:
      return faArrowRightToLine;
    case Quote_Event_Types_Enum.Accepted:
      return faCheck;
    case Quote_Event_Types_Enum.Rejected:
      return faArrowLeftToLine;
    case Quote_Event_Types_Enum.Canceled:
      return faBan;
    default:
      return faQuestion;
  }
};
export const getEventForegroundColor = (eventType: Quote_Event_Types_Enum) => {
  switch (eventType) {
    case Quote_Event_Types_Enum.Created:
      return "var(--color-surface-400)";
    case Quote_Event_Types_Enum.Updated:
      return "var(--color-primary)";
    case Quote_Event_Types_Enum.Archived:
      return "var(--color-warning)";
    case Quote_Event_Types_Enum.Unarchived:
      return "var(--color-surface-50)";
    case Quote_Event_Types_Enum.Commented:
      return "var(--color-surface-50)";
    case Quote_Event_Types_Enum.Requested:
      return "var(--color-surface-50)";
    case Quote_Event_Types_Enum.Accepted:
      return "var(--color-surface-50)";
    case Quote_Event_Types_Enum.Rejected:
      return "var(--color-surface-50)";
    case Quote_Event_Types_Enum.Canceled:
      return "var(--color-surface-50)";
    default:
      return "magenta";
  }
};
export const getEventBackgroundColor = (eventType: Quote_Event_Types_Enum) => {
  switch (eventType) {
    case Quote_Event_Types_Enum.Created:
      return "var(--color-surface-100)";
    case Quote_Event_Types_Enum.Updated:
      return "var(--color-surface-900)";
    case Quote_Event_Types_Enum.Archived:
      return "var(--color-surface-600)";
    case Quote_Event_Types_Enum.Unarchived:
      return "vaR(--color-warning)";
    case Quote_Event_Types_Enum.Commented:
      return "var(--color-primary)";
    case Quote_Event_Types_Enum.Requested:
      return "var(--color-info)";
    case Quote_Event_Types_Enum.Accepted:
      return "var(--color-success)";
    case Quote_Event_Types_Enum.Rejected:
      return "var(--color-danger)";
    case Quote_Event_Types_Enum.Canceled:
      return "var(--color-surface-500)";
    default:
      return "magenta";
  }
};
export const getStatusForeground = (
  deliveryStatus: Delivery_Statuses_Enum,
  reviewStatus: Review_Statuses_Enum
): string => {
  if (deliveryStatus === Delivery_Statuses_Enum.Draft) {
    return "var(--color-secondary)";
  } else if (reviewStatus === Review_Statuses_Enum.Requested) {
    return "var(--color-primary)";
  } else if (reviewStatus === Review_Statuses_Enum.Accepted) {
    return "var(--color-success)";
  } else if (reviewStatus === Review_Statuses_Enum.Rejected) {
    return "var(--color-danger)";
  }
  return "var(--color-surface-600)";
};
