import { graphql, useFragment } from "@/generated";
import { useLogger } from "@/logger";

import { useMutation, useQuery } from "@urql/vue";
import { computed, toValue, type MaybeRefOrGetter } from "vue";

const { log } = useLogger("useQuotes"); // eslint-disable-line @typescript-eslint/no-unused-vars

const detailfragment = graphql(/* GraphQL */ `
  fragment UseQuotes on quote_quotes {
    ...QuoteCard
    id
    ref
    created_at
    updated_at
    deleted_at
    delivery_status
    lifecycle_status
    review_status
    notesj

    author {
      ...UserLink
    }

    customer {
      ...CustomerLink
      ...CustomerChoice
      id
      title
      abbreviation
      mailing_address
      price_book {
        id
        title
      }
      contacts_aggregate(where: { deleted_at: { _is_null: true } }) {
        aggregate {
          count
        }
      }
    }

    media_uploads(order_by: [{ created_at: asc }]) {
      id
      caption
      ...MediaUploadCard
      ...SHLightbox
    }
  }
`);

const itemsFragment = graphql(/* GraphQL */ `
  fragment UseQuotesItems on quote_items {
    id
    product_override
    qty
    subtotal_cents
    subtotal_override_cents
    notes
    product {
      id
      title
      catalog_id
    }
  }
`);

const detailQuery = graphql(/* GraphQL */ `
  query UseQuoteComposable($quoteId: uuid!) {
    quote_quotes_by_pk(id: $quoteId) {
      ...UseQuotes
    }
  }
`);

const itemsQuery = graphql(/* GraphQL */ `
  query UseQuoteItems($quoteId: uuid!) {
    quote_items(where: { quote_id: { _eq: $quoteId } }) {
      ...UseQuotesItems
    }
  }
`);

export const useQuotes = () => {
  const getByPk = (quoteId: MaybeRefOrGetter<string>) => {
    const { data, fetching, error } = useQuery({
      query: detailQuery,
      variables: computed(() => ({
        quoteId: toValue(quoteId) || "unknown quote id"
      })),
      pause: computed(() => !toValue(quoteId))
    });
    const quote = computed(() =>
      useFragment(detailfragment, data.value?.quote_quotes_by_pk)
    );
    return { quote, fetching, error };
  };

  const getByPkAsync = async (quoteId: MaybeRefOrGetter<string>) => {
    const { data, fetching, error } = await useQuery({
      query: detailQuery,
      variables: computed(() => ({
        quoteId: toValue(quoteId) || "unknown quote id"
      })),
      pause: computed(() => !toValue(quoteId))
    });
    const quote = computed(() =>
      useFragment(detailfragment, data.value?.quote_quotes_by_pk)
    );
    return { quote, fetching, error };
  };

  const getItemsAsync = async (quoteId: MaybeRefOrGetter<string>) => {
    const { data, fetching, error } = await useQuery({
      query: itemsQuery,
      variables: computed(() => ({
        quoteId: toValue(quoteId) || "unknown quote id"
      })),
      pause: computed(() => !toValue(quoteId))
    });
    const items = computed(() =>
      useFragment(itemsFragment, data.value?.quote_items ?? [])
    );
    return { items, fetching, error };
  };

  return {
    getByPk,
    getByPkAsync,
    getItemsAsync,
    mutations: {
      create: useMutation(
        graphql(/* GraphQL */ `
          mutation QuoteCreate($args: quote_create_args!) {
            quote_create(args: $args) {
              id
              ref
            }
          }
        `)
      ),
      update: useMutation(
        graphql(/* GraphQL */ `
          mutation QuoteUpdate($args: quote_update_args!) {
            quote_update(args: $args) {
              id
              ref
            }
          }
        `)
      ),
      comment: useMutation(
        graphql(/* GraphQL */ `
          mutation QuoteComment($args: quote_comment_args!) {
            quote_comment(args: $args) {
              id
            }
          }
        `)
      ),
      send: useMutation(
        graphql(/* GraphQL */ `
          mutation QuoteSendAction(
            $quoteId: uuid!
            $userIds: [String!]!
            $customerContacts: [uuid!]!
          ) {
            sendQuote(
              quoteId: $quoteId
              userIds: $userIds
              contactIds: $customerContacts
            ) {
              quote {
                id
                ref
              }
            }
          }
        `)
      ),
      accept: useMutation(
        graphql(/* GraphQL */ `
          mutation QuoteAccept($args: quote_accept_args!) {
            quote_accept(args: $args) {
              id
            }
          }
        `)
      ),
      reject: useMutation(
        graphql(/* GraphQL */ `
          mutation QuoteReject($args: quote_reject_args!) {
            quote_reject(args: $args) {
              id
            }
          }
        `)
      )
    }
  };
};
