<script setup lang="ts">
import QueryFilter from "@/components/QueryFilter.vue";
import SHBadge from "@/components/SHBadge.vue";
import SHButton from "@/components/SHButton.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHNote from "@/components/SHNote.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHTable from "@/components/SHTable.vue";
import SHTableRowCount from "@/components/SHTableRowCount.vue";
import { useFacets } from "@/composables/useFacets";
import type { OrderSet } from "@/composables/useQueryStringFilters";
import { useRole } from "@/composables/useRole";
import { graphql } from "@/generated";
import {
  Order_By,
  type Product_Types_Enum,
  type Products_Order_By
} from "@/generated/graphql";
import { CollectionView } from "@/lib/collectionViewTypes";
import {
  faArrowTurnUp,
  faCalculator,
  faPen,
  faPlus
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation, useQuery } from "@urql/vue";
import { computed } from "vue";

const { catalogId } = defineProps<{
  catalogId: string;
  query?: string;
  productType?: Product_Types_Enum;
}>();

const { can } = useRole();

const orderByFilters: OrderSet<Products_Order_By> = {
  defaultValue: "title",
  defaultOrderBy: Order_By.Asc,
  options: [
    {
      id: "title",
      title: "Product",
      asc: [{ title: Order_By.Asc }],
      desc: [{ title: Order_By.Desc }]
    },
    {
      id: "author",
      title: "Author",
      asc: [{ author: { full_name: Order_By.Asc } }],
      desc: [{ author: { full_name: Order_By.Desc } }]
    },
    {
      id: "created_at",
      title: "Created",
      asc: [{ created_at: Order_By.Asc }],
      desc: [{ created_at: Order_By.Desc }]
    },
    {
      id: "updated_at",
      title: "Updated",
      asc: [{ updated_at: Order_By.Asc }],
      desc: [{ updated_at: Order_By.Desc }]
    }
  ]
};

const {
  facetModels,
  resetFacets,
  searchString,
  sortOrder,
  updateSearch,
  updateSortDirection,
  updateSortType
} = useFacets([], orderByFilters);

const { data, fetching, error, executeQuery } = useQuery({
  query: graphql(/* GraphQL */ `
    query ProductsIndex(
      $where: products_bool_exp
      $order_by: [products_order_by!]
      $limit: Int!
      $offset: Int!
    ) {
      products(
        where: $where
        order_by: $order_by
        limit: $limit
        offset: $offset
      ) {
        id
        title
        product_type
        created_at
        deleted_at

        catalog {
          title
        }

        products_xref_custom_forms_aggregate {
          aggregate {
            count
          }
        }
      }

      products_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `),
  variables: computed(() => ({
    where: {
      _and: [
        { catalog_id: { _eq: catalogId } },
        {
          title: { _ilike: `${searchString.value}%` }
        }
      ]
    },
    order_by: sortOrder.value,
    limit: 50, // TODO: pagination
    offset: 0
  })),
  context: { additionalTypenames: ["products"] }
});

const products = computed(() => data.value?.products ?? []);
const productsAgg = computed(() => data.value?.products_aggregate);
const activeProductCount = computed(
  () => data.value?.products_aggregate.aggregate?.count || 0
);

const archivedProducts = computed(() =>
  products.value.filter(p => !!p.deleted_at)
);

const activeProducts = computed(() =>
  products.value.filter(p => !archivedProducts.value.includes(p))
);

const restoreMutation = graphql(/* GraphQL */ `
  mutation ProductsViewRestoreProduct($productId: uuid!) {
    update_products_by_pk(
      pk_columns: { id: $productId }
      _set: { deleted_at: null }
    ) {
      id
    }
  }
`);

const { executeMutation: restore } = useMutation(restoreMutation);

async function onRestore(productId: string) {
  const { data, error } = await restore({ productId });

  if (error) {
    throw new Error("Unable to restore product.");
  }

  if (data) {
    await executeQuery();
  }
}
</script>

<template>
  <article class="products-view vertical">
    <QueryFilter
      :default-collection-view="CollectionView.Table"
      :facets="[]"
      :facet-models="facetModels"
      :fetching="fetching"
      :order-set="orderByFilters"
      :result-count="productsAgg?.aggregate?.count"
      clear-all-filters
      no-card-view
      no-table-view
      no-calendar-view
      placeholder="Search by Name..."
      searchable
      @reset:facets="resetFacets"
      @update:search="updateSearch($event)"
      @update:sort-direction="updateSortDirection($event)"
      @update:sort-type="updateSortType($event)"
    >
      <template v-if="can('catalogs:update')" #leftOfSearch>
        <SHButton
          color="primary"
          :to="{
            name: 'ProductCreate'
          }"
        >
          <div class="level tight">
            <FontAwesomeIcon :icon="faPlus" />
            <span>Product</span>
          </div>
        </SHButton>
      </template>
    </QueryFilter>

    <SHSpinner v-if="fetching" />

    <SHNote v-else-if="error" theme="danger">
      {{ error }}
    </SHNote>

    <section class="vertical">
      <!-- Active Products -->
      <strong>Active Products</strong>
      <SHTable
        :rows="activeProducts"
        class="products-table"
        empty-message="No active products found."
        fluid
      >
        <template #body="{ rows }">
          <template v-for="product in rows" :key="product.id">
            <tr>
              <td style="width: 70%">
                <div class="level tight">
                  <SHButton
                    v-if="can('catalogs:update')"
                    square
                    size="sm"
                    color="primary"
                    :to="{
                      name: 'ProductEdit',
                      params: { productId: product.id }
                    }"
                  >
                    <FontAwesomeIcon :icon="faPen" />
                  </SHButton>

                  {{ product.title }}
                </div>
              </td>
              <td>
                <div class="level-end tight">
                  <SHBadge
                    class="number level tight"
                    :color="
                      (product.products_xref_custom_forms_aggregate.aggregate
                        ?.count || 0) > 0
                        ? 'var(--color-surface-500)'
                        : undefined
                    "
                  >
                    <FontAwesomeIcon :icon="faCalculator" />
                    {{
                      product.products_xref_custom_forms_aggregate.aggregate
                        ?.count || 0
                    }}
                  </SHBadge>
                </div>
              </td>
            </tr>
          </template>
        </template>

        <template #footer>
          <SHTableRowCount :count="activeProductCount" label="active product" />
        </template>
      </SHTable>

      <div class="vertical">
        <!-- Archived Products -->
        <strong>Archived Products</strong>
        <SHTable
          :rows="archivedProducts"
          empty-message="No archived products found."
        >
          <template #header>
            <th>Title</th>
            <th>Last Updated</th>
            <th style="width: 3em"></th>
          </template>

          <template #row="{ row }">
            <td>{{ row.title }}</td>
            <td>
              <SHInlineDate :d="row.deleted_at" />
            </td>
            <td>
              <SHButton
                v-if="can('catalogs:update')"
                link
                color="success"
                @click="onRestore(row.id)"
              >
                <FontAwesomeIcon :icon="faArrowTurnUp" />
              </SHButton>
            </td>
          </template>

          <template #footer>
            <SHTableRowCount
              :count="archivedProducts.length"
              label="archived product"
            />
          </template>
        </SHTable>
      </div>
    </section>
  </article>
</template>

<style lang="scss" scoped>
.products-view {
  section {
    &.empty {
      text-align: center;
      aspect-ratio: 1;

      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 2em;
      padding: 2em 1em;
      border: thin dashed var(--color-surface);

      svg {
        font-size: 8em;
        color: var(--color-danger);
      }
    }

    h2 {
      padding: var(--padding);
      font-size: var(--font-size-md);
    }
  }

  .products-table {
    > .expand {
      &:active {
        background: unset;
      }

      > td {
        padding: 0.5em;

        .placeholder {
          text-align: left;
          padding: var(--padding);
          color: var(--color-surface-500);
        }
      }
    }
  }
}
</style>
