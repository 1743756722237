<script async setup lang="ts">
import type { ProductChoice } from "@/composables/useProductChoice";
import { formatUSD } from "@/formatters";
import { graphql } from "@/generated";
import type { UseQuotesItemsFragment } from "@/generated/graphql";
import { useLogger } from "@/logger";
import { faCancel, faEdit, faTimes } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useQuery } from "@urql/vue";
import { computed } from "vue";
import SHButton from "./SHButton.vue";
import SHDollarInput from "./SHDollarInput.vue";
import SHField from "./SHField.vue";
import SHInput from "./SHInput.vue";
import SHNote from "./SHNote.vue";
import SHNumberInput from "./SHNumberInput.vue";
import SHProductChooser from "./SHProductChooser.vue";

const { log } = useLogger("QuoteItemForm");

const props = defineProps<{
  priceBookId?: string;
  lineItemNumber: number;
}>();

defineEmits<{
  (e: "deleteItem"): void;
}>();

const modelValue = defineModel<UseQuotesItemsFragment>({ required: true });

const { data, error } = await useQuery({
  query: graphql(/* GraphQL */ `
    query QuoteItemForm($priceBookId: uuid!, $productId: uuid!) {
      product_prices(
        limit: 1
        order_by: [{ valid_from: desc }]
        where: {
          product_id: { _eq: $productId }
          price_book_id: { _eq: $priceBookId }
          valid_from: { _lte: "now()" }
        }
      ) {
        id
        product_id
        cents_per_unit
        valid_from
      }
    }
  `),
  variables: computed(() => ({
    priceBookId: props.priceBookId || "unknown price book id",
    productId: modelValue.value?.product?.id || "unknown product id"
  })),
  pause: computed(() => !props.priceBookId || !modelValue.value?.product?.id)
});

const subTotalCents = computed(
  () =>
    modelValue.value.subtotal_override_cents ||
    (data.value?.product_prices.at(0)?.cents_per_unit ?? 0) *
      (modelValue.value.qty ?? 0)
);

const onProductUpdate = (p: ProductChoice) => {
  modelValue.value.product = p;
  modelValue.value.subtotal_cents = subTotalCents.value;
};
const onQtyUpdate = (qty: number | null | undefined) => {
  log("updating qty", qty);
  modelValue.value.qty = qty ?? 0;
  modelValue.value.subtotal_cents = subTotalCents.value;
  modelValue.value.subtotal_override_cents = undefined;
};
const onPriceOverride = (cents: number | null | undefined) => {
  log("on price override", cents);
  if (cents === modelValue.value.subtotal_cents) return;
  modelValue.value.subtotal_cents = cents ?? undefined;
  modelValue.value.subtotal_override_cents = cents ?? undefined;
};
const onProductOverride = () => {
  log("on price override");
  modelValue.value.product_override =
    modelValue.value?.product?.title ?? "Custom Item";
  modelValue.value.product = undefined;
};
const onUseProduct = () => {
  log("on use product");
  modelValue.value.product = undefined;
  modelValue.value.product_override = undefined;
};
</script>

<template>
  <SHNote v-if="error" theme="danger">{{ error.message }}</SHNote>
  <article v-else class="quote-item">
    <div class="vertical">
      <h2>{{ lineItemNumber ?? 1 }}</h2>
      <SHButton color="danger" square size="sm" @click="$emit('deleteItem')">
        <FontAwesomeIcon :icon="faTimes" />
      </SHButton>
    </div>
    <section class="fields">
      <SHField label="Product" style="grid-area: product">
        <template v-if="!priceBookId" #help>
          You must choose a customer to pick a product
        </template>
        <div v-if="!modelValue.product_override" class="level-spread tight">
          <SHProductChooser
            style="flex-grow: 1"
            :disabled="!priceBookId"
            :model-value="modelValue.product"
            :price-book-id="priceBookId"
            @update:model-value="onProductUpdate"
          />
          <SHButton
            size="sm"
            color="secondary"
            square
            @click="onProductOverride"
          >
            <FontAwesomeIcon :icon="faEdit" />
          </SHButton>
        </div>
        <div v-else class="level-spread tight">
          <SHInput v-model="modelValue.product_override" style="flex-grow: 1" />
          <SHButton size="sm" color="secondary" @click="onUseProduct">
            <FontAwesomeIcon :icon="faCancel" />
          </SHButton>
        </div>
      </SHField>
      <SHField label="Note" style="grid-area: note">
        <SHInput v-model="modelValue.notes" />
      </SHField>
      <SHField label="Qty" style="grid-area: qty">
        <SHNumberInput
          style="text-align: right"
          :model-value="modelValue.qty"
          :min="0"
          :max="10000"
          select-on-focus
          @update:model-value="onQtyUpdate"
        >
          <template v-if="data?.product_prices.at(0)" #suffix>
            <span class="suffix">
              @{{ formatUSD(data?.product_prices.at(0)?.cents_per_unit ?? 0) }}
            </span>
          </template>
        </SHNumberInput>
      </SHField>

      <SHField label="Total" class="total" style="grid-area: total">
        <SHDollarInput
          style="text-align: right"
          :model-value="subTotalCents"
          select-on-focus
          @update:model-value="onPriceOverride"
        />
      </SHField>
    </section>
    <div></div>
  </article>
</template>

<style lang="scss" scoped>
@use "../assets/scss/breakpoints.scss" as bp;

.quote-item {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  gap: 0.5em;
  box-shadow: var(--box-shadow);
  background: var(--color-surface-200);
  padding: 0.5em;
  .fields {
    padding: var(--padding);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "product"
      "note"
      "qty"
      "total";
    gap: 0.5em;
    .suffix {
      font-size: 0.85em;
      font-weight: 600;
      font-family: var(--font-family-monospace);
    }
    .total {
      text-align: right;
      font-family: var(--font-family-monospace);
    }
    @include bp.laptop {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "product note"
        "qty total";
    }
    @include bp.desktop {
      grid-template-columns: 2fr 1fr 12em 12em;
      grid-template-areas: "product note qty total";
    }
  }
}
</style>
