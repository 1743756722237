<script async setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import SHNote from "@/components/SHNote.vue";
import SHTable from "@/components/SHTable.vue";
import { formatUSD } from "@/formatters";
import { graphql, useFragment } from "@/generated";
import { useQuery } from "@urql/vue";
import { computed } from "vue";

const { quoteId } = defineProps<{
  quoteId: string;
}>();

const fragment = graphql(/* GraphQL */ `
  fragment QuoteSummary on quote_quotes {
    id
    ref
    customer {
      title
      mailing_address
      price_book {
        id
        title
      }
    }
    items {
      id
      product_override
      qty
      subtotal_cents
      subtotal_override_cents
      notes
      product {
        id
        title
        catalog_id
      }
    }
  }
`);

const { data, error } = await useQuery({
  query: graphql(/* GraphQL */ `
    query QuoteSummary($quoteId: uuid!) {
      quote_quotes_by_pk(id: $quoteId) {
        ...QuoteSummary
      }
    }
  `),
  variables: computed(() => ({ quoteId }))
});
const quote = computed(() =>
  useFragment(fragment, data.value?.quote_quotes_by_pk)
);
const subTotalCents = computed(() =>
  quote.value?.items.reduce(
    (total, item) =>
      total + (item.subtotal_override_cents ?? item.subtotal_cents ?? 0),
    0
  )
);
</script>

<template>
  <SHNote v-if="error" theme="danger">{{ error.message }}</SHNote>
  <SHNote v-else-if="!quote" theme="danger">Not found</SHNote>
  <article v-else class="quote-summary">
    <h2>Stuff</h2>
    <div v-if="quote?.customer?.mailing_address" class="level-spread wrap">
      <h4>Address</h4>
      <div class="address">
        {{ quote.customer.mailing_address }}
      </div>
    </div>
    <div class="level-spread wrap">
      <h4>Pricebook</h4>
      <span>{{ quote.customer?.price_book.title }}</span>
    </div>

    <h2>Items</h2>
    <SHTable :rows="quote?.items || []">
      <template #header>
        <th width="55%">Product</th>
        <th width="10%" style="text-align: right">Qty</th>
        <th width="25%" style="text-align: right">Subtotal</th>
      </template>
      <template #row="{ row }">
        <td>
          <AppLink
            v-if="row.product"
            :to="{
              name: 'ProductDetail',
              params: {
                catalogId: row.product.catalog_id,
                productId: row.product.id
              }
            }"
          >
            {{ row.product?.title }}
          </AppLink>
          <span v-else>{{ row.product_override }}</span>
          <div v-if="row.notes" class="notes">{{ row.notes }}</div>
        </td>
        <td class="number" style="text-align: right">{{ row.qty }}</td>
        <td class="subtotal">
          {{ formatUSD(row.subtotal_override_cents ?? row.subtotal_cents) }}
        </td>
      </template>
    </SHTable>
    <div class="level-end tight">
      <h3>Subtotal</h3>
      {{ formatUSD(subTotalCents) }}
    </div>
  </article>
</template>

<style lang="scss" scoped>
.quote-summary {
  .qty,
  .subtotal {
    text-align: right;
    font-family: var(--font-family-monospace);
  }
}
</style>
