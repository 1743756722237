import ModuleHeader from "@/components/ModuleHeader.vue";
import SHButton from "@/components/SHButton.vue";
import { useRole } from "@/composables/useRole";
import ExpensesView from "@/views/ExpensesView.vue";
import ServiceReportForm from "@/views/ServiceReportForm.vue";
import TicketForm from "@/views/TicketForm.vue";
import WorkOrderCreate from "@/views/WorkOrderCreate.vue";
import WorkOrderDetail from "@/views/WorkOrderDetail.vue";
import WorkOrderTicketsView from "@/views/WorkOrderTicketsView.vue";
import WorkOrdersView from "@/views/WorkOrdersView.vue";
import {
  faCalendarRange,
  faChartUser,
  faPlus
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { h } from "vue";
import type { RouteRecordRaw } from "vue-router";

export const workOrderRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "WorkOrdersView",
    components: {
      default: WorkOrdersView,
      header: h(
        ModuleHeader,
        { icon: faCalendarRange },
        {
          default: () => "Work Orders",
          nav: () =>
            useRole().can("work_orders:create")
              ? h(
                  SHButton,
                  {
                    to: { name: "WorkOrderCreate" },
                    color: "primary",
                    size: "sm"
                  },
                  () =>
                    h("div", [
                      h(FontAwesomeIcon, {
                        icon: faPlus,
                        fixedWidth: true
                      }),
                      h(FontAwesomeIcon, {
                        icon: faCalendarRange,
                        fixedWidth: true
                      })
                    ])
                )
              : ""
        }
      )
    }
  },
  {
    path: "new/:customerId?",
    name: "WorkOrderCreate",
    props: { default: true },
    components: {
      default: h(WorkOrderCreate, { class: "xmargin" }),
      header: h(
        ModuleHeader,
        { icon: faCalendarRange },
        () => "Create New Work Order"
      )
    }
  },
  {
    path: ":workOrderId",
    name: "WorkOrderDetail",
    components: {
      default: WorkOrderDetail,
      header: h(
        ModuleHeader,
        { icon: faCalendarRange },
        {
          default: () => "Work Order"
        }
      )
    },
    props: true,
    redirect: {
      name: "WorkOrderTicketsView",
      query: {
        status: "all",
        mode: "card"
      }
    },
    children: [
      {
        name: "WorkOrderTicketsView",
        path: "tickets",
        component: WorkOrderTicketsView,
        props: ({ params, query }) => ({
          ...params,
          mode: query.mode,
          status: query.status
        })
      },
      {
        path: "service-report",
        name: "CreateServiceReportFromWorkOrder",
        components: {
          default: ServiceReportForm,
          header: h(
            ModuleHeader,
            { icon: faChartUser },
            () => "Create Service Report"
          )
        },
        props: true
      },
      {
        path: "expenses",
        name: "WorkOrderExpenses",
        components: {
          default: h(ExpensesView, { noMargin: true }),
          header: h(
            ModuleHeader,
            { icon: faChartUser },
            () => "Work Order Expenses"
          )
        },
        props: true
      },
      {
        path: "tickets/new",
        name: "WorkOrderTicketCreate",
        component: TicketForm,
        props: ({ params }) => ({
          ...params,
          noMargin: true
        })
      }
    ]
  },
  {
    path: ":workOrderId/service-report",
    name: "ServiceReportCreate",
    components: {
      default: ServiceReportForm,
      header: h(
        ModuleHeader,
        { icon: faChartUser },
        () => "Create Service Report"
      )
    },
    props: {
      default: route => ({
        class: "xmargin",
        ...route.params
      })
    }
  }
];
