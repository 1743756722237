import ModuleHeader from "@/components/ModuleHeader.vue";
import SHButton from "@/components/SHButton.vue";
import CustomFormDetail from "@/views/CustomFormDetail.vue";
import CustomFormsView from "@/views/CustomFormsView.vue";
import ExpenseLogForm from "@/views/ExpenseLogForm.vue";
import MediaUploadsView from "@/views/MediaUploadsView.vue";
import TicketActivityView from "@/views/TicketActivityView.vue";
import TicketDetail from "@/views/TicketDetail.vue";
import TicketForm from "@/views/TicketForm.vue";
import TicketReportForm from "@/views/TicketReportForm.vue";
import TicketReview from "@/views/TicketReview.vue";
import TicketSignatureDetail from "@/views/TicketSignatureDetail.vue";
import TicketSignatureRequest from "@/views/TicketSignatureRequest.vue";
import TicketsView from "@/views/TicketsView.vue";
import TimeSheetsView from "@/views/TimeSheetsView.vue";
import TravelLogForm from "@/views/TravelLogForm.vue";
import WorkLogForm from "@/views/WorkLogForm.vue";
import {
  faCalendar,
  faClipboardListCheck,
  faPlus
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { h } from "vue";
import type { RouteRecordRaw } from "vue-router";

export const ticketRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "TicketsView",
    components: {
      default: TicketsView,
      header: h(
        ModuleHeader,
        {
          icon: faClipboardListCheck,
          allowedPermissions: [
            "calloff_tickets:create_self",
            "calloff_tickets:create_others"
          ]
        },
        {
          default: () => "Tickets",
          nav: () =>
            h(
              SHButton,
              { to: { name: "TicketCreate" }, color: "primary", size: "sm" },

              () =>
                h("div", [
                  h(FontAwesomeIcon, { icon: faPlus, fixedWidth: true }),
                  h(FontAwesomeIcon, {
                    icon: faClipboardListCheck,
                    fixedWidth: true
                  })
                ])
            )
        }
      )
    },
    props: {
      default: ({ params }) => ({
        ...params,
        ...{ showAddTicket: false }
      }),
      header: true
    }
  },
  {
    path: "new",
    name: "TicketCreate",
    components: {
      default: TicketForm,
      header: h(
        ModuleHeader,
        { icon: faCalendar },
        () => "Create New Ticket (Call-Off)"
      )
    }
  },
  {
    path: ":ticketId/edit",
    name: "TicketEdit",
    props: true,
    components: {
      default: TicketForm,
      header: h(ModuleHeader, { icon: faCalendar }, () => "Edit Ticket")
    }
  },
  {
    path: ":ticketId",
    name: "TicketDetail",
    components: {
      default: TicketDetail,
      header: h(
        ModuleHeader,
        { icon: faClipboardListCheck },
        () => "Ticket Detail"
      )
    },
    props: true,
    redirect: { name: "TicketActivityView" },
    children: [
      {
        path: "signature",
        name: "TicketSignatureDetail",
        props: true,
        component: TicketSignatureDetail
      },
      {
        path: "sign",
        name: "TicketSignatureRequest",
        props: true,
        component: TicketSignatureRequest
      },
      {
        path: "activity",
        name: "TicketActivityView",
        component: TicketActivityView,
        props(route) {
          return {
            ...route.params,
            ...route.query
          };
        }
      },
      {
        path: "report",
        name: "TicketReportForm",
        component: TicketReportForm,
        props(route) {
          return {
            ...route.params,
            ...route.query
          };
        }
      },
      {
        path: "activity/logs",
        name: "WorkLogsView",
        component: TicketActivityView,
        props(route) {
          return {
            ...route.params,
            filter: "logs"
          };
        }
      },
      {
        path: "activity/travel",
        name: "TravelLogsView",
        component: TicketActivityView,
        props(route) {
          return {
            ...route.params,
            filter: "travel"
          };
        }
      },
      {
        path: "activity/expenses",
        name: "ExpenseLogsView",
        component: TicketActivityView,
        props(route) {
          return {
            ...route.params,
            filter: "expenses"
          };
        }
      },
      {
        path: "workLogs/new",
        name: "WorkLogCreate",
        component: WorkLogForm,
        props: true
      },
      {
        path: "worklogs/:workLogId",
        name: "WorkLogDetail",
        redirect: {
          name: "WorkLogEdit"
        },
        children: [
          {
            path: "edit",
            name: "WorkLogEdit",
            component: WorkLogForm,
            props(route) {
              return {
                ...route.params,
                noMargin: true
              };
            }
          }
        ]
      },
      {
        path: "mileage/new",
        name: "TravelLogCreate",
        component: TravelLogForm,
        props: true
      },
      {
        path: "expense/new",
        name: "ExpenseLogCreate",
        component: ExpenseLogForm,
        props: true
      },
      {
        path: "review",
        name: "TicketReview",
        component: TicketReview,
        props: true
      },
      {
        path: "media",
        name: "TicketMediaView",
        component: MediaUploadsView,
        props: true
      },
      {
        path: "form",
        name: "CustomFormDetail",
        component: CustomFormDetail,
        // HACK: had to do this with query as multiple params on a child route of ticketDetail wouldn't load the route
        props: props => ({
          ...props.params, // ticketId doesn't come through in params since its grabbed as an earlier route param
          ...props.query
        })
      },
      {
        path: "forms",
        name: "TicketCustomForms",
        component: CustomFormsView,
        props: true
      },
      {
        path: "mileage/:travelLogId",
        name: "TravelLogDetail",
        redirect: {
          name: "TravelLogEdit"
        },
        children: [
          {
            path: "edit",
            name: "TravelLogEdit",
            component: TravelLogForm,
            props: true
          }
        ]
      },
      {
        path: "expense/:expenseLogId",
        name: "ExpenseLogDetail",
        redirect: {
          name: "ExpenseLogEdit"
        },
        children: [
          {
            path: "edit",
            name: "ExpenseLogEdit",
            component: ExpenseLogForm,
            props: true
          }
        ]
      },
      {
        path: "timesheet",
        name: "TicketTimeSheet",
        component: TimeSheetsView,
        props: true
      }
    ]
  }
];
