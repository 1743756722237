<script setup lang="ts">
import { injectStrict } from "@/lib/helpers";
import { useLogger } from "@/logger";
import { BreakPointsKey } from "@/providerKeys";
import { faFileCircleExclamation } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, onMounted, ref, watch } from "vue";
import SHSpinner from "./SHSpinner.vue";

const { src = "" } = defineProps<{
  alt: string;
  src: string;
}>();

const { log } = useLogger("SHImage");
const breakpoints = injectStrict(BreakPointsKey);
const isLoading = ref(false);
const loadFailed = ref(false);
const srcVideo = computed(() => src);
const videoEl = ref<HTMLVideoElement | null>(null);

const resetVideo = () => {
  if (videoEl.value) {
    isLoading.value = false;
    videoEl.value.pause();
    videoEl.value.currentTime = 0;
  }
};

const toggle = () => {
  if (!loadFailed.value && videoEl.value?.paused) {
    videoEl.value?.play();
  } else if (!loadFailed.value && !videoEl.value?.paused) {
    videoEl.value?.pause();
  }
};

defineExpose({
  loadFailed,
  isLoading,
  resetVideo,
  toggle
});

watch(
  srcVideo,
  () => {
    loadFailed.value = false;
    isLoading.value = true;
  },
  { immediate: true }
);

const onError = () => {
  loadFailed.value = true;
  isLoading.value = false;
  log("Unable to load: ", src);
};

const onLoaded = () => {
  isLoading.value = false;
  loadFailed.value = false;
};

onMounted(() => {
  if (src && videoEl.value) {
    videoEl.value?.load();
  }
});
</script>

<template>
  <div :class="{ shVideo: !loadFailed }">
    <div v-if="loadFailed" class="missing-video vertical">
      <FontAwesomeIcon
        :icon="faFileCircleExclamation"
        :size="breakpoints.includes('desktop') ? '3x' : '1x'"
      />
      <span v-if="breakpoints.includes('desktop')" class="alt-text">
        ({{ alt }})
      </span>
    </div>

    <template v-else>
      <div v-if="isLoading" class="spinner vertical">
        <SHSpinner />
        <span class="alt-text">{{ alt }}</span>
      </div>

      <video
        v-bind="$attrs"
        ref="videoEl"
        :src="src"
        @error="onError"
        @loadeddata="onLoaded"
      ></video>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.shVideo {
  user-select: none;

  .alt-text {
    font-size: 0.75em;
  }
}

.missing-video {
  color: var(--color-danger);
  min-width: 2em;
}

.missing-video,
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
