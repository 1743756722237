<script setup lang="ts">
import {
  faCirclePlay,
  faFile,
  faFileAudio,
  faFilePdf
} from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed } from "vue";

const {
  src,
  alt,
  contentType,
  size = "md",
  label
} = defineProps<{
  src: string;
  alt: string;
  contentType: string;
  label?: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}>();

const icon = computed(() => {
  switch (true) {
    case contentType.includes("audio/"):
      return faFileAudio;

    case contentType.includes("pdf"):
      return faFilePdf;

    case contentType.includes("video/"):
      return faCirclePlay;

    default:
      return faFile;
  }
});
</script>

<template>
  <article class="sh-file-thumbnail" :class="[size]">
    <main class="level-center">
      <FontAwesomeIcon
        v-if="contentType == 'audio' || 'video'"
        fixed-width
        :icon="faCirclePlay"
      />
      <a v-else class="file-link" :href="src">
        <FontAwesomeIcon fixed-width :icon="icon" />
      </a>
    </main>
    <footer>
      {{ label ?? alt }}
    </footer>
  </article>
</template>

<style lang="scss" scoped>
.sh-file-thumbnail {
  border-radius: var(--border-radius);
  border: thin solid var(--color-surface-200);
  cursor: pointer;
  display: grid;
  gap: 0.5em;
  grid-template-rows: 1fr min-content;
  padding: var(--padding);

  .file-link {
    display: flex;
  }

  svg {
    color: var(--color-secondary-down-100);

    &:hover {
      color: var(--color-secondary-down-300);
    }
  }
  footer {
    word-break: break-all;
  }

  &.xs svg {
    height: 4em;
  }

  &.sm svg {
    height: 6em;
  }

  &.md svg {
    width: 6em;
    height: 6em;
  }

  &.lg svg {
    height: 16em;
  }

  &.xl svg {
    height: 32em;
  }
}
</style>
