<script setup lang="ts">
import SHAlert from "@/components/SHAlert.vue";
import SHButton from "@/components/SHButton.vue";
import useWebpush from "@/composables/useWebpush";
import { injectStrict } from "@/lib/helpers";
import { CurrentUserKey } from "@/providerKeys";
import { useLocalStorage } from "@vueuse/core";
import { computed, ref } from "vue";

const { register, subscription, isLoading } = useWebpush();
const user = injectStrict(CurrentUserKey);

const doNotShowAgain = useLocalStorage("disableNotificationPrompt", false);
const showAlert = ref(true);

const enableNotifications = async () => {
  const response = await register(user.value.id);
  if (response) showAlert.value = false;
};

const hasValidSub = computed(() => {
  const activeSub = user.value.subscription;
  const localSub = subscription.value;
  return JSON.stringify(localSub) === activeSub;
});

const onClose = (dontShowAgain?: boolean) => {
  showAlert.value = false;
  doNotShowAgain.value = dontShowAgain;
};
</script>

<template>
  <Teleport to="body">
    <SHAlert
      :visible="!isLoading && !hasValidSub && showAlert && !doNotShowAgain"
      dontShowAgain
      @hide-alert="onClose"
    >
      <template #message>
        <div class="vertical">
          <div class="level tight">Push notifications disabled.</div>
          <div class="level loose">
            <SHButton
              color="success"
              style="width: max-content"
              @click="enableNotifications"
            >
              Enable Push
            </SHButton>
          </div>
        </div>
      </template>
    </SHAlert>
  </Teleport>
</template>
