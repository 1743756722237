import { graphql } from "@/generated";
import type { Feature_Flags_Enum } from "@/generated/graphql";
import { useLogger } from "@/logger";
import { useQuery } from "@urql/vue";
import { computed } from "vue";

const { log } = useLogger("useFeatureFlags"); // eslint-disable-line @typescript-eslint/no-unused-vars

export const useFeatureFlags = () => {
  const { data, fetching, error } = useQuery({
    query: graphql(/* GraphQL */ `
      query useFeatureFlags {
        hfns_org_get_enabled_flags(order_by: [{ feature_flag: asc }]) {
          feature_flag
        }
      }
    `)
  });

  const enabledFeatures = computed(() =>
    (data.value?.hfns_org_get_enabled_flags || []).map(ff => ff.feature_flag)
  );

  const hasFeature = (feature_flag: Feature_Flags_Enum | string): boolean => {
    // log("checking for flag", feature_flag);
    return fetching.value
      ? false // wait for the query
      : enabledFeatures.value.includes(feature_flag as Feature_Flags_Enum);
  };

  return {
    hasFeature,
    has: hasFeature,
    enabledFeatures,
    fetching,
    error
  };
};
