import type { RouteRecordRaw } from "vue-router";
import { agentRoutes } from "./agentRoutes";
import { catalogRoutes } from "./catalogRoutes";
import { customerRoutes } from "./customers";
import { dashboardRoutes } from "./dashboardRoutes";
import { expenseRoutes } from "./expenseRoutes";
import { invoiceRoutes } from "./invoiceRoutes";
import { orgSettingRoutes } from "./orgSettingRoutes";
import { pricebookRoutes } from "./pricebookRoutes";
import { quoteRoutes } from "./quoteRoutes";
import { reportRoutes } from "./reportRoutes";
import { ticketRoutes } from "./ticketRoutes";
import { workOrderRoutes } from "./workOrderRoutes";

const organizationRoutes: Array<RouteRecordRaw> = [
  { path: "", children: dashboardRoutes },
  { path: "/catalogs", children: catalogRoutes },
  { path: "/customers", children: customerRoutes },
  { path: "/invoices", children: invoiceRoutes },
  { path: "/expenses", children: expenseRoutes },
  { path: "/agents", children: agentRoutes },
  { path: "/pricebooks", children: pricebookRoutes },
  { path: "/tickets", children: ticketRoutes },
  { path: "/workorders", children: workOrderRoutes },
  { path: "/reports", children: reportRoutes },
  { path: "/org-settings", children: orgSettingRoutes },
  { path: "/quotes", children: quoteRoutes }
];

export default organizationRoutes;
