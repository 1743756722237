import { injectStrict } from "@/lib/helpers";
import { useLogger } from "@/logger";
import { ClientKey } from "@/providerKeys";
import { fileTypeFromBuffer } from "file-type";
import { ref, type Ref } from "vue";

const { log, error } = useLogger("useS3Upload");

export type S3UploadTask = {
  id: string;
  file: File;
  contentType: string;
  s3url: Ref<string>;
  progress: Ref<number>;
  abort: () => void;
  start: () => Promise<void>;
};

export function useS3Upload() {
  const client = injectStrict(ClientKey);

  async function getUploadPolicy(path: string, key: string, uploadId?: string) {
    const { data: policyData } = await client.query(
      /* GraphQL */ `
        query GetUploadPolicy($path: String!, $key: uuid!, $uploadId: String) {
          getSignedS3PostPolicy(path: $path, key: $key, uploadId: $uploadId) {
            url
            fields
          }
        }
      `,
      {
        path,
        key,
        uploadId
      }
    );

    const policy = policyData?.getSignedS3PostPolicy;

    if (!policy) {
      throw new ReferenceError("S3 Policy is null or undefined");
    }
    return policy;
  }

  async function startMultiPartUpload(path: string, key: string) {
    const { data: uploadData } = await client.query(
      /* GraphQL */ `
        query beginS3MultiPartUpload($path: String!, $key: uuid!) {
          beginS3MultiPartUpload(path: $path, id: $key) {
            statusCode
            serverSideEncryption
            bucket
            key
            uploadId
          }
        }
      `,
      {
        path,
        key
      }
    );

    const upload = uploadData?.beginS3MultiPartUpload;

    if (!upload) {
      throw new ReferenceError("S3 Policy is null or undefined");
    }
    return upload;
  }

  async function uploadToS3(
    file: File,
    id: string,
    path: string
  ): Promise<S3UploadTask> {
    log(`Starting upload for file: ${file.name}, id: ${id}, path: ${path}`);
    const fileExtension = file.name.split(".").pop();
    const fileName = `${id}.${fileExtension}`;
    log(`Generated fileName: ${fileName}`);

    let mimeType = file.type;
    try {
      const fileBuffer = await file.arrayBuffer();
      const fileType = await fileTypeFromBuffer(fileBuffer);

      if (fileType) {
        mimeType = fileType.mime;
        log(`Detected MIME type: ${mimeType}`);
      } else {
        log(`Unable to detect MIME type, using file.type: ${mimeType}`);
      }
    } catch (err) {
      error("Error getting file type", err);
    }

    let xhr: XMLHttpRequest;

    const s3url = ref("");
    const progress = ref(0);

    const task: S3UploadTask = {
      id,
      file,
      contentType: mimeType,
      s3url,
      progress,
      abort: () => {
        log(`Aborting upload for file: ${file.name}`);
        xhr?.abort();
      },
      start: async () => {
        log(`Starting multipart upload for file: ${file.name}`);
        const upload = await startMultiPartUpload(path, fileName);

        if (!upload.uploadId) {
          const errorMsg = "Unable to start multipart upload";
          error(errorMsg);
          throw new Error(errorMsg);
        }
        log(`Multipart upload started. Upload ID: ${upload.uploadId}`);

        const policy = await getUploadPolicy(path, fileName, upload.uploadId);
        log("Received upload policy", policy);

        const formData = new FormData();
        Object.entries(policy.fields as Record<string, string>).forEach(
          ([key, value]) => {
            formData.append(key, value);
            log(`Appended form data: ${key}`);
          }
        );
        formData.append("file", file);
        log("File appended to form data");

        xhr = new XMLHttpRequest();
        xhr.open("POST", policy.url, true);
        log(`XHR opened for URL: ${policy.url}`);

        return new Promise<void>((resolve, reject) => {
          xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
              s3url.value = `${policy.url}${policy.fields.key}`;
              log(`Upload successful. S3 URL: ${s3url.value}`);
              resolve();
            } else {
              const errorMsg = `HTTP error! status: ${xhr.status}`;
              error(errorMsg);
              reject(new Error(errorMsg));
            }
          };

          xhr.onerror = () => {
            const errorMsg = "Network error occurred";
            error(errorMsg);
            reject(new Error(errorMsg));
          };

          xhr.upload.onprogress = event => {
            if (event.lengthComputable) {
              progress.value = event.loaded / event.total;
              log(`Upload progress: ${(progress.value * 100).toFixed(2)}%`);
            }
          };

          log("Sending XHR request");
          xhr.send(formData);
        });
      }
    };

    return task;
  }

  return {
    uploadToS3
  };
}
