import { useLogger } from "@/logger";
import { useDebounceFn, useMutationObserver } from "@vueuse/core";
import { Previewer } from "pagedjs"; // eslint-disable-line @typescript-eslint/no-unused-vars
import type { Ref } from "vue";

const { log, error: logError } = useLogger("usePagedJsPreview"); // eslint-disable-line @typescript-eslint/no-unused-vars

/**  uncomment to make the below "module" observer fire for pagedjs preview
 class SHHandler extends Handler {
  // this thing is registered right below the definition, you can register a lot of callbacks here
  constructor(chunker: Chunker, polisher: Polisher, caller: Previewer) {
    super(chunker, polisher, caller);
    console.log("making SHHandler", caller);
  }

  onUrl(urlNode: unknown) {
    console.log("URL:", urlNode);
  }

  async afterParsed(parsed: HTMLElement) {
    // create an array that will store the images data later on
    const imagePromises: Promise<typeof Image>[] = [];
    // find all images parsed by paged.js
    const images = parsed.querySelectorAll("img");
    // for each image
    images.forEach(image => {
      console.log("IMAGE FOUND", image);

      const p = new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          console.log("loaded image", image.src);
          resolve(img);
        };
        img.onerror = reject;
        img.src = image.src;
      });
      imagePromises.push(p);
    });

    return await Promise.all(imagePromises).catch(err => {
      console.warn(err);
    });
  }

  afterPageLayout(pageFragment: unknown, page: unknown) {
    console.log("after page layout", { pageFragment, page });
  }
}
registerHandlers(SHHandler);
*/

/**
 * @param source a ref of an HTML element to be paged
 * @param target a ref to show the output of the paged html
 */
export function usePagedJsPreview(
  source: Ref<HTMLElement | undefined>,
  target: Ref<HTMLElement | undefined>,
  extraCssFiles?: string[]
) {
  useMutationObserver(
    source,
    useDebounceFn(() => {
      // log("MUTATION in source");
      rebuildPreview();
    }, 100),
    { subtree: true, childList: true }
  );

  const rebuildPreview = async () => {
    if (!source.value || !target.value || !target.value.parentElement) return;
    // log("building preview source:", source.value?.innerHTML.length);
    const zoomEl = target.value.parentElement;
    target.value.innerHTML = "";

    // cleanup the style tags the previewer adds
    document.head
      .querySelectorAll("style[data-pagedjs-inserted-styles]")
      .forEach(st => {
        // log("tossing old style tag", st);
        st.remove();
      });

    const rememberZoom = zoomEl.style.transform;
    zoomEl.style.transform = "none";
    zoomEl.style.visibility = "hidden";

    const paged = new Previewer();
    const styleSheets = [...(extraCssFiles || []), "/print.css"];
    try {
      await paged.preview(source.value?.innerHTML, styleSheets, target.value);
    } catch (e) {
      logError("couldn't preview content", e);
    }
    zoomEl.style.transform = rememberZoom;
    zoomEl.style.visibility = "visible";
    // log("building preview target:", target.value?.innerHTML.length);
  };

  return {
    rebuildPreview
  };
}
