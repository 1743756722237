import { graphql, useFragment, type FragmentType } from "@/generated";
import type { ProductChoiceFragment } from "@/generated/graphql";

const fragment = graphql(/* GraphQL */ `
  fragment ProductChoice on products {
    id
    title
    catalog_id
  }
`);

export type ProductChoice = ProductChoiceFragment;

/**
 * Overload the useFragment function to provide a more specific type for the agent choice fragment.
 * The overloads are written this make typescript work better when agent is null or undefined.
 */
export function useProductChoice(obj: FragmentType<typeof fragment>) {
  return useFragment(fragment, obj);
}
