import { graphql, useFragment, type FragmentType } from "@/generated";
import type { SignaturePayload } from "@/types";
import { useMutation } from "@urql/vue";
import { computed, toValue, type MaybeRefOrGetter } from "vue";
import { useSignatureUpload } from "./useSignatureUpload";

const fragment = graphql(/* GraphQL */ `
  fragment UseTicketSignature on tickets {
    id

    signature {
      id
      url
      author_name
      author_email
      created_at
    }

    work_order {
      customer {
        id
        requires_signature
      }
    }
  }
`);

export function useTicketSignature(
  ticket: MaybeRefOrGetter<FragmentType<typeof fragment> | null | undefined>
) {
  const data = computed(() => useFragment(fragment, toValue(ticket)));

  const { executeMutation } = useMutation(
    graphql(/* GraphQL */ `
      mutation SaveSignature(
        $signatureId: uuid!
        $ticketId: uuid!
        $customerId: uuid!
        $url: String!
        $authorName: String!
        $authorEmail: String!
      ) {
        insert_signatures_one(
          object: {
            id: $signatureId
            customer_id: $customerId
            url: $url
            author_name: $authorName
            author_email: $authorEmail
          }
        ) {
          id
          url
        }

        update_tickets_by_pk(
          pk_columns: { id: $ticketId }
          _set: { signature_id: $signatureId }
        ) {
          id
        }
      }
    `)
  );

  const { uploadSignature } = useSignatureUpload();

  async function signTicket(input: {
    authorName: string;
    authorEmail: string;
    payload: SignaturePayload;
  }) {
    if (!data.value) {
      throw new Error("Ticket data is missing");
    }

    // Upload the signature to S3
    const uploadTask = await uploadSignature(input.payload);

    // Create signature record in the database
    const signature = await executeMutation({
      signatureId: uploadTask.id,
      authorName: input.authorName,
      authorEmail: input.authorEmail,
      ticketId: data.value.id,
      customerId: data.value?.work_order.customer.id,
      url: uploadTask.s3url.value
    });

    if (!signature.data?.insert_signatures_one) {
      throw new Error("Failed to create signature record");
    }

    return signature.data.insert_signatures_one;
  }

  const signature = computed(() => {
    return data.value?.signature ?? null;
  });

  const isSignatureRequired = computed(() => {
    return data.value?.work_order.customer.requires_signature ?? false;
  });

  const isMissingSignature = computed<boolean>(() => {
    return isSignatureRequired.value && !signature.value;
  });

  return {
    signTicket,
    signature,
    isSignatureRequired,
    isMissingSignature
  };
}
