<script setup lang="ts" generic="T extends ListOption">
import { useFuse } from "@/composables/useFuse";
import { useLogger } from "@/logger";
import type { ComponentInstance, ListOption } from "@/types";
import type Fuse from "fuse.js";
import { computed, ref, type Ref } from "vue";

import SHCombo from "@/components/SHCombo.vue";
import SHInput from "@/components/SHInput.vue";
import SHList from "@/components/SHList.vue";
import SHModal from "@/components/SHModal.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { injectStrict } from "@/lib/helpers";
import { IsMobileKey } from "@/providerKeys";
import SHBadge from "../SHBadge.vue";

const { log } = useLogger("ComboBox"); // eslint-disable-line @typescript-eslint/no-unused-vars

const isMobile = injectStrict(IsMobileKey);

const props = defineProps<{
  options: T[];
  limit?: number;
  modelValue?: T | null | undefined;
  multiple?: T[] | undefined;

  mobileHeader: string;
  getOptionLabel?: (i: ListOption | T | null | undefined) => string;

  togglable?: boolean;
  loading?: boolean;
  enableFuse?: boolean;
  fuseOptions?: Fuse.IFuseOptions<T>;
  disabled?: boolean;
  placeholder?: string;
  compact?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:model-value", value: ListOption | null): void;
  (e: "update:multiple", value: ListOption[] | undefined): void;
}>();

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:model-value", val ?? null);
  }
});
const bufferValue = ref(modelValue.value) as Ref<typeof modelValue.value>;

const multiple = computed({
  get() {
    return props.multiple;
  },
  set(val) {
    if (val) {
      emit("update:multiple", val);
    }
  }
});
const bufferMultiple = ref(multiple.value) as Ref<typeof multiple.value>;
const isMultiSelect = computed(
  () => Array.isArray(multiple.value) && !modelValue.value
);

const onOpen = () => {
  if (isMobile.value) {
    isFullScreen.value = true;
  }
};

const isFullScreen = ref(false);
const search = ref("");
const { result: filteredOptions } = useFuse(
  search,
  computed(() => props.options),
  props.fuseOptions ?? {
    keys: ["id", "label", "title"],
    threshold: 0.5
  }
);

const comboComponent = ref<ComponentInstance<typeof SHCombo<T, T>>>();
const inputEl = computed(() => comboComponent.value?.inputEl?.value);

const shouldCloseOnSelect = computed(() => !multiple.value);

defineExpose({
  focus: () => {
    // HACK: editor doesn't know inputEl is a ref, so it treats `.value` like a string vs the element
    if (inputEl.value) (inputEl.value as unknown as HTMLInputElement).focus();
  }
});
</script>

<template>
  <article class="sh-wombo">
    <SHModal
      v-if="isMobile"
      v-model:show="isFullScreen"
      @close="
        bufferValue = modelValue;
        bufferMultiple = multiple;
      "
    >
      <template #brand>
        <h3>{{ mobileHeader }}</h3>
      </template>
      <div class="full-screen-menu" :class="{ isMultiSelect }">
        <div v-if="modelValue">
          Current Selection:
          <SHBadge color="var(--color-primary)">
            {{ modelValue?.label ?? modelValue?.title ?? "-" }}
          </SHBadge>
        </div>

        <SHInput v-model="search" class="search-input" placeholder="Search" />

        <SHList
          v-model="bufferValue"
          v-model:multiple="bufferMultiple"
          class="menu-list"
          :options="filteredOptions"
          :limit="limit"
        >
          <template
            v-if="$slots.option"
            #item="{ item, isSelected, isFocused }"
          >
            <slot
              name="mobile-option"
              :option="item"
              :is-selected="isSelected"
              :isFocused="isFocused"
            ></slot>
          </template>
        </SHList>
        <UndoConfirm
          class="buttons"
          confirm-enabled
          @confirm="
            modelValue = bufferValue;
            multiple = bufferMultiple;
            isFullScreen = false;
          "
          @undo="() => (isFullScreen = false)"
        >
          <template #confirm>
            Select {{ bufferValue?.label ?? bufferValue?.title ?? "-" }}
          </template>
        </UndoConfirm>
      </div>
    </SHModal>
    <SHCombo
      v-if="!isFullScreen"
      ref="comboComponent"
      v-model="modelValue"
      v-model:multiple="multiple"
      v-model:search="search"
      :searchable="enableFuse"
      :get-option-label="getOptionLabel"
      :options="filteredOptions"
      :limit="limit"
      :close-on-select="shouldCloseOnSelect"
      :togglable="togglable"
      :disabled="disabled"
      :loading="loading"
      :placeholder="placeholder"
      :compact="compact"
      @open="onOpen"
    >
      <template #selected-option="slotProps">
        <slot name="selected-option" v-bind="slotProps" />
      </template>
      <template v-if="$slots.option" #option="{ option }">
        <slot name="option" :option="option" />
      </template>
    </SHCombo>
  </article>
</template>

<style scoped lang="scss">
.full-screen-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1em;
  padding: var(--padding);

  .menu-list {
    flex-grow: 1;
    overflow-y: auto;
  }

  .buttons {
    justify-content: space-between;

    :deep(.confirm) {
      > span {
        // truncate text
        max-width: 70vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
