<script async setup lang="ts">
import OrgLogo from "@/components/OrgLogo.vue";
import SHField from "@/components/SHField.vue";
import SHInput from "@/components/SHInput.vue";
import SHLogo from "@/components/SHLogo.vue";
import SHModal from "@/components/SHModal.vue";
import SHPill from "@/components/SHPill.vue";
import SHSignaturePad from "@/components/SHSignaturePad.vue";
import SHSpinner from "@/components/SHSpinner.vue";
import SHWordMark from "@/components/SHWordMark.vue";
import UndoConfirm from "@/components/UndoConfirm.vue";
import { useTicketSignature } from "@/composables/useTicketSignature";
import { useToaster } from "@/composables/useToaster";
import { graphql } from "@/generated";
import { injectStrict } from "@/lib/helpers";
import { useLogger } from "@/logger";
import { IsMobileKey } from "@/providerKeys";
import type { SignaturePayload } from "@/types";
import { faMobileNotch } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useQuery } from "@urql/vue";
import { useElementBounding } from "@vueuse/core";
import { computed, ref } from "vue";

const { createToast } = useToaster();
const { log } = useLogger("TicketSignatureRequest");
const isMobile = injectStrict(IsMobileKey);

const props = defineProps<{
  ticketId: string;
}>();

const containerEl = ref<HTMLElement | null>(null);
const { width, height } = useElementBounding(containerEl);

const show = defineModel<boolean>("show", { default: true });
const requestStages = ["author", "signature", "confirm"] as const;
const currentStage = ref<(typeof requestStages)[number]>("author");

const isSaving = ref(false);
const confirmSignatureUrl = ref<string | null>(null);
const { data } = await useQuery({
  query: graphql(/* GraphQL */ `
    query TicketSignatureRequest($ticketId: uuid!) {
      tickets_by_pk(id: $ticketId) {
        ref

        work_site {
          title
        }

        product {
          title
        }

        date_range {
          first_work
          last_work
        }

        work_order {
          ref

          organization {
            title
            ...OrgLogo
          }

          customer_id
          customer {
            title
          }
        }

        ticket_agents {
          agent {
            id
            full_name
            email
            phone_number
          }
        }

        signature {
          author_name
          author_email
          url
        }

        combined_logs_aggregate {
          aggregate {
            sum {
              work_hours
              travel_hours
              travel_miles
            }
          }
        }

        ...UseTicketSignature
      }
    }
  `),
  variables: computed(() => ({
    ticketId: props.ticketId
  })),
  pause: computed(() => !props.ticketId)
});

const ticket = computed(() => data.value?.tickets_by_pk ?? null);

const { signTicket } = useTicketSignature(ticket);

const authorName = ref<string | null>("");
const authorEmail = ref<string | null>("");

const payload = ref<SignaturePayload | null>(null);

const isValid = computed(
  () => !!authorName.value && !!authorEmail.value && !!payload.value
);

const ticketAgent = computed(
  () => ticket.value?.ticket_agents[0].agent ?? null
);
const workHours = computed(() => {
  return ticket.value?.combined_logs_aggregate.aggregate?.sum?.work_hours ?? 0;
});

const travelHours = computed(() => {
  return (
    ticket.value?.combined_logs_aggregate.aggregate?.sum?.travel_hours ?? 0
  );
});

const travelMiles = computed(() => {
  return (
    ticket.value?.combined_logs_aggregate.aggregate?.sum?.travel_miles ?? 0
  );
});

const { format: formatNumber } = new Intl.NumberFormat("en-US", {
  style: "decimal",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
});

async function handleSubmit() {
  if (
    !isValid.value ||
    !ticket.value?.work_order?.customer_id ||
    !authorEmail.value ||
    !authorName.value ||
    !payload.value
  ) {
    return { signature: null, error: new Error("Invalid form data") };
  }

  isSaving.value = true;

  try {
    const signature = await signTicket({
      payload: payload.value,
      authorName: authorName.value,
      authorEmail: authorEmail.value
    });

    log("Signature uploaded", signature);

    confirmSignatureUrl.value = signature.url;

    isSaving.value = false;
    return { signature, error: null };
  } catch (error) {
    console.error("Error uploading signature:", error);
    createToast({
      message: "Signature upload failed",
      theme: "danger"
    });
    isSaving.value = false;
    return { signature: null, error };
  }
}
</script>

<template>
  <SHSpinner v-if="!ticket" />
  <SHModal v-else class="ticket-signature-request-modal" :show="show" no-header>
    <article
      v-if="isMobile && currentStage === 'signature'"
      class="request-landscape-mode"
    >
      <h1 class="level-center">Rotate to Landscape</h1>
      <div class="icon-container">
        <FontAwesomeIcon
          class="rotate-mobile"
          size="10x"
          :icon="faMobileNotch"
        />
      </div>
    </article>
    <article v-else class="ticket-signature-request">
      <header
        v-if="currentStage !== 'signature'"
        class="signature-request-header"
      >
        <div class="org-info">
          <OrgLogo
            class="logo"
            :organization="ticket.work_order.organization"
          />
          <strong class="org-title level tight">
            {{ ticket.work_order.organization.title }}
          </strong>
          <span class="serviced-by level tight">
            Serviced By {{ ticketAgent?.full_name }}
          </span>
        </div>
        <strong class="worksite-title">{{ ticket.work_site.title }}</strong>
        <SHPill color="var(--color-surface-500)" left="#" :right="ticket.ref" />
        <div class="ticket-info">
          <SHPill
            :left="ticket.product.title"
            :right="`${formatNumber(workHours)} hrs`"
          />
          <SHPill
            :left="`${formatNumber(travelMiles)} mi`"
            :right="`${formatNumber(travelHours)} hrs`"
          />
        </div>
      </header>
      <section ref="containerEl" class="container">
        <template v-if="currentStage === 'author'">
          <article class="author-stage">
            <section class="form-container vertical">
              <SHField label="Customer Name">
                <SHInput v-model="authorName" type="text" class="author-name" />
              </SHField>
              <SHField label="Customer Work Email">
                <SHInput
                  v-model="authorEmail"
                  type="email"
                  placeholder="name@examaple.com"
                  class="author-email"
                />
              </SHField>
            </section>
          </article>
        </template>
        <template v-if="currentStage === 'signature'">
          <SHSignaturePad
            class="signing-pad"
            :width="width"
            :height="height"
            :model-value="payload"
            @update:model-value="payload = $event"
          />
        </template>
        <template v-if="currentStage === 'confirm'">
          <article class="confirmation-stage">
            <h2 class="congrats">Signature complete.</h2>
            <h3 class="level-center">Thank you for your business</h3>
          </article>
        </template>
      </section>
      <footer class="footer">
        <div class="spearhead">
          <SHLogo class="mark" />
          <SHWordMark class="word" />
        </div>
        <UndoConfirm
          v-if="currentStage === 'author'"
          :confirm-enabled="!!authorName && !!authorEmail"
          @undo="$router.back()"
          @confirm="currentStage = 'signature'"
        >
          <template #undo>Cancel</template>
          <template #confirm>Next</template>
        </UndoConfirm>
        <UndoConfirm
          v-if="currentStage === 'signature'"
          :confirm-enabled="!!payload"
          :loading="isSaving"
          @undo="currentStage = 'author'"
          @confirm="
            async () => {
              const { error } = await handleSubmit();
              if (error) {
                return;
              }
              currentStage = 'confirm';
            }
          "
        >
          <template #undo>Back</template>
          <template #confirm>Next</template>
        </UndoConfirm>
        <UndoConfirm
          v-if="currentStage === 'confirm'"
          hide-undo
          confirm-enabled
          @confirm="
            $router.push({
              name: 'TicketSignatureDetail',
              params: { ticketId: props.ticketId }
            })
          "
        >
          <template #confirm>Close</template>
        </UndoConfirm>
      </footer>
    </article>
  </SHModal>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/breakpoints.scss" as bp;

.ticket-signature-request-modal {
  height: 100dvh;
}

.ticket-signature-request {
  padding: 3dvh;

  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1em;
  height: 100%;

  .tight {
    gap: 0.2em;
  }

  h2 {
    font-size: 1em;
  }

  .signature-request-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1em;
  }

  .ticket-info {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2em;
  }

  .org-info {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    gap: 0.5em;

    .logo {
      grid-row: 1 / 3;
      height: 64px;
    }
  }

  .container {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .confirmation-stage {
    display: flex;
    flex-direction: column;

    .congrats {
      text-align: center;
      font-size: 3em;
    }

    .signature-date {
      font-size: 0.8em;
    }
  }

  .signature-details {
    display: flex;
    flex-direction: column;
    gap: 1em;

    @include bp.phone-landscape {
      flex-direction: row;
      gap: 2em;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;

    .spearhead {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5em;
      .mark {
        height: 39px;
      }
      .word {
        height: 22px;
      }
    }
  }
}

.request-landscape-mode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  h1 {
    margin-bottom: 2rem;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
  }

  .rotate-mobile {
    animation: rotatePhone 4s ease-in-out infinite;
    transform-origin: center;
  }

  @keyframes rotatePhone {
    0%,
    100% {
      transform: rotate(0deg);
      opacity: 1;
    }
    35% {
      transform: rotate(90deg);
      opacity: 1;
    }
    65% {
      transform: rotate(90deg);
      opacity: 0;
    }
    70% {
      transform: rotate(0deg);
      opacity: 0;
    }
    85% {
      transform: rotate(0deg);
      opacity: 1;
    }
  }
}
</style>
