<script async setup lang="ts">
import {
  useCustomerChoice,
  type CustomerChoice
} from "@/composables/useCustomerChoice";
import { useQuotes } from "@/composables/useQuotes";
import { useToaster } from "@/composables/useToaster";
import { formatUSD } from "@/formatters";
import { graphql } from "@/generated";
import type { UseQuotesItemsFragment } from "@/generated/graphql";
import { makeArrayWorkWithHasura } from "@/lib/helpers";
import { useLogger } from "@/logger";
import type { UploadedMedia } from "@/types";
import type { JSONContent } from "@tiptap/vue-3";
import { useQuery } from "@urql/vue";
import { v4 } from "uuid";
import { computed, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import QuoteItemForm from "./QuoteItemForm.vue";
import SHBadge from "./SHBadge.vue";
import SHButton from "./SHButton.vue";
import SHCustomerChooser from "./SHCustomerChooser.vue";
import SHField from "./SHField.vue";
import SHInput from "./SHInput.vue";
import SHNote from "./SHNote.vue";
import SHTextEditorWithToolbar from "./TextEditor/SHTextEditorWithToolbar.vue";
import UndoConfirm from "./UndoConfirm.vue";

const { log } = useLogger("QuoteForm");
const { createToast } = useToaster();
const router = useRouter();

const props = defineProps<{
  quoteId?: string; // set when editing, undefine for new
  customerId?: string;
}>();
const isEditing = computed(() => !!props.quoteId);

const { getByPkAsync, getItemsAsync } = useQuotes();
const { quote } = props.quoteId
  ? await getByPkAsync(props.quoteId)
  : { quote: computed(() => null) };
const { items } = props.quoteId
  ? await getItemsAsync(props.quoteId)
  : { items: computed(() => []) };

const currentCustomerId = ref<string | undefined>(props.customerId);

const { data, error } = await useQuery({
  query: graphql(/* GraphQL */ `
    query QuoteForm($isCustomerSet: Boolean!, $customerId: uuid!) {
      org_from_token {
        last_quote_number
      }
      customers_by_pk(id: $customerId) @include(if: $isCustomerSet) {
        id
        title
        price_book_id
        ...CustomerChoice
      }
    }
  `),
  variables: computed(() => ({
    isCustomerSet: !!currentCustomerId.value,
    customerId: currentCustomerId.value || "unknown customer"
  }))
});
const nextRef = ref<string>(
  "Q" + ((data.value?.org_from_token?.last_quote_number || 0) + 1)
);
const priceBookId = computed(
  () =>
    form.customer?.price_book_id || data.value?.customers_by_pk?.price_book_id
);

const blankItem = (): UseQuotesItemsFragment => ({
  id: v4(),
  qty: 1,
  __typename: "quote_items"
});

const newItem = (idx: number) => {
  log("will add item at idx", idx);
  form.items.push(blankItem());
};

const form = reactive<{
  id: string;
  ref: string;
  customer: CustomerChoice | undefined;
  notes: JSONContent | null;
  items: UseQuotesItemsFragment[];
  mediaUploads: UploadedMedia[];
}>({
  id: quote.value ? quote.value.id : v4(),
  ref: quote.value ? quote.value.ref : nextRef.value,
  customer: quote.value?.customer
    ? useCustomerChoice(quote.value.customer)
    : undefined,
  notes: quote.value?.notesj ?? null,
  items: items.value ? items.value : [blankItem()],
  mediaUploads: quote.value?.media_uploads ?? []
});

const subTotalCents = computed(() =>
  formatUSD(
    form.items.reduce(
      (total, item): number => total + (item.subtotal_cents ?? 0),
      0
    )
  )
);

const { mutations } = useQuotes();

const onEditSubmit = async () => {
  console.log("onEditSubmit");
  const { data, error } = await mutations.update.executeMutation({
    args: {
      _quote_id: form.id,
      _ref: form.ref,
      _customer_id: props.customerId || form.customer?.id,
      _notes: form.notes,
      _items: form.items.map(i => ({
        ...i,
        quote_id: form.id,
        product_id: i.product?.id
      })),
      _media_upload_ids: makeArrayWorkWithHasura(
        form.mediaUploads.map(u => u.id)
      )
    }
  });

  if (error) {
    createToast({
      theme: "danger",
      title: "Quote was not updated.",
      message: error.message
    });
  } else if (data) {
    createToast({
      theme: "success",
      message: `Quote ${data?.quote_update?.ref} updated.`
    });
    router.replace({
      name: "QuoteDetail",
      params: {
        quoteId: data.quote_update?.id
      }
    });
  }
};

const onCreateSubmit = async () => {
  console.log("onSubmit");
  const { data, error } = await mutations.create.executeMutation({
    args: {
      _quote_id: form.id,
      _ref: form.ref,
      _customer_id: props.customerId || form.customer?.id,
      _notes: form.notes,
      _items: form.items.map(i => ({
        ...i,
        quote_id: form.id,
        product_id: i.product?.id
      })),
      _media_upload_ids: makeArrayWorkWithHasura(
        form.mediaUploads.map(u => u.id)
      )
    }
  });

  if (error) {
    createToast({
      theme: "danger",
      title: `Quote not ${isEditing.value ? "updated" : "created"}.`,
      message: error.message
    });
  } else if (data) {
    createToast({
      theme: "success",
      message: `Ticket ${data?.quote_create?.ref} ${
        isEditing.value ? "updated" : "created"
      }.`
    });
    router.replace({
      name: "QuoteDetail",
      params: {
        quoteId: data.quote_create?.id
      }
    });
  }
};
</script>

<template>
  <article class="quote-form">
    <h1 v-if="isEditing">Edit Quote</h1>
    <h1 v-else>Create New Quote</h1>
    <SHNote v-if="error" theme="danger">{{ error.message }}</SHNote>
    <main v-else class="vertical">
      <SHField label="Quote Ref">
        <SHInput
          :model-value="form.ref"
          @update:model-value="form.ref = $event"
        />
      </SHField>

      <SHField v-if="!customerId" label="Customer">
        <SHCustomerChooser
          :model-value="form.customer"
          @update:model-value="
            form.customer = $event;
            currentCustomerId = $event?.id;
          "
        />
      </SHField>

      <div class="level wrap tight">
        <h2>Items</h2>
        <SHBadge>{{ form.items.length }}</SHBadge>
        <SHButton color="primary" @click="newItem(-1)">New Item</SHButton>
      </div>
      <section v-if="!form.items.length">
        <SHNote theme="secondary">No items yet.</SHNote>
        <SHButton color="primary" @click="newItem(-1)">Add New Item</SHButton>
      </section>
      <section class="items vertical">
        <div v-for="(_, i) of form.items" :key="form.items.at(i)?.id">
          <Transition name="fade">
            <QuoteItemForm
              v-model="form.items[i]"
              :line-item-number="i + 1"
              :price-book-id="priceBookId"
              @delete-item="form.items.splice(i, 1)"
            />
          </Transition>
        </div>
      </section>
      <h2>Subtotal: {{ subTotalCents }}</h2>

      <SHField label="Notes" block>
        <SHTextEditorWithToolbar
          v-model="form.notes"
          v-model:media-uploads="form.mediaUploads"
          :file-path="`quotes/${form.ref}`"
          :context="{ quote_id: form.id || quoteId }"
          editable
        />
      </SHField>

      <UndoConfirm
        confirm-enabled
        @confirm="isEditing ? onEditSubmit() : onCreateSubmit()"
        @undo="$router.back"
      />
      <pre>{{ form }}</pre>
    </main>
  </article>
</template>

<style lang="scss" scoped></style>
