<script setup lang="ts">
import { graphql, useFragment } from "@/generated";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { clamp, onKeyStroke, useSwipe } from "@vueuse/core";
import { addV, useDrag, usePinch } from "@vueuse/gesture";
import { computed, ref, watch } from "vue";
import type { MediaObjectOrUploadTask } from "../composables/useUpload";
import { formatBytes } from "../formatters";
import AppLink from "./AppLink.vue";
import MediaUploadCard from "./MediaUploadCard.vue";
import SHAudio from "./SHAudio.vue";
import SHButton from "./SHButton.vue";
import SHImage from "./SHImage.vue";
import SHModal from "./SHModal.vue";
import SHVideo from "./SHVideo.vue";

import {
  faChevronLeft,
  faChevronRight,
  faTimes
} from "@fortawesome/sharp-light-svg-icons";

const fragment = graphql(/* GraphQL */ `
  fragment SHLightbox on media_uploads {
    id
    orig_filename
    content_type
    content_size
    url
    caption

    author {
      full_name
      ...UserLink
    }
  }
`);

const { mediaUploads } = defineProps<{
  mediaUploads: MediaObjectOrUploadTask[];
}>();

const emit = defineEmits<{
  (e: "opened"): void;
  (e: "closed"): void;
}>();

const isShowing = ref(false);
const currentIndex = ref<number>();
defineExpose({
  open: (idx = 0) => {
    currentIndex.value = idx;
    isShowing.value = true;
  },
  close: () => {
    currentIndex.value = undefined;
    isShowing.value = true;
  }
});

const lightBoxEl = ref<HTMLElement>();
const { direction } = useSwipe(lightBoxEl, {
  onSwipeEnd: () => (direction.value === "left" ? onNext() : onPrev())
});

const scaleFactor = ref(0.01);
const zoom = ref(1);
const offset = ref([0, 0]);
const _pinching = ref(false);

watch(zoom, val => {
  if (val === 1) {
    offset.value = [0, 0];
  }
});

const transform = computed(() => `scale(${zoom.value})`);
// Composable usage
usePinch(
  ({ delta: [d], pinching }) => {
    if (!lightBoxEl.value) {
      return;
    }
    _pinching.value = pinching;

    // Calculate new zoom level
    const newZoom = clamp(zoom.value + d * scaleFactor.value, 1, 3);

    if (newZoom !== zoom.value) {
      // Update the zoom level
      zoom.value = newZoom;
    }
  },
  {
    domTarget: lightBoxEl,
    eventOptions: {
      passive: true
    }
  }
);

useDrag(
  ({ delta: [dx, dy] }) => {
    if (!lightBoxEl.value || _pinching.value || zoom.value === 1) {
      return;
    }

    // Adjust the delta based on the current zoom level
    const adjustedDelta = [dx / zoom.value, dy / zoom.value];

    // Update the offset using the addV utility
    offset.value = addV(offset.value, adjustedDelta);
  },
  {
    domTarget: lightBoxEl,
    eventOptions: {
      passive: true
    }
  }
);

const onPrev = () => {
  pauseMedia();
  currentIndex.value =
    (currentIndex.value ?? 0) - 1 < 0
      ? mediaUploads.length - 1
      : (currentIndex.value ?? 0) - 1;
};

const onNext = () => {
  pauseMedia();
  currentIndex.value =
    (currentIndex.value ?? 0) + 1 >= mediaUploads.length
      ? 0
      : (currentIndex.value ?? 0) + 1;
};

onKeyStroke("Escape", () => {
  isShowing.value = false;
  pauseMedia();
  emit("closed");
});

const altText = ref("");

const getUrl = (u: MediaObjectOrUploadTask) => {
  if (u) {
    if ("progress" in u) {
      return URL.createObjectURL(u.file);
    }
    const imageFromFragment = useFragment(fragment, u).url;
    altText.value = useFragment(fragment, u).orig_filename;
    return imageFromFragment;
  }
  return "unknown";
};

const shouldProxyRequest = (u: MediaObjectOrUploadTask) => {
  // do not proxy tasks
  return u && !("progress" in u);
};

const media = computed(() => {
  const upload = mediaUploads[currentIndex.value ?? 0];
  if (!upload || "progress" in upload) {
    return;
  }
  return useFragment(fragment, upload);
});

const mediaType = ref("image");

const mainAudio = ref<InstanceType<typeof SHAudio>>();
const mainVideo = ref<InstanceType<typeof SHVideo>>();

const onClose = () => {
  pauseMedia();
  emit("closed");
};

const onMediaClicked = (type: string, i: number) => {
  currentIndex.value = i;
  mediaType.value = type;
  if (type === "audio") {
    mainAudio.value?.toggle();
  } else if (type === "video") {
    mainVideo.value?.toggle();
  }
};

const pauseMedia = () => {
  if (mainVideo.value) {
    mainVideo.value?.resetVideo();
  } else if (mainAudio.value) {
    mainAudio.value?.resetAudio();
  }
};
</script>

<template>
  <SHModal :show="isShowing" no-header @close="onClose">
    <article class="sh-lightbox">
      <header class="level-spread">
        <h3>
          {{ media?.orig_filename }} ({{ formatBytes(media?.content_size) }})
        </h3>
        <SHButton
          @click="
            isShowing = false;
            $emit('closed');
          "
        >
          <FontAwesomeIcon :icon="faTimes" />
        </SHButton>
      </header>

      <main ref="lightBoxEl">
        <SHImage
          v-if="
            typeof currentIndex !== 'undefined' &&
            mediaUploads.length &&
            media?.content_type.startsWith('image/')
          "
          class="main-image"
          type="lightbox"
          :src="getUrl(mediaUploads[currentIndex])"
          :alt="altText"
          :style="{
            transform: `scale(${zoom}) translateX(${offset[0]}px) translateY(${offset[1]}px)`
          }"
          :proxy-request="shouldProxyRequest(mediaUploads[currentIndex])"
          responsive
        />

        <SHAudio
          v-if="
            typeof currentIndex !== 'undefined' &&
            mediaUploads.length &&
            media?.content_type.startsWith('audio/')
          "
          ref="mainAudio"
          autoplay
          controls
          :src="media.url"
          :alt="media.orig_filename"
        />

        <SHVideo
          v-if="
            typeof currentIndex !== 'undefined' &&
            mediaUploads.length &&
            media?.content_type.startsWith('video/')
          "
          ref="mainVideo"
          autoplay
          controls
          disablepictureinpicture
          :src="media.url"
          :alt="media.orig_filename"
        />

        <div class="prev">
          <AppLink block @click="onPrev">
            <FontAwesomeIcon :icon="faChevronLeft" fixed-width size="2x" />
          </AppLink>
        </div>

        <div class="next">
          <AppLink block @click="onNext">
            <FontAwesomeIcon :icon="faChevronRight" fixed-width size="2x" />
          </AppLink>
        </div>
      </main>

      <footer v-if="mediaUploads.length">
        <MediaUploadCard
          v-for="(upload, i) in mediaUploads"
          :key="upload?.id || '?'"
          :media-upload="upload"
          thumbnail-size="md"
          :is-ssr="false"
          no-controls
          @audio:clicked="onMediaClicked('audio', i)"
          @image:clicked="onMediaClicked('image', i)"
          @video:clicked="onMediaClicked('video', i)"
        />
      </footer>
    </article>
  </SHModal>
</template>

<style lang="scss" scoped>
.sh-lightbox {
  --header-height: 3em;
  --footer-height: 10em;
  --main-height: calc(100dvh - var(--header-height) - var(--footer-height));

  background: purple;
  background: var(--color-surface-opacity-25);

  display: grid;
  grid-template-rows: var(--header-height) 1fr var(--footer-height);
  grid-template-columns: 1fr;
  overflow: hidden;

  header {
    padding: 0.5em;
  }

  main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--main-height);

    :deep(img) {
      max-height: var(--main-height);
      padding: 1em;
      transform: v-bind(transform);
      object-fit: fill;
    }

    :deep(audio),
    :deep(video) {
      max-height: var(--main-height);
    }

    .audio-card {
      max-height: var(--main-height);
      height: 12em;
      width: 10em;

      span {
        font-size: 0.75em;
      }
    }
  }

  .prev {
    left: 0.5em;
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }

  .next {
    right: 0.75em;
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }

  .prev,
  .next {
    padding: 0.5em 0.25em;
    position: fixed;
    top: 50%;
    translate: 0 -50%;
    z-index: 1;
  }

  footer {
    border-top: thin solid var(--color-primary);
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 0.5em;
    padding: 0.5em;

    background: var(--color-surface-700);
    overflow-x: scroll;
    --thumbnail-max-height: calc(var(--footer-height) - 3em);
    --thumbnail-min-height: var(--thumbnail-max-height);
  }
}
</style>
