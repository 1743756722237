<script setup lang="ts">
import type { ButtonSize } from "@/types";
import { faTrashCan } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SHButton from "./SHButton.vue";
import SHDropdown from "./SHDropdown.vue";

const { square = true } = defineProps<{
  enabled?: boolean | null;
  size?: ButtonSize;
  square?: boolean;
}>();

const emit = defineEmits<{
  (e: "delete"): void;
}>();
</script>

<template>
  <SHDropdown
    class="delete-with-confirm"
    placement="bottom-end"
    strategy="fixed"
  >
    <template #default="{ toggle }">
      <SHButton
        color="danger"
        :square="square"
        :disabled="!enabled"
        :size="size"
        @click.stop="toggle"
      >
        <slot name="buttonIcon">
          <FontAwesomeIcon :icon="faTrashCan" fixed-width />
        </slot>
      </SHButton>
    </template>

    <template #popup="{ close }">
      <div class="popup">
        <div style="margin: 1em">
          <slot name="question">Are you sure you want to delete?</slot>
        </div>
        <div class="level-center loose" style="margin: 1em">
          <SHButton @click.stop="() => close()">No</SHButton>
          <SHButton
            color="primary"
            @click.stop="
              close();
              emit('delete');
            "
          >
            Yes
          </SHButton>
        </div>
      </div>
    </template>
  </SHDropdown>
</template>
