<script async setup lang="ts">
import AppLink from "@/components/AppLink.vue";
import SHNote from "@/components/SHNote.vue";
import { SPEARHEAD_DOMAIN } from "@/constants";
import { graphql } from "@/generated";
import type { OrganizationProviderQuery } from "@/generated/graphql";
import { useLogger } from "@/logger";
import { ClientKey, CurrentUserKey, OrganizationKey } from "@/providerKeys";
import type { OrganizationUser } from "@/types";
import {
  createAuthExchange,
  createClient,
  createSubscriptionExchange
} from "@/urql";
import { useAuth0, type Auth0VueClient } from "@auth0/auth0-vue";
import { provideClient } from "@urql/vue";
import { computed, provide, ref } from "vue";

const props = defineProps<{
  roleName: string;
  orgFromHostname?: OrganizationProviderQuery["organizations"][number];
  authClient: Auth0VueClient;
}>();

const { log } = useLogger("ClientProvider"); // eslint-disable-line @typescript-eslint/no-unused-vars
const { logout } = useAuth0();

const subscriptionExchange = createSubscriptionExchange(
  props.authClient,
  computed(() => props.roleName)
);
const authExchange = createAuthExchange(
  props.authClient,
  computed(() => props.roleName)
);

const client = createClient(authExchange, subscriptionExchange);
provide(ClientKey, client);
provideClient(client);

const isLoading = ref(true);

// Insert this user and org_agent into the local DB
const { data, error } = await client.mutation(
  graphql(/* GraphQL */ `
    mutation AuthenticateClient {
      authenticate_client {
        id
        fname
        lname
        email
        phone_number
        full_name
        subscription
        ...UserLink

        organization_agents {
          user_roles {
            user_role {
              ...UserRole
            }
          }

          organization {
            id
            title
            short_name
            ...OrgLogo
          }
        }
      }
    }
  `),
  {}
);

if (error) {
  console.error("Error authenticating client", error);
  localStorage.removeItem("selectedRole");
} else if (data?.authenticate_client) {
  provide(
    CurrentUserKey,
    computed(
      () =>
        ({
          ...data.authenticate_client,
          default_role:
            props.authClient.user.value?.[SPEARHEAD_DOMAIN]?.default_role ||
            "public",
          allowed_roles: props.authClient.user.value?.[SPEARHEAD_DOMAIN]
            ?.allowed_roles || ["public"],
          isSupport: (
            props.authClient.user.value?.[SPEARHEAD_DOMAIN]?.allowed_roles || []
          ).includes("spearhead_staff")
        }) as OrganizationUser
    )
  );
  provide(
    OrganizationKey,
    computed(
      () => data.authenticate_client?.organization_agents.at(0)?.organization
    )
  );
} else {
  console.log("no user", { data, error });
}

isLoading.value = false;
</script>

<template>
  <div class="client-provider">
    <!-- todo: come back and only allow further slots if we got an undeleted user back (not blocked) -->
    <template v-if="!isLoading">
      <template v-if="!data?.authenticate_client">
        <SHNote theme="danger" class="blocked-banner">
          <div class="vertical tight">
            <h1>Access Denied</h1>
            <p>You are not authorized to use this app.</p>
            <AppLink @click.prevent="() => logout()">Logout</AppLink>
            <p v-if="error">{{ error }}</p>
          </div>
        </SHNote>
      </template>
      <slot v-else />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.blocked-banner {
  margin: 1em;
  padding: var(--padding);
}
</style>
