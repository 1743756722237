export enum Event_Types_Enum {
  AgentCreated = "agent_created",
  CalloffTicketCreated = "calloff_ticket_created",
  DedicatedTicketCreated = "dedicated_ticket_created",
  DedicatedWorkOrderCreated = "dedicated_work_order_created",
  TicketReviewRequested = "ticket_review_requested",
  TicketReviewCommented = "ticket_review_commented",
  TicketReviewAccepted = "ticket_review_accepted",
  TicketReviewRejected = "ticket_review_rejected",
  TicketReviewCancelled = "ticket_review_cancelled",
  QuoteCreated = "quote_created",
  QuoteSent = "quote_sent",
  QuoteArchived = "quote_archived",
  QuoteUnarchived = "quote_unarchived"
}