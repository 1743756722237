<script setup lang="ts">
import { faTimes } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ref } from "vue";
import SHButton from "./SHButton.vue";

defineEmits<{
  (e: "hide-alert", dontShowAgain?: boolean): void;
}>();

defineProps<{
  visible?: boolean;
  dontShowAgain?: boolean;
}>();

const dontRepeat = ref(false);
</script>
<template>
  <Transition name="fade">
    <section v-if="visible" class="alert-banner">
      <div class="vertical" style="gap: 0">
        <slot name="message"></slot>
        <slot name="action"></slot>
      </div>

      <slot name="close">
        <aside class="close-btn">
          <SHButton
            color="surface-400"
            square
            @click="$emit('hide-alert', dontRepeat)"
          >
            <FontAwesomeIcon :icon="faTimes" />
          </SHButton>
        </aside>
      </slot>
      <label v-if="dontShowAgain" class="dont-box">
        <input v-model="dontRepeat" type="checkbox" />
        Don't show again
      </label>
    </section>
  </Transition>
</template>
<style lang="scss" scoped>
.alert-banner {
  align-items: center;
  background: var(--color-surface-200);
  border-style: solid;
  border-width: 0 0 1px 0;
  color: var(--color-surface-700);
  display: grid;
  gap: 0.5em;
  grid-template-columns: max-content 1fr max-content;

  padding: 1em;
  position: fixed;
  top: 0;
  width: 100%;

  > section {
    text-align: center;
  }

  &.hide {
    display: none;
  }

  .close-btn {
    position: fixed;
    top: 1em;
    right: 1em;
  }

  .dont-box {
    position: absolute;
    bottom: 1em;
    right: 1em;
  }
}
.isMobileMode {
  header {
    display: none;
  }
  article {
    flex-direction: column;
  }
  main {
    flex: 1;
  }
}
</style>
