<script async setup lang="ts">
import ReportHeader from "@/components/ReportHeader.vue";
import SHInlineDate from "@/components/SHInlineDate.vue";
import SHNote from "@/components/SHNote.vue";
import { graphql, useFragment } from "@/generated";
import QuoteSummary from "@/views/QuoteSummary.vue";
import { useQuery } from "@urql/vue";
import { computed } from "vue";

const { quoteId } = defineProps<{
  quoteId: string;
}>();

const fragment = graphql(/* GraphQL */ `
  fragment QuoteRO on quote_quotes {
    id
    ref
    author {
      ...ReportHeaderAgent
    }
    customer {
      ...ReportHeaderCustomer
    }
    items {
      id
      product_override
      qty
      subtotal_cents
      subtotal_override_cents
      notes
      product {
        id
        title
        catalog_id
      }
    }
  }
`);

const { data, error } = await useQuery({
  query: graphql(/* GraphQL */ `
    query QuotePreview($quoteId: uuid!) {
      quote_quotes_by_pk(id: $quoteId) {
        ...QuoteRO
      }
    }
  `),
  variables: computed(() => ({ quoteId }))
});

const quote = computed(() =>
  useFragment(fragment, data.value?.quote_quotes_by_pk)
);
</script>
<template>
  <SHNote v-if="error" theme="danger">{{ error.message }}</SHNote>
  <SHNote v-else-if="!quote" theme="danger">Not found</SHNote>
  <article v-else class="quote-ro vertical">
    <span class="quote-ref">&nbsp;{{ quote.ref }}</span>
    <ReportHeader
      :title="`Quote ${quote.ref}`"
      :agent="quote.author"
      :customer="quote.customer || {}"
    >
      <div>
        Generated on
        <SHInlineDate :d="new Date()" format="long" />
      </div>
    </ReportHeader>

    <QuoteSummary :quote-id="quoteId" />
  </article>
</template>

<style lang="scss">
.quote-ro {
  .quote-ref {
    display: none; // this element is there just to give context to pagedjs content
  }
}
</style>
