<script setup lang="ts">
import { graphql, useFragment, type FragmentType } from "@/generated";
import { injectStrict } from "@/lib/helpers";
import { DarkModeKey } from "@/providerKeys";
import { computed } from "vue";

const { BASE_URL } = import.meta.env;

const DEFAULT_APP_ICON_URL = "/icons/app-icon-192.png"; // if file or organization name are falsey, return the default app icon.

const fragment = graphql(/* GraphQL */ `
  fragment OrgLogo on organizations {
    short_name
    accent_color
    logo_square_dark_url
    logo_square_light_url
    logo_wide_dark_url
    logo_wide_light_url
  }
`);

const {
  organization,
  dark = false,
  square = true
} = defineProps<{
  organization?: FragmentType<typeof fragment> | null;
  dark?: boolean;
  square?: boolean;
}>();

const isDark = injectStrict(DarkModeKey);

const imgSrc = computed(() => {
  const o = useFragment(fragment, organization);
  const file =
    dark || isDark.value
      ? square
        ? o?.logo_square_dark_url
        : o?.logo_wide_dark_url
      : square
        ? o?.logo_square_light_url
        : o?.logo_wide_light_url;

  if (!o?.short_name || !file) {
    return DEFAULT_APP_ICON_URL;
  }

  return `${BASE_URL}organizations/${o.short_name}/${file}`;
});
</script>

<template>
  <img v-if="imgSrc" class="org-logo" :src="imgSrc" />
</template>
